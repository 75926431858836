/**
 * A Database Grid editing module intended to work with /server/db/Table.js Api Tables
 * {@tutorial using_grids}
 * @example
 * //server/route/api/lorrnel/alpac.js
 * exposeTable('/cmt_crossing', 'cmt_edit', new Table(alpacPool, 'lorrnel.cmt_crossings', ['id']));
 *
 * //src/js/client/cmt.js or wherever
 * let tableGridConfig = {
 *     view: 'myView',
 *     route: '/api/myRoute/myView',
 * }
 *
 *
 * See Above sample config;
 * @class
 * @requires Grid.js
 */
class TableGrid extends Grid {

    /**
     * This is ment to fully construct a mangment grid for databases view
     * also see GRID options
     * Stolen from DatabaseGrid.js
     * Accept config from db directly (maybe cmt or tg or  somthing)
     *
     * OPTIONS:
     * @param {String} config.route - the route to the db table api ie /api/lorrnel/cmt_crossing
     * @param [config.select='/selectAll'] use this if you with to use a custom route for the select all request
     * @param {Object<Grid>} config.gridOptions - You can defien Grid constructor options here see grid docs for more info
     * @param {String} config.view - this is the name of the view or table ie 'v_cmt_company'
     * @param {String} [config.idField='id'] - Lets you set the field of the table that holds a uuid or equivalent
     * @param {String} [config.sortField=idField] - Lets you set the field of the table you would like to initaly sort the data by
     * @param {String} [config.nameField=idField] - a field with a human name for the row to display to user when needed defaults to id field
     * @param {String} [config.rowNoun='row'] - a noun that describes what the row represents for user msgs ie 'state', 'road segment' etc
     * @param {String} config.table 'cmt_company_dev_v1', The table name used for construct relations in planmap style users + groups => user_group todo verify
     * @param config.geoserver config for layer manager so we can get wfs/wms this lets you show a wms layer as the spaital componenet not hilighting might break
     * @param config.geoserver.source - the server name see layerManager must be in site_config.layer_config.sources
     * @param config.geoserver.workspace - the workspace name
     * @param config.geoserver.layer - the layer name
     * @param {String} [config.geomField=undefined] - if set this field will be used for getting the spaital information usualy 'geom'. This will make this a spaital table
     * @param {Object} [config.geojson] - these options are mergerd with the L.geojson constructor to allow use styling {@link https://leafletjs.com/reference.html#geojson}
     * @param {function} [config.geojson.pointToLayer] - override a pointToLayer callback cb(feature, latlng, row, grid) must return L.marker or Somthing
     * @param {function} [config.geojson.style] - override the style function for polylines etc callback cb(feature, row, grid)
     * @param {String} [config.layerMode='geojson'] - the mode for the map layer geojson,wms,cluster
     * @param {Number} [config.highlightZoom=10] the zoom level for when hilighting
     * @param {Number} [config.actionWidth] - the width in pixels for the action column see defulets
     * @param [config.deleteAction=true] wether or not to add the delete action in for you
     * @param [config.notifyOnLoad=true] set to false if you dont want a notification when all the data is loaded
     * @param [config.onEdit] - Slick Event called every time something is edited
     *
     * @param {Array<Object>} config.editTables - An array of tables that edits should be made to. This lets you sat multiple forgin tables
     *  The make up this view as well as the join fileds
     * @param {String} config.editTables.table - The table name this is what you reference in the field config if necessary
     * @param {String} config.editTables.route - '/api/lorrnel/alpac/cmt', the route to a Table api endpoint for this table
     * @param {String} [config.editTables.tableId] - 'event_id' this is the field name of the id in the table
     * @param {String} [config.editTables.viewId] - 'object_id' this is the field name of the id in the view if different
     *
     * @param {Object} [config.default] - Allows you to set the default field config can have all fieldConfig Options
     * @param {Boolean} [config.default.visible=true] - true acts like blacklist false acts like whitelist
     * @param {Array<Object>} config.fieldConfig And array of field objects for non default feilds note any undefiend fields will use defaults
     * @param {String} config.fieldConfig.name the field name as it appears in the database or Object key this is how we will how which filed these settings are for
     * @param {String} [config.fieldConfig.niceName] - Lets you provied a nice readable name for this field default to bs.niceName(name)
     * @param {Boolean} [config.fieldConfig.visible]: wether or not this filed should be displayed in the grid or not
     * @param {Boolean} [config.fieldConfig.hideInfo]: obscure option that disable a row from showing in info popup enven tho its visible
     * @param {Object} config.fieldConfig.columnOptions -Slick Grid Colum options {@link https://github.com/6pac/SlickGrid/wiki/Column-Options}
     * @param {Object} [config.fieldConfig.edit] config for where and how edits should be made
     * @param {String} config.fieldConfig.edit.type types supported by bs.prompt default detect the type from pg todo verify / todo maybe this http://6pac.github.io/SlickGrid/examples/example-composite-editor-item-details.html
     * @param {String} [config.fieldConfig.edit.table] the db table to post edits to ie 'cmt_company_dev_v1',
     * @param {String} [config.fieldConfig.edit.field] the field in the edit table to update
     * @param {String} [config.fieldConfig.edit.mode="prompt"] where edits should be made "inline", "prompt", "both"
     * @param {Object} config.fieldConfig.edit.options  extra edit options to pass to the editor ir select2 or prompt
     *
     * // enity lines
     * config(..., defaults, fieldConfig)
     * fieldConfig(name, niceName, visible, hideInfo, columOptions, edit)
     * edit(type,table,field,mode,options)
     * columnOptions -> https://github.com/6pac/SlickGrid/wiki/Column-Options
     */
    constructor(config, layerManager) {

        let defaults = {
            enabled: true,
            route: undefined,//this can be a view or a table
            view: undefined,//this can be a view or a table
            loadingMask: false, // if true will put up the loading mask wile initializing
            select: '/selectAll',
            idField: 'id',//todo error if not exists or changed
            rowNoun: 'row',
            sortField: undefined,//defaults to idField
            geomField: undefined,//undefined or false for nono spatial tables
            layerMode: 'geojson', // 'wms', 'cluster', 'geojson'
            geojson: {}, //lets you set L.geojson Styles functions pointToLayer and style see code maybe.
            highlightZoom: 10,//see highlightRow
            actionWidth: 64,
            geoserver: false,
            deleteAction: true,
            notifyOnLoad: true,//notify after loading all the data for the table
            default: {//edit the default fieldConfig
                visible: true,//true acts like blacklist false acts like whitelist
                edit: false
            },
            fieldConfig: [],
            editTables: [],
            gridOptions: {
                //options cascading but these should be DBLevel Priority 1  > src/js/TableGrid.js Level > src/js/Grid.js > node_modules/slickgrid/slick.grid.js The code is best docs for this
                containerType: 'jspanel',
                jspanelOptions: {
                    panelSize: {
                        width: window.innerWidth * 0.9,
                        height: 350,
                    },
                    aspectRatio: false,

                    position: {
                        my: 'center-bottom',
                        at: 'center-bottom',
                        offsetX: 0,
                        offsetY: 0
                    },
                    onclosed: () => {
                        this.panel = undefined;
                        this.autoSyncOff();
                    },
                    headerTitle: bs.niceName(config.view),

                },

                gridOptions: {
                    // enableAddRow: true,
                    editable: true,
                    frozenColumn: 0,
                    frozenBottom: true,
                    autosizeColsMode: Slick.GridAutosizeColsMode.LegacyForceFit,
                    // autosizeColsMode: Slick.GridAutosizeColsMode.FitViewportToCols,

                    multiColumnSort: true,
                    numberedMultiColumnSort: true,
                    tristateMultiColumnSort: true,
                    sortColNumberInSeparateSpan: false,
                }
            }

        }

        if (!layerManager && config.geoserver) {
            throw new Error("No LayerManger provided but geoserver config is present make sure you pass the layer manager into the constructor");
        }


        config = bs.mergeDeep(defaults, config);

        if (config.loadingMask) {
            loadingMask.waitOnMe(config.view);
            // console.log("Loading Mask: wait on", );

        }

        config.sortField = config.sortField || config.idField;
        config.nameField = config.nameField || config.idField

        //this initializes the object with grid defaults our defaults should take priority but doesn't initialize the grid yet
        super([], [], config.gridOptions, false);
        //now 'this' can be used
        this.config = config;


        this.dataViewOptions = {
            inlineFilters: true,
            idProperty: this.config.idField

        }


        this._tableGridEvents();
        this._layerManager = layerManager;

        this._getTableData(config).then(async r => {

            let res = r.res;//select all
            let describe = r.describe;
            this.describe = r.describe;
            config = r.config;
            this.config = config;


            this.data = this.setupData(res.data)

            console.log("Row0", res.data[0]);//sample data to look at
            console.log('describe', describe);

            this.template = {}
            this.columns = [];
            this.fieldConfigs = []

            //for each column the data
            // Object.keys(this.data[0]).forEach(key => {
            describe.forEach(meta => {
                    //sample table columns views dont have check
                    // character_maximum_length: 30
                    // check: (4) ["Current", "Historical", "Proposed", "Unknown"]
                    // column_default: null
                    // column_name: "historicalstate"
                    // data_type: "character varying"
                    // is_nullable: "YES"
                    // is_updatable: "YES"
                    let key = meta.column_name;
                    console.debug("Column, ", key);
                    //config options
                    //name, niceName, edit.table, edit.field, edit.type, edit.mode, edit.options
                    let fcTMP = config.fieldConfig.find(f => f.name == key);
                    // console.debug("FC, ", fcTMP);


                    let tmpFieldConfig = bs.mergeDeep({}, config.default)
                    // console.debug("FC Default", tmpFieldConfig);
                    // console.debug("FC, ", fcTMP);
                    tmpFieldConfig = bs.mergeDeep(tmpFieldConfig, fcTMP)
                    // console.debug("FC Merged", tmpFieldConfig)

                    // console.log('hasEditCOnfig: ', fcTMP && typeof fcTMP.edit)
                    if (fcTMP && fcTMP.edit) {// then we try to merge them
                        if (bs.isObject(fcTMP.edit)) {
                            Object.keys(fcTMP.edit).forEach(k => {
                                console.debug("FC: edit k,v:", k, fcTMP.edit[k])
                                // delete tmpFieldConfig.edit[k]; // not sure why i was deleting this lets just elt js handle that for us :)
                                if (typeof tmpFieldConfig.edit == 'string') {
                                    //if edit is just a string
                                    let table = tmpFieldConfig.edit
                                    console.warn("Filed config edit was a string so assuming you meant to" +
                                        " used defaults and this edit table: ", table);
                                    tmpFieldConfig.edit = {
                                        table: table,
                                    }

                                }
                                tmpFieldConfig.edit[k] = fcTMP.edit[k];
                            })
                        } else {//string
                            tmpFieldConfig.edit = fcTMP.edit;
                        }
                    }
                    // console.debug("FC Edit", tmpFieldConfig);
                    let fieldConfig = tmpFieldConfig;


                    let editMeta, template, column;


                    if (fieldConfig.visible) {//make a column for it
                        column = {
                            id: key,
                            name: fieldConfig.niceName || bs.niceName(key),
                            field: key,
                            sortable: true,
                            // editor: Slick.Editors.LongText,
                            // width: 60
                        };


                        // console.log("TODO: get formatter by meta type", meta);
                        if (meta.data_type == "date") {
                            column.formatter = DateFormatter
                        }


                        column = bs.mergeDeep(column, fieldConfig.columnOptions);

                        // this.columns.push(column);
                    } //endif visible

                    // let meta = describe.find(d => d.column_name == key)
                    if (fieldConfig.edit) {
                        let edit = fieldConfig.edit;
                        let editDesc;
                        if (edit.table) {
                            editDesc = config.editTables.find(t => t.table == edit.table).describe;

                        } else if (typeof edit == 'string') { //if its a string asume thats the table
                            let et = config.editTables.find(t => t.table == edit);
                            if (!et) {
                                console.error("CONFIG ERROR: you set the edit to a string but that string isn't a table in editTables edit.table:", edit);
                            } else {
                                edit = {
                                    table: edit,
                                    field: key,
                                }
                                editDesc = et.describe;
                            }
                        } else if (Array.isArray(config.editTables) && config.editTables[0]) { //else if theres any edit table use the first one
                            if (edit === true) {//if edit is just set to true then it needs to be an object
                                edit = {}
                            }
                            editDesc = config.editTables[0].describe;
                            edit.table = config.editTables[0].table;
                        } else {//use the view if its true and not an array or string
                            editDesc = describe;
                            config.editTables.push({
                                table: config.view,
                                route: config.route,
                                tableId: config.idField,
                                viewId: config.idField,
                                describe: describe,
                            })

                            edit = {
                                table: config.view,
                            }
                        }

                        edit.type = fieldConfig.edit.type;
                        edit.mode = fieldConfig.edit.mode;

                        edit.field = edit.field || key;//if no edit field is provided asume it is the same


                        //ok now we have the right column desc /meta and the edit object has a table/field for future use i hope
                        editMeta = editDesc.find(d => d.column_name == edit.field)
                        edit.meta = editMeta;
                        //editMeta is now the column metadata where we will try and save it

                        if (!edit.meta) {
                            console.warn(`No PG Describe Founds For ${edit.table}.${edit.field}`);
                        }


                        //what type?
                        if (typeof edit.type != 'string') {
                            edit.type = 'select2'
                            if (!edit.check && edit.meta && edit.meta.character_maximum_length > 250) {
                                edit.type = 'textarea'
                            }

                            if (edit.meta && edit.meta.data_type == 'date') {
                                edit.type = 'date'
                            } else {
                                let val = res.data[0][key];
                                if (val && typeof val == 'object') {
                                    this.template[key] = 'json';
                                }
                            }

                            console.warn('Table Grid: No Default edit type set it too: ' + edit.type);
                        }

                        if (typeof edit.mode != 'string') {
                            edit.mode = 'both';//default mode for editing
                        }


                        //compute some usefule info


                        let values = [];
                        let uniqueValues = [];
                        let maxLengthValue = 0;

                        if (edit.type === 'datalist' || edit.type === 'select2') {
                            values = this.data.map(row => row[key]);
                            uniqueValues = bs.uniqueArray(values);
                            maxLengthValue = Math.max(...uniqueValues.map(v => bs.testTextLength(v)));//todo probabnly can estamat based on char length
                            if (uniqueValues.length > 10000) {
                                console.warn(`Warning: column ${key} has ${uniqueValues.length} unique values this is a lot maybe make it a input not a select2/datalist`);
                            }
                        }
                        // console.log("Max Length Value: ", key, " - ", maxLengthValue);
                        // console.log(key, values[0])
                        // console.log("Typeof: ", typeof values[0]);


                        if (fieldConfig.visible && (edit.mode === 'both' || edit.mode === 'inline')) {

                            //do inline stuff
                            switch (edit.type) {
                                case "select2": {
                                    column.editor = Select2Editor;
                                    column.formatter = Select2Formatter;

                                    let opts = {}
                                    let tags = true;
                                    let allowClear = true;
                                    if (edit.meta && edit.meta.check) {//we have a constraint so use that
                                        edit.meta.check.forEach(v => {
                                            opts[v] = bs.niceName(v);
                                        })
                                        tags = false;//makes you not able to type whatever you want
                                        allowClear = false;
                                    } else if (edit.data) {//has data;
                                        // if(edit.data instanceof Promise) {
                                        //
                                        // }
                                        opts = edit.data;

                                    } else {
                                        uniqueValues.forEach(v => {
                                            opts[v] = v;
                                        })

                                    }

                                    // column.options = opts;
                                    column.dataSource = opts;
                                    column.editOptions = edit.options || {};
                                    column.select2Options = {
                                        tags: tags,
                                        allowClear: allowClear
                                    }
                                    break;
                                }
                                case "array-input":
                                case "array": {
                                    column.editor = ArrayInputEditor
                                    break
                                }


                                case "textarea": {
                                    column.editor = Slick.Editors.LongText
                                    break;
                                }
                                case "input": {
                                    column.editor = Slick.Editors.Text
                                    break;
                                }
                                case "date": {
                                    column.editor = Slick.Editors.Date/*DateEditor*/
                                    column.formatter = DateFormatter
                                    break;
                                }
                                case "datalist": {
                                    console.error("Not implemented datalist")
                                    break;
                                }
                            }

                        }
                        if (edit.mode === 'both' || edit.mode === 'prompt') {
                            //do prompt stuff
                            switch (edit.type) {
                                case "select2": {

                                    let isConstraint = edit && edit.meta && edit.meta.check
                                    template = {
                                        type: 'select2',
                                        options: {
                                            tags: !isConstraint,
                                            allowClear: !isConstraint,
                                            placeholder: ' '

                                        },
                                        data: isConstraint ? edit.meta.check : uniqueValues,
                                    }

                                    break;

                                }
                                case "array-input":
                                case "array": {
                                    // column.editor = ArrayInputEditor
                                    template = 'array';
                                    break;

                                }

                                case "textarea": {
                                    template = 'textarea';
                                    break;
                                }
                                case "date": {
                                    template = 'datepicker';
                                    break;
                                }
                                case "input": {
                                    template = 'input';
                                    break;
                                }
                                case "datalist": {
                                    template = {
                                        type: 'datalist',
                                        data: uniqueValues,

                                    };
                                    break;
                                }
                            }
                        }


                        if (meta.check) {
                            column.editor = Select2Editor;
                            let opts = {}
                            meta.check.forEach(v => {
                                opts[v] = bs.niceName(v);
                            })
                            // column.options = opts;
                            column.dataSource = opts;
                            column.formatter = Select2Formatter;
                            // column.options = meta.check.join(',');
                            console.log("META CHECK", meta.check);
                        }


                        fieldConfig.edit = edit;

                    }

                    //SET THE VARS
                    if (template) {
                        this.template[key] = template;
                    }
                    if (column && fieldConfig.visible) {
                        this.columns.push(column);
                    }
                    fieldConfig.name = key;
                    fieldConfig.meta = meta;
                    // console.log("fieldConfig to push: ", fieldConfig);
                    console.debug("FC Final", fieldConfig);
                    this.fieldConfigs.push(fieldConfig);
                }
            );


            //add the actions column in first
            this.columns.splice(0, 0, {
                id: 'actions',
                name: 'Actions',
                field: 'id',
                formatter: this.actionFormatter,
                //https://github.com/ghiscoding/slickgrid-universal/issues/548#issuecomment-969838502
                // onCellClick: (event, args) => {
                //     const dataContext = args.dataContext;
                //
                //     if(!Array.isArray(this._actions)) this._actions = [];
                //     for (let i = 0; i < this._actions.length; i++) {
                //
                //     }
                //
                //     this._executeAction()
                //
                //     if ((event.target as HTMLElement).classList.contains('mdi-close')) {
                //         if (confirm(`Do you really want to delete row (${args.row + 1}) with "${dataContext.title}"`)) {
                //             this.slickerGridInstance.gridService.deleteItemById(dataContext.id);
                //         }
                //     } else if ((event.target as HTMLElement).classList.contains('mdi-check-underline')) {
                //         this.slickerGridInstance.gridService.updateItem({ ...dataContext, completed: true });
                //         alert(`The "${dataContext.title}" is now Completed`);
                //     }
                // }
                minWidth: config.actionWidth - 1,
                width: config.actionWidth,
                maxWidth: config.actionWidth + 1,
                autosizeMode: Slick.ColAutosizeMode.Locked,

            });


            //now this.data and this.columns should be set so we can init;
            this.sortcol = "id";
            this.sortdir = 1;


            //
            this.onInit.subscribe(() => {
                //so its before init callback
                if (config.addRow) {

                    if (typeof config.addRow == "string") {
                        this.addToolbarAddRow(config.addRow)
                    } else {
                        this.addToolbarAddRow()
                    }

                }
                if (config.genericTools) {
                    this.addGenericTools();
                }
            })
            //Initialize the grid
            this.init();

            if (this.fieldConfigs.find(c => c.edit && (c.edit.mode == 'prompt' || c.edit.mode == 'both'))) {
                this.addEditAction();
            }
            if (config.deleteAction) {
                this.addDeleteAction();
            }

            if (this.config.geomField) {
                this._initSpatial();
                this.onSpatialInit.notify({grid: this});
            }


            if (config.btn) {
                if (!config.btn instanceof HTMLButtonElement) {
                    console.error("Must pass in a button to TableGrid: ", config.view);
                }
                this.setBtn(config.btn);
            }
            if (config.loadingMask) {
                loadingMask.done();
                console.log("DONE LOADING ", this.config.view);
            }

            // this.bsutil = new BSUtil('dbgrid' + this.id);
        })

        // this.setupToolBar();
    }

    /**
     * gets table data from server
     * @param [config=this.config] - table grid config
     * @return {Promise<{
     *    res: *,
     *    describe: *,
     *    config: *
     * }>}
     * @private
     */
    _getTableData(config) {
        if (!config) config = this.config
        this.url = bs.routeToURL(config.route);


        if (typeof gun != "undefined" && config.schema && config.table) {

            gun.get(config.schema).get(config.view)
        }


        //fetch the data in parallel
        let select = config.select/* || '/selectAll'//this may be redundant*/
        let promises = [
            bs.sendJson('get', bs.routeToURL(config.route + select)),
            bs.sendJson('get', bs.routeToURL(config.route + '/describe'))
        ]

        let statIndex = 1; // the number of things before in promises array
        let routesFetched = [config.route]; // = vals[1]
        for (let i = 0; i < config.editTables.length; i++) {
            let route = config.editTables[i].route
            let index = routesFetched.indexOf(route);
            if (index > 0) {
                //already fetching it
                config.editTables[i].describe = index + statIndex;

            } else {
                promises.push(bs.sendJson('get', bs.routeToURL(route + '/describe')));
                config.editTables[i].describe = routesFetched.length + statIndex;// this is the index in vals for later
            }
        }

        return Promise.all(promises).then(values => {
            let res = values[0];
            // if (res.packed) {
            //     res.data = jsonpack.unpack(res.data);
            //     values[0] = res;
            // }
            let describe = values[1].data;

            for (let i = 0; i < config.editTables.length; i++) {
                //just a dirty way of keeping track.
                let index = config.editTables[i].describe
                let res = values[index];

                if (!res.success) bs.resNotify(res);

                config.editTables[i].describe = res.data;
            }

            //cheack for errors/failures
            if (values[0].success && values[1].success) {
                if (config.notifyOnLoad) {
                    bs.resNotify({
                        success: true,
                        msg: `Successfully got Table Data and Info for: ${config.view}`
                    });
                }//else success but silently
            } else {
                console.error(`Failed getting table data and info for: ${config.view} \n Values:`, values)
                bs.resNotify({
                    success: false,
                    msg: `Failed getting table data and info for: ${config.view} \n please try again (refresh) Error: ${values[0]}`
                });
            }
            //at this point we have all our data, config and descriptions but what do we actually want
            // one description with the edit fields included
            //view.field => editTable.describe.editField

            return {
                res: res,
                describe: describe,
                config: config,
            }
        });

    }

    /**
     * Uses server/db/Table.js Generic routes api see code. {@link https://portal3.lorrnel.com/dev/docs/Table.html}
     * @param route - the table.js route
     * @param body - the request body
     * @param nocores - whether to use cores
     * @param table - the edit table if necessary @return {Promise<any>}
     */
    query(route, body, nocores, table) {

        if (typeof route != 'string') {
            throw "Route must be a string"
        }

        let r = this.config.route;
        let t = this.config.editTables.find(t => t.table == table)
        if (t) {
            r = t.route;
        }
        if (route[0] == '/') route = route.substr(1, route.length - 1)
        let url = bs.routeToURL(r + '/' + route)

        switch (route) {
            case 'insert': {
                return bs.sendJson('POST', url, body, nocores);
            }
            case 'update': {
                return bs.sendJson('POST', url, body, nocores);
            }
            case 'deleteRow': {
                return bs.sendJson('DELETE', url, body, nocores);
            }
            case 'delete': {
                return bs.sendJson('DELETE', url, body, nocores);
            }
            case 'describe': {
                return bs.sendJson('GET', url, body, nocores);
            }
            case 'select': {
                return bs.sendJson('POST', url, body, nocores);
            }
            case 'selectSimple': {
                return bs.sendJson('post', url, body, nocores);
            }
            case 'selectAll': {
                return bs.sendJson('GET', url, body, nocores);
            }
        }

    }

    /**
     * This is what actually sets the data in this.grid
     * also performs stuff like sorting that should happen on setup
     * @param data
     */
    setupData(data) {

        if (!data || data[0] == null) {
            console.error("No Data", data);
            return [];
        }


        //sort it the way we want before giving it to the grid
        data = data.sort((a, b) => {
            let x = a[this.config.sortField], y = b[this.config.sortField];
            return (x == y ? 0 : (x > y ? 1 : -1));//no ===
        })

        if (!('id' in data[0])) {//if no id make one
            this.hideEditId = true;
            let i = 0;
            data.forEach(row => {
                row.id = i++;
            })
        }

        return data
    }

    /**
     * feteches dumb update from server
     */
    updateData() {
        this._getTableData().then(r => {


            // let res = r.res;//select all
            // let describe = r.describe;
            // let config = r.config;
            // this.describe = r.describe;
            // this.config = config;
            this.data = this.setupData(r.res.data);
            // console.log("Row0", res.data[0]);//sample data to look at
            // console.log('describe', describe);

            this.setItems(this.data, 'id');

        });
    }

    /**
     * This sets up a generic row delete button
     */
    addDeleteAction() {
        // language=SQL format=false
        this.addActionButton('delete', `Delete this ${this.config.rowNoun} from database`, icons.delete, this.delete, {
            colorClass: 'btn-danger'
        })
    }

    /**
     * This adds a bs.promt edit based on config.fieldConfig
     */
    addEditAction() {
        this.addActionButton('edit', 'Popup Edit Prompt', icons.edit, this.rowEdit)
    }

    /**
     * This adds a toolbar button with an +rowNoun. This will open a modal prompt edit that is blank with all editable fields
     * @param [html] - accepts custom button innerHTML
     * @param [options] - options for setting up the prompt (bs prompt args in object form);
     */
    addToolbarAddRow(html, options) {
        html = html || `<i class="fa fa-plus-square"></i> ${this.config.rowNoun}`
        this.addToolbarButton('addRowButton' + this.config.view, `Add A new ${this.config.rowNoun}`, html, () => {
            if (!options) {
                this.addRow();//todo defaults?
            } else {
                let {title, template, values} = options;
                bs.prompt(title, template, values, options).then(data => {

                    if (options.dataMap) {
                        data = options.dataMap(data);
                        if (!data) {
                            console.error("Invalid data returned canceling insert", data)
                            return;
                        }
                    }

                    this.query('insert', {row: data}, false, this.config.editTables[0].table).then(res => {
                        if (res.success) {
                            bs.resNotify(res);
                            console.log(res.data);
                            let row = res.data[0];
                            this.dataView.addItem(row);
                        } else {
                            console.error("Failed to add item or something");
                        }
                    })


                })

            }
        });
    }


    /**
     * Called when the spatial component is don being setup
     * @event TableGrid#onSpatialInit
     * @param {Slick.EventData} e - the slick event
     * @param {Object} args - additional arguments for the event
     * @param args.grid - the instance of this grid
     */

    /**
     * Called when an edit is made and the updated row is returned from the db/view
     * @event TableGrid#onEdit
     * @param {Slick.EventData} e - the slick event
     * @param {Object} args - additional arguments for the event
     * @param args.row - the row submitted
     * @param args.grid - the instance of this grid
     */

    /**
     * setup table grid events
     * @private
     */
    _tableGridEvents() {
        this.onSpatialInit = new Slick.Event();
        if (typeof this.config.onSpatialInit == 'function') {
            this.onSpatialInit.subscribe(this.config.onSpatialInit);
        }

        this.onEdit = new Slick.Event();
        if (typeof this.config.onEdit == 'function') {
            this.onEdit.subscribe(this.config.onEdit);
        }

    }

    getGeojson(data) {
        if (!data) data = this.data;
        return bs.rowsToGeojson(data, this.config.geomField, {
            onlyFields: ['id', this.config.layerTooltipField]
        });
    }

    /**
     * Filters the Geojson based on the dataview filters the
     * @param [geojson] -UNUSED TODO FINISH pass in geojson to filter must have same id field in feature group properties
     * @returns {*} - A geojson object of all items in the table (after filter)
     */
    getFilteredGeojson(geojson) {
        // let items = this.dataView.getFilteredItems();
        // if(geojson) {
        //
        //       let items = geojson.features.filter(f=>items.some(i=>i[this.config.idField] == f.propertys[this.config.idField]);
        // } else {
        //     let
        // }
        return this.getGeojson(this.dataView.getFilteredItems());
    }


    updateMapLayer(layer) {

        if (layer) this.layer = layer;

        let oldMap;
        if (this.mapLayer && this.mapLayer.remove) {
            oldMap = this.mapLayer._map;
            this.mapLayer.remove();
        }

        switch (this.config.layerMode) {
            case "wms": {
                let l = this._layerManager.sourceManager.getLayer(g.source, g.workspace, g.layer);
                console.log(l);
                // l.addTo(map);
                this.mapLayer = l;
                break;
            }
            case "cluster": {
                let layers = this.layer.getLayers();

                if (!this.cluster) {
                    this.cluster = L.markerClusterGroup({disableClusteringAtZoom: 18});
                    this.cluster.addLayer(this.layer);
                } else {

                    let clusterLayers = this.cluster.getLayers()
                    this.cluster.removeLayers( // remove the layers that are in cluster layers but not layers
                        clusterLayers.filter(l => !layers.some(lx => lx.feature.properties.id == l.feature.properties.id))
                    );


                    this.cluster.addLayers(//add all layers that are not yet in clusterLayers
                        layers.filter(l => !clusterLayers.some(lx => lx.feature.properties.id == l.feature.properties.id))
                    );
                }

                // let cluster = L.markerClusterGroup({disableClusteringAtZoom: 18});


                // cluster.addTo(map);
                this.mapLayer = this.cluster;

                break;
            }
            case "geojson": {
                // this.layer.addTo(map);
                this.mapLayer = this.layer;

                break;
            }
        }

        console.log("oldMap: ", oldMap);
        if (oldMap) {
            this.mapLayer.addTo(oldMap);
        }

    }

    /**
     * Setup the spatial component if a geom is present
     * this consists of internal
     * this.layer - the L.geoJson layer
     * this.mapLayer - the selected layer which is added to the map.  wms, cluseter, geojson
     * this._highlightLayer - the layer where the hilighted rings go
     * @fires onSpatialInit
     * @private
     */
    _initSpatial() {
        // this.geojson = bs.rowsToGeojson(this.data, this.config.geomField, {
        //     onlyFields: ['id', this.config.layerTooltipField]
        // });
        this.geojson = this.getGeojson();

        this.isSpatial = true;
        let map = this._layerManager.map;


        let randomColor = this.config.layerColor || bs.getRandomColor();

        let self = this;

        this.layer = L.geoJson(this.geojson, {
            // http://leafletjs.com/reference.html#geojson-style
            //this is applied to points
            pointToLayer: function (feature, latlng) {

                if (typeof self.config.geojson.pointToLayer == 'function') {
                    let item = self.dataView.getItemById(feature.properties.id);
                    return self.config.geojson.pointToLayer(feature, latlng, item, self)
                }

                //Initial style: radius: size of circle, weight: thickness of outline, color: color of outline (black so easy to see), fillColor: color inside
                return L.circleMarker(latlng, {
                    radius: 5,
                    weight: 1,
                    color: '#000',
                    fillColor: randomColor,
                    fillOpacity: 1
                });
            },
            //this is applied to polylines and polygons and overrides some pointTolyaer styles
            style: function (feature) {

                if (typeof self.config.geojson.style == 'function') {
                    let item = self.dataView.getItemById(feature.properties.id);
                    return self.config.geojson.style(feature, item, self)
                }

                return {
                    color: randomColor,
                    weight: 2
                };
            },

            onEachFeature: async function (feature, layer) {
                let id = feature.properties.id
                if (self.config.layerTooltipField) {
                    layer.bindTooltip('' + feature.properties[self.config.layerTooltipField]);
                }

                layer.on('click', (e) => {
                    console.log('Layer clicked!', e);
                    self.highlightLayer.call(self, id, {
                        zoomMode: 'aroundPointIntelligent'
                    });
                });

            }

        });

        this._highlightLayer = L.geoJson(null, {
            pointToLayer: function (feature, latlng) {
                return L.circleMarker(latlng, {
                    radius: 10,
                    color: "#00FF00",
                    weight: 5,
                    opacity: 0.3,
                    //fillColor: "#00FFFF",
                    fillOpacity: 0,
                    interactive: false,
                    clickable: false
                });
            },
            style: function (feature) {
                return {
                    color: "#00FF00",
                    weight: 10,
                    opacity: 0.3,
                    //fillColor: "#00FFFF",
                    fillOpacity: 0,
                    interactive: false,
                    clickable: false
                };
            }
        });

        this._highlightLayer.addTo(this._layerManager.map);

        let g = this.config.geoserver;//the layer manager layer config
        // let l = this._layerManager.sourceManager.getLayer(g.source, g.workspace, g.layer);
        // console.log(l);
        // l.addTo(map);
        // this.layer.addTo(map);
        switch (this.config.layerMode) {
            case "wms": {
                let l = this._layerManager.sourceManager.getLayer(g.source, g.workspace, g.layer);
                console.log(l);
                // l.addTo(map);
                this.mapLayer = l;
                break;
            }
            case "cluster": {
                let cluster = L.markerClusterGroup({disableClusteringAtZoom: 18});

                cluster.addLayer(this.layer);
                // cluster.addTo(map);
                this.mapLayer = cluster;

                break;
            }
            case "geojson": {
                // this.layer.addTo(map);
                this.mapLayer = this.layer;

                break;
            }
        }

        this.updateMapLayer(this.layer);

        if (this.config.gridOptions.initPanel) {
            this.mapLayer.addTo(map)
        }


        this.onshow.subscribe(() => {
            this.mapLayer.addTo(this._layerManager.map);
        })
        this.onhide.subscribe(() => {
            this.mapLayer.remove();
        })

        this.onAddItem.subscribe((e, item) => {
            console.log('e,item: ', e, item);

            // let geom = item[this.config.geomField];

            let geom = bs.rowsToGeojson([item], this.config.geomField, {
                onlyFields: ['id', this.config.layerTooltipField]
            });
            //todo update geojson and layer without regenerating it all.
            this._geojsonInvalid = true;

            if (geom && this.config.layerMode !== 'wms') {
                this.layer.addData(geom);
                this.updateMapLayer();
            }
        })

        this.onDelItem.subscribe((e, id) => {
            this.mapLayer.eachLayer(l => {
                if (l.feature.properties.id == id) {
                    l.remove();
                }
            })
        });
        // this.addToolbarButton('addRow', 'Add a row to the database', '<a class="fas fa-plus">', this.addRow);
        this.addToolbarButton('syncMap', 'Show rows in current map extents', icons.syncMap, this.syncMapClick);
        this.addToolbarButton('showAll', 'Show all rows in table', icons.syncAll, this.syncAllClick);
        this._autoSyncItem = this.addToolbarButton('autoSync',
            'Toggle(turn on or off) continuous live syncing to map extents',
            icons.autosyncOff,
            this.toggleAutoSyncClick
        );

        this._autoSync = false;

        map.on('moveend', (e) => {
            this._mapMovedSinceSync = true;
        });

        //the spatial filter fo filtering table to map extents
        this.addCustomFilter({
            name: "spatialFilter",
            fn: (item, args) => {

                if (args && args.disabled) {
                    return true;
                }

                let bounds = map.getBounds();
                let bbox = item[this.config.geomField].bbox;
                let llbounds

                if (!bbox) {
                    let l = this.findLeafletLayer(item[idField])
                    if (!l) {
                        console.error("[TableGrid] Unable to find layer ")
                    }
                    llbounds = l.getBounds()
                } else if (bbox.length === 6) {
                    //lng, lat, el, lng,lat,el
                    llbounds = L.latLngBounds([bbox[1], bbox[0]], [bbox[4], bbox[3]]);
                } else if (bbox.length === 4) {
                    //lng, lat, lng, lat
                    llbounds = L.latLngBounds([bbox[1], bbox[0]], [bbox[3], bbox[2]]);
                } else {
                    console.error("[TableGrid] Unable to find bbox for the layer in the geom did you export right in postgis")
                }
                let ret = bounds.intersects(llbounds);
                // console.log('filter spatial: ', ret, bounds, llbounds);

                return ret;
            },
            args: {disabled: true} // you must pass in defults for clear filters to work properly
        })
        //default this to show all so when things are clicked on it dosent filter the map by default
        // this.setCustomFilterArgs('spatialFilter', {disabled: true});

        //keep the map in sync with the table
        this.onFilter.subscribe((e) => {
            //todo we can maybe do this difrently but thisis to suport
            // other non spaital filters it is true that for spaital filers to
            // the extents we can leave all data on.  but the bug will apear for
            // data filtes so this catch all keeps it always in synk wit hthe table.
            this.spatialFilterSync();
        })

        this.grid.onClick.subscribe((e) => {
            console.log(e);
            var cell = this.grid.getCellFromEvent(e);
            //cell ={row: 6, cell: 2}
            let row = this.dataView.getItem(cell.row);
            let field = this.grid.getColumns()[cell.cell].id

            console.log("Grid Clicked: row-> ", row, '  field-> ', field);
            if (field != 'actions') {
                this.highlightLayer(row.id, {
                    zoomMode: 'intelligent',
                    scrollTo: 'view'
                });
            }

            console.log(cell)
        });

        this._initSpatialExport();

        if (this.panel) {
            this.resize();//this is so the tables can
        }

    }

    _initSpatialExport() {

        let optName = 'spatial(kml,shp,..)'
        this.registerExport(optName, (options) => {

            options.fileName = options.fileName.replace('.' + optName, '');
            let rows = this.getExportData(options.columnMode, options.filter, true);
            // g = this.geojson.co
            let geojson = {
                type: 'FeatureCollection',
                features: []
            }
            rows.forEach(r => {

                let f = this.geojson.features.find(f => f.properties.id == r.id);
                // let cp = Object.create(f)
                let a = bs.mergeDeep({}, f);
                bs.mergeDeep(a.properties, r);

                if (a.properties.hasOwnProperty('undefined')) {//todo fix properly
                    delete a.properties.undefined
                }
                // cp.properties.assign(r);
                geojson.features.push(a);

            })

            // this.geojson
            bs.geojsonDownload(geojson, "Table Spatial Export: " + this.config.view, options.fileName, true);
        })


    }

    findLeafletLayer(id) {

        return this.isSpatial?Object.entries(this.layer._layers)
                .find(e => e[1].feature.properties[this.config.idField] === id)
            : false
    }

    /**
     * Apply the table filter to the spatial data
     */
    spatialFilterSync() {
        this.layer.clearLayers();
        this.layer.addData(this.getFilteredGeojson());

        this.updateMapLayer();
    }

    /**
     * This is where the colors are configured to add a color add the row to the array
     * and also add a css class to core.css
     * ie
     * css:
     * .slick-highlight-row-red {
     *      background-color: #f35e5e !important; important is to override the odd color
     * }
     * array:
     * {grid: 'red', map: '#f35e5e'}
     *
     * note that 'red' is the grid name and at the end of the css class
     * @return {number|*|number}
     * @private
     */
    _nextHighlightColor() {
        if (!this._hlColors) {
            this._hlColors = [
                // {grid: 'red', map: '#f35e5e'},
                // {grid: 'blue', map: '#a0b0ff'},
                // {grid: 'green', map: '#82ff74'},
                // {grid: 'orange', map: '#ef9e47'},
                // {grid: 'purple', map: '#aa7eec'},
                // {grid: 'yellow', map: '#fcf66e'},
                // {grid: 'darkblue', map: '#5d71f8'},
                {grid: 'hyellow', map: '#fdffb6'},
                {grid: 'hgreen', map: '#caffbf'},
                {grid: 'hblue', map: '#9bf6ff'},
                {grid: 'hdarkblue', map: '#a0c4ff'},
                {grid: 'hpink', map: '#ffc6ff'},

            ]
            this._currentColor = 0;
        }


        this._currentColor = (this._currentColor + 1) % this._hlColors.length;
        return this._currentColor;
    }


    /**
     * Toggles row highlighting with support for geom layer
     * this gets called in both the onEachFeature of the layer and in the slick row clicked events.
     * @param id - the slick id
     * @param {Boolean} [options.state] - if you wish to set it to something specific instead of toggling
     * @param {Int}     [options.timeout] - setTimeout for toggling highlight back again
     * @param {string}  [options.scrollTo='top'] - if falsy wont scroll otherwise options are 'top', 'view',
     * @param {String} [options.zoomMode] - the mode see bellow options ie ..., 'mapCenter'});
     * @param {Option} options.zoomMode.mapCenter, - puts the point in the center of the map and zooms in to the zoom level.
     * @param {Option} options.zoomMode.aroundPoint, - zooms in to the zoom level with the point remaining in the same relative positions
     * @param {Option} options.zoomMode.intelligent, - places the point intelligently in the center largest visible spot on the map
     * @param {Option} options.zoomMode.aroundPointIntelligent, - if the point visible its zoomed around so the point doesn't move otherwise its intelligently panned to

     */
    highlightLayer(id, options) {


        options = options || {};
        let state = options.state;
        let timeout = options.timeout;
        let scrollTo = options.scrollTo || 'top';
        //zoommodes:
        // mapCenter, - puts the point in the center of the map and zooms in to the zoom level.
        // aroundPoint, - zooms in to the zoom level with the point remaining in the same relative positions
        // intelligent, - places the point intelligently in the center largest visible spot on the map
        // aroundPointIntelligent, - if the point visible its zoomed around so the point doesn't move otherwise its intelligently panned to
        let zoomMode = options.zoomMode || 'mapCenter';


        let row = this.dataView.getItemById(id);

        //toggle the state if not defined
        if (typeof state == 'undefined') {
            state = !row.highlight;
        }

        let colorIndex
        let color
        //set the highlight color
        if (state) {
            colorIndex = this._nextHighlightColor();
            color = this._hlColors[colorIndex];
            state = color.grid;
        }

        if (color && color.id) {

            this.highlightLayer(color.id, {state: false});

            //unhilight layer
            // color.layer.remove();
            // delete color.layer;
        }


        row.highlight = state;
        this.dataView.updateItem(row.id, row);
        this.grid.render();
        console.log('highlight row ', id, ": ", row.highlight);

        if (row.highlight) {


            // let idx = this.dataView.getIdxById("c60ce88f-efff-407c-ab63-c573f9e1951c")
            let idx = this.dataView.getFilteredItems().findIndex(i => i.id == id)

            if (row.highlight) {
                if (scrollTo === 'view') {
                    this.grid.scrollRowIntoView(idx);
                } else if (scrollTo === 'top') {
                    this.grid.scrollRowToTop(idx);
                }
            }

            // this._highlightLayer.clearLayers();
            let mapLayer = this.mapLayer.getLayers().find(l => l.feature.properties.id == id);

            // this._highlightLayer.addData(mapLayer.toGeoJSON()).bringToBack();

            if (!mapLayer) {
                bs.resNotify({
                    success: false, color: "warning",
                    msg: "Oops! This row doesn't seem to have a map or geographical data attached. If error reach out to your data administrator for assistance"
                })
                return;
            }

            switch (zoomMode) {
                case "mapCenter": {
                    leafletUtil.zoomMapCenter(mapLayer, this.config.highlightZoom)

                    break;
                }
                case "intelligent": {
                    leafletUtil.zoomIntelligent(mapLayer, this.config.highlightZoom)
                    break;
                }
                case "aroundPointIntelligent": {
                    leafletUtil.zoomAroundPointIntelligent(mapLayer, this.config.highlightZoom)
                    break;
                }
                case   "aroundPoint": {
                    leafletUtil.zoomAroundPoint(mapLayer, this.config.highlightZoom)
                    break;
                }
            }


            console.log('mlGeojson', mapLayer.toGeoJSON());
            let hlLayer = L.geoJson(mapLayer.toGeoJSON(), {
                pointToLayer: function (feature, latlng) {
                    return L.circleMarker(latlng, {
                        radius: 10,
                        color: color.map,
                        weight: 5,
                        opacity: 0.8,
                        //fillColor: "#00FFFF",
                        fillOpacity: 0,
                        interactive: false,
                        clickable: false
                    });
                },
                style: function (feature) {
                    return {
                        color: color.map,
                        weight: 10,
                        opacity: 0.8,
                        //fillColor: "#00FFFF",
                        fillOpacity: 0,
                        interactive: false,
                        clickable: false
                    };
                }
            });

            hlLayer.addTo(map).bringToBack();
            if (!Array.isArray(this._hlLayers)) {
                this._hlLayers = [];
            }
            this._hlLayers.push(hlLayer);
            // color.layer = hlLayer;
            color.id = id;

            // this._highlightLayer.addData(mapLayer.toGeoJSON()).bringToBack();

        } else {

            let i = this._hlLayers.findIndex(l => l.getLayers()[0].feature.properties.id == id);

            let c = this._hlColors.find(c => c.id == id)
            this._hlLayers[i].remove();
            this._hlLayers.splice(i, 1);
            if (c) {
                // delete c.layer;
                // delete c.id;
                c.id = false;
            }
        }


        // this.resize();

        if (typeof timeout != 'undefined') {
            setTimeout(() => {
                this.highlightLayer(id, {
                    state: !row.highlight,

                    // zoomMode: 'aroundPointIntelligent'
                })
            }, timeout)
        }

    }

    //i think this filters table features within spaital extents of the map
    syncMapClick(grid, item) {
        let btn = grid.panel.querySelector(item.selector);
        console.log("SYNC MAP", btn)
        if (grid._autoSync) {
            grid.toggleAutoSyncClick(grid, grid._autoSyncItem);
        }
        grid.setCustomFilterArgs('spatialFilter', {disabled: false});
        // grid._syncMap
    }

    //refetch data from db to show al rows
    async syncAllClick(grid, item) {

        let btn = grid.panel.querySelector(item.selector);
        console.log("SYNC ALL", btn)
        if (grid._autoSync) {
            grid.toggleAutoSyncClick(grid, grid._autoSyncItem);
        }
        await grid.updateData();

        grid.setCustomFilterArgs('spatialFilter', {disabled: true});
    }

    //continus syming to map etenets
    toggleAutoSyncClick(grid, item) {
        let btn = grid.panel.querySelector(item.selector);
        let $btn = $(btn);
        // ok we need jquery here becuse it initalizes the dom first or somthing
        if (grid._autoSync) {
            grid.autoSyncOff($btn);
        } else {
            grid.autoSyncOn($btn);
        }
    }

    autoSyncOn($btn) {
        console.log("AUTO SYNC ON")
        if ($btn) {
            //here .innerHtml does not work :)
            $btn.html(icons.autosyncOn);
        }
        this._autoSync = true;
        this.setCustomFilterArgs('spatialFilter', {disabled: false});
        this._autosync(1000);
    }

    autoSyncOff($btn) {
        console.log("AUTO SYNC OFF")
        if ($btn) {
            $btn.html(icons.autosyncOff)
        }
        this._autoSync = false;
    }

    //for spatial sync
    _autosync(timeout) {

        let self = this;

        let autosyncfn = () => {
            console.debug('autosync');
            if (self._mapMovedSinceSync) {
                self.syncTable(false);
            }
            if (self._autoSync) {
                setTimeout(autosyncfn, timeout);
            }
        }

        autosyncfn();
        //if not cheacked this will be the last sync.
    }

    /**
     * Syncs the table by applying filters including spatial filter
     * @param [noSpaital=false] - if you want to disable syncing the spaital component with the table data
     *
     */
    syncTable(noSpaital) {
        this._mapMovedSinceSync = false;
        this.updateFilter(noSpaital);
        if (this.isSpatial && !noSpaital) {
            this.spatialFilterSync();
        }
    }


    /**
     * prompts user for row data and adds a row to the table
     */
    addRow(options) {

        let defaults = {}
        this.fieldConfigs.forEach(c => {
            defaults[c.name] = '';
        });


        console.log("add row defaults: ", defaults);

        bs.prompt('Add Row', this.template, defaults, {
            stringifyArrays: true
        }).then(data => {
            console.log("Row:", data);

            let numCols = Object.keys(data).length
            this.fieldConfigs.forEach(c => {

                if (data[c.name] == '') {
                    delete data[c.name];
                    numCols--;
                }
            });

            console.log(data);
            if (numCols == 0) {
                bs.resNotify({
                    success: false,
                    msg: "You Need to specify at least one field to insert! "
                })
                return;
            }

            this._getTableData();


            bs.sendJsonAndNotify('post', this.url + '/insert', {row: data}).then(res => {
                console.log(res);
                if (res.success) {
                    this.addItem(res.data[0]);
                }
            })
        })
    }

    // show() {
    //
    //     if (this.isSpatial) {
    //         this.mapLayer.addTo(this._layerManager.map);
    //     }
    //     return super.show();
    // }
    //
    // hide() {
    //
    //     if (this.isSpatial) {
    //         this.mapLayer.remove();
    //     }
    //     return super.hide();
    // }

    /**
     * Fetch a row from database
     * @fires onEdit
     * @param id
     */
    async updateRow(id) {
        // let id = res.data[0][this.config.idField];
        let vres = await this.query('selectSimple', {
            key: this.config.idField,
            value: id
        });

        if (vres.success && vres.data.length) {
            let d = vres.data[0];
            // delete d.id;
            console.log("id===id", id === d.id, '... ', id, d.id)
            if (id !== d.id) {
                console.log("Id not === so using the id from data to be sure todo whyyyyyyy :(");
                id = d.id
            }
            this.dataView.updateItem(id, d);
            this.onEdit.notify({grid: this, row: d, id: id})
        } else console.warn("Failed to get new row from database no change to grid data made");

        return vres;
    }

    /**
     * This gets called by slick grid when an inline edit occurs
     * @param e
     * @param args
     */
    inlineEdit(e, args) {
        //do whatever is needed to inline hint: edit args._grid

        let col = args.column.id;
        let item = args.item;


        let config = this.fieldConfigs.find(c => c.name == col);

        let table = this.config.editTables.find(t => t.table == config.edit.table);

        let newVal = {}
        newVal[col] = item[col];

        //fix to allow seting a colum to null in inline mode by erasing the value like in pgadmin
        if(newVal[col] === "") newVal[col] = null;

        let tableId = table.tableId || this.config.idField;
        let viewId = table.viewId || this.config.idField;


        newVal[tableId] = item[viewId];//for where in sql

        console.log("EDIT", newVal);
        let url = bs.routeToURL(table.route + '/update');

        console.log(config);


        //ok now we dave to db
        bs.sendJsonAndNotify('post', url, {row: newVal}).then(async res => {
            console.log("EDIT RES:", res);
            // this.dataView.setItem(res.data[])
            // this.dataView.setItem(res.data[])

            if (!res.data[0]) {
                let err = `Looks like 0 rows were updated you are using the wrong id (editTables) or the table is empty (dba problem)!`
                bs.resNotify({success: false, msg: err});
            }
            let d = res.data[0]
            let id = d[this.config.idField];
            this.updateRow(id);
        })


    }


    /**
     * separates the edits but editTable in edit objects within url and the new row
     * @param changedCols array of column names that have changes
     * @param row - the full row
     * @param data - the edit data only needed for cols in changed cols
     * @param route - ie '/update' something to tag on to the url or do it later :)
     * @private
     */
    _getEdits(changedCols, row, data, route) {
        let edits = {};
        changedCols.forEach(col => {
            let config = this.fieldConfigs.find(c => c.name == col);
            let tName = config.edit.table
            if (!edits[tName]) {
                let table = this.config.editTables.find(t => t.table == tName);
                edits[tName] = {
                    url: bs.routeToURL(table.route + route),
                    row: {},
                }
                edits[tName].row[table.tableId] = row[table.viewId];//so that we have the edit id for the where sql
            }

            edits[tName].row[config.edit.field] = data[col];

        })
        return edits;
        //update editTable set k=v where editTable.tableId = row[editTable.viewId]
    }

    /**
     * action function if not called from an action mut have this == grid
     * @param id
     * @param grid
     */
    rowEdit(id, grid) {
        if (!grid) {
            grid = this;
        }

        // row[this.config.nameField]
        let row = grid.data.find((r) => r.id == id);
        let name = row[grid.config.nameField]/* row.nice_name || row.name || row.label || row.id;*/
        let headingid = 'prompt_summery_heading' + id;
        let collapseid = 'prompt_summery_collapse' + id;

        let vals = Object.entries(row)//['key', 'value']
            .filter(e => {
                let fc = grid.fieldConfigs.find(c => c.name == e[0]);
                return fc && fc.visible;
            })//filter out the non visible enteries
            .map(e => {
                return {Field: bs.niceName(e[0]), Value: e[1]}
            });//map them into a for table builder
        // grid.fieldConfigs
        let table = buildHtmlTable(vals);

        let summery = `
        <div class="card mb-3">
            <div class="card-header p-0" id="${headingid}">
                <h5 class="mb-0">
                    <button class="btn btn-secondary" style="width: 100%" data-toggle="collapse" data-target="#${collapseid}" aria-expanded="true" aria-controls="collapseOne">
                    View Row Summary
                    </button>
                </h5>
            </div>
            
            <div id="${collapseid}" class="collapse" aria-labelledby="${headingid}" >
            <div class="card-body">
            ${table.outerHTML}
            </div>
            </div>
        </div>
        `
        bs.prompt('Edit: ' + name, grid.template, row, {
            submit: 'Save ' + name,
            prependHTML: summery,
            stringifyArrays: true,
        }).then(data => {

            let changedCols = [];

            Object.keys(data).forEach(k => {
                if (data[k] == 'null') data[k] = null;
                if (data[k] != row[k]) {
                    changedCols.push(k);
                }
            })

            let edits = grid._getEdits(changedCols, row, data, '/update');

            Promise.all(Object.keys(edits).map(k => {
                console.log(edits[k]);
                return bs.sendJsonAndNotify('post', edits[k].url, {row: edits[k].row})
            })).then(vals => {
                console.log("VALS: ", vals);
                let shouldUpdate = true;
                vals.forEach(res => {
                    if (!res || !res.success) {
                        // bs.resNotify(res);
                        console.warn('Some Edits Failed to save so not updating table');
                        shouldUpdate = false;
                    }
                });

                if (shouldUpdate) {
                    // let row = grid.dataView.getItem(id);
                    // row = Object.assign(row, data);
                    // console.log('id: ', id, row);
                    // let id = res.data[0][this.config.idField];
                    grid.updateRow(id);
                    // grid.dataView.updateItem(row.id, row);
                }

            })
        })

    }


// jsonEdit(id) {
//     let row = 1.data.find((r) => r.id == id);
//     let name = row.nice_name || row.name || row.label || row.id;
//     bs.editJson('JSON Edit: ' + name, row).then(data => {
//         bs.sendJsonAndNotify('post', this.url + '/update', {row: data, key: 'id', value: id}).then(res => {
//             console.log(res);
//             if (res.success) this.dataView.updateItem(id, res.data[0]);//todo; maybe update all returned rows from and update request
//         })
//     })
// }

    /**
     * action function if not called from an action mut have this == grid
     * @param id
     * @param grid
     */
    delete(id, grid) {
        if (!grid) {
            grid = this;
        }
        // let row = this.data.find((r) => r.id == id);
        let row = grid.dataView.getItemById(id);
        let p = prompt(`Danger! This will permanently delete the ${row[grid.config.nameField]}.\nAre you sure? y/yes/YES`);
        if (p.toLowerCase() === 'y' || p.toLowerCase() === 'yes') {

            Promise.all(grid.config.editTables.map(t => {
                    let url = bs.routeToURL(t.route) + '/delete'

                    return bs.sendJsonAndNotify('delete', url, {
                        key: t.tableId,
                        value: row[t.viewId]
                    })

                    // .then(res => {
                    // console.log(res);
                    //     // if (res.success) grid.dataView.deleteItem(id)//todo; maybe update all returned rows from and update request
                    // });

                })
            ).then(vals => {
                console.log("VALS: ", vals);
                let shouldUpdate = true;
                vals.forEach(res => {
                    if (!res || !res.success) {
                        // bs.resNotify(res);
                        console.warn('Some Deletes Failed not deleting row in table');
                        shouldUpdate = false;
                    }
                })

                if (shouldUpdate) {
                    console.log('Removing Row: ', row.id);
                    grid.delItem(row.id)
                    // grid.dataView.deleteItem(row.id)
                }
            })

        }

    }

    //unused but useful todo doc? review
    editRelation(id, relation) {
        if (!this.relations) {
            this.relations = {};
        }
        if (!this.relations[relation]) {
            let rgrid = _globalGrids.find(g => g.options.table == relation)
            let ftable = rgrid.options.tables.find(t => t != this.options.table);
            if (!ftable) console.error('No Foreign Table with: ', relation);

            let fgrid = _globalGrids.find(g => g.options.table == ftable);
            if (!fgrid) console.error('No Forging Grid with: ', ftable);

            this.relations[relation] = {
                relation: relation,
                rgrid: rgrid,
                ftable: ftable,
                fgrid: fgrid,
            }


            console.log(this.relations[relation])
        }
        let r = this.relations[relation];
        let data = r.fgrid.dataView.getItems()
        let $modal = bs.createModal('editRelation' + bs.id++, "Edit Relations: " + bs.niceName(relation), '', false);
        let body = $modal[0].querySelector('.modal-body')
        body.style.display = 'grid';
        let boxes = data.map(d => {


            let label = d.nice_name || d[Object.keys(d).find(k => k.includes('name'))] || d.id;

            let relation = {}//the relation row for the relation table
            // let checked = false;
            r.relation.split('_').forEach(keyPart => {//site_group => site, group
                let key = keyPart + '_id';// site => site_id
                if (r.ftable.includes(keyPart)) {//is forigin grid
                    relation[key] = d.id; // the checkboxes data.id
                } else {
                    relation[key] = id // the ide of the clicked row
                }
            })

            //we need to see if our relation exists in the relation grid;
            let checked = r.rgrid.dataView.getItems().find(row => {
                let ret = true;
                Object.keys(relation).forEach(k => {
                    if (relation[k] != row[k]) ret = false;
                })
                return ret;
            });

            console.log('checked', checked);

            let box = this._createCheckbox(label, body, {checked: checked});
            console.log(box);
            box.input.addEventListener('click', (e) => {

                console.log(relation);

                if (box.input.checked) {
                    console.log("ADD REF", label);


                    bs.sendJsonAndNotify('post', r.rgrid.url + '/insert', {row: relation}).then(res => {
                        console.log(res);

                        if (res.success) r.rgrid.addItem(res.data[0])//todo; maybe update all returned rows from and update request
                    })


                } else {
                    console.log("DEL REF", label);
                    bs.sendJsonAndNotify('delete', r.rgrid.url + '/deleteRow', {row: relation}).then(res => {
                        console.log(res);
                        // r.rgrid.dataView.getItems().find(row =>

                        if (res.success) r.rgrid.delItem(checked.id)//todo; maybe update all returned rows from and update request
                    })
                }
            })

            return box;
            // bs.createCheckbox()
        });

        $modal.modal('show');
        // bs.prompt(bs.niceName(relation),)

    }

    /**
     * Creates a checkbox and appends it to container
     * @param labelText - the label text
     * @param container - the container to append it to
     * @param options optional options
     * @param options.id - the id to use for the checkbox input
     * @param options.inputClass - optional additional classes for the input tag ie 'my-input'
     * @param options.labelClass - optional additional classes for the label tag ie:'my-label mb3'
     * @param options.checked - bool, wether to cheack the box by deafult
     * @return {{input: any, label: any, id: (*|string)}}
     */
    _createCheckbox(labelText, container, options) {

        if (!options) {
            options = {}
        }
        let id = (typeof options.id != "undefined") ? options.id : 'bsutilcheck_' + this.id + '_' + (this.nextcheckid++).toString();
        let inputClass = (typeof options.inputClass != "undefined") ? options.inputClass : '';
        let labelClass = (typeof options.labelClass != "undefined") ? options.labelClass : '';
        let checked = (typeof options.checked != "undefined") ? options.checked : false;


        let label = L.DomUtil.create('label', labelClass, container);
        label.setAttribute('for', id);

        let input = L.DomUtil.create('input', inputClass, label);
        input.id = id;
        input.type = 'checkbox';
        input.checked = checked;

        let span = L.DomUtil.create('span', '', label);
        span.innerText = labelText;

        return {label: label, input: input, id: id};

    }

}
