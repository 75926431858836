class WFS {

    static example(url) {
        url = url || 'https://cwfis.cfs.nrcan.gc.ca/geoserver/ows'
        let wfs = new WFS(url, {});
        wfs.getDescribeLayer('public:elevation_250m')
    }


    /**
     * Create WFS Client
     * @param url - the wfs url ie https://myserver.com/geoserver/ows?
     * @param options
     * @param [options.version='1.1.0'] - wfs version
     * @param [options.service='WFS'] - wfs service
     */
    constructor(url, options = {}) {
        if (typeof url == "undefined") {
            throw "Error Must define WFS URL";
        }


        this.version = typeof options.version != 'undefined' ? options.version : '2.0.0'
        this.service = typeof options.service != 'undefined' ? options.service : 'WFS'
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')


/*        let params = {
            service: 'WFS',
            version: "2.0.0",
            request: "GetFeature",
            typeNames: "alpac:asmt_pointcloud",
            outputFormat: 'application/json',
        }*/

        this.url = url;
        if (this.url[this.url.length - 1] != '?') {
            this.url += '?';
        }
        this.capabilities = {};

    }

    async getCapabilities() {


        //todo options
        let time = Date.now();


        let params = {
            service: this.service,
            version: this.version,
            request: "getCapabilities",
        }

        let url = this.url + new URLSearchParams(params).toString();
        //
        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin', // include, *same-origin, omit
        });

        if (!response.ok) {
            console.error(`Response Not OK: ${response.statusText}`);
        }
        console.debug(`Done Fetching wfs getCapabilities ins ${Date.now() - time}ms`);
        time = Date.now();
        let text = await response.text();
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(text, "text/xml");
        console.debug(xmlDoc);

        //todo only re-parse if capabilities change?
        this.capabilities = {};
        this.capabilities.xml = xmlDoc;
        this.capabilities.lastUpdate = time;
/*        const tempCapabilityXML = this.capabilities.xml.getElementsByTagName('Capability')[0].children
        for (let i = 0; i < tempCapabilityXML.length; i++) {
            let xmlNode = tempCapabilityXML[i]
            switch (xmlNode.tagName.toString()) {
                case 'Request': {
                    let request = {}
                    request.xml = xmlNode;
                    this.capabilities.request = request;
                    break;
                }
                case 'Exception': {
                    let exception = {};
                    exception.xml = xmlNode;

                    this.capabilities.exception = exception;
                    break;
                }
                case 'Layer': {
                    if (typeof this.capabilities.layers == "undefined") this.capabilities.layers = [];
                    this.capabilities.layers.push(new WMSLayer(xmlNode));
                    break;
                }
                default:
                    console.warn(`Warning: Unknown capability sorting might have gon wrong parsing xml or the service ${xmlNode.tagName.toString()}`);

            }

        }*/
        console.debug(`Done Parsing getCapabilities in ${Date.now() - time}ms`);

        return xmlDoc;

        // let url = this.url + '?' + new URLSearchParams(params).toString();
        //
        // return fetch(url, {
        //     method: 'GET',
        //     credentials: 'same-origin', // include, *same-origin, omit
        // }).then(response => {
        //     if (!response.ok) {
        //         console.error(`Respopnse Not OK: ${response.statusText}`);
        //     }
        //     return response.text().then(function (text) {
        //         // do something with the text response
        //
        //
        //         let parser = new DOMParser();
        //         let xmlDoc = parser.parseFromString(text,"text/xml");
        //         console.debug(xmlDoc);
        //         console.debug(`Done Fetching getCapabilities ${Date.now() - time}ms`);
        //         return xmlDoc;
        //
        //     });
        // });

    }


    /**
     * DescribeFeatureType
     * DescribeFeatureType requests information about an individual feature type before requesting the actual data.
     * Specifically, the operation will request a list of features and attributes for the given feature type, or list
     * the feature types available.
     * https://docs.geoserver.org/latest/en/user/services/wfs/reference.html#describefeaturetype
     * @param layerName the full wfs layername ie 'alpac:pointcloud_layer'
     * @return {Promise<Document>}
     */
    async getDescribeLayer(layerName, params) {

        let time = Date.now();

        params = Object.assign({
            service: this.service,
            version: this.version,
            request: "DescribeFeatureType",
            typeNames: layerName,
            outputFormat: 'application/json',
        }, params);

        let url = this.url + new URLSearchParams(params).toString();
        //
        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin', // include, *same-origin, omit
        });

        if (!response.ok) {
            console.error(`Response Not OK: ${response.statusText}`);
        }
        console.debug(`Done Fetching wfs getCapabilities ins ${Date.now() - time}ms`);
        time = Date.now();
        let json = await response.json();
        console.debug(json);
        return json;

    }


    /**
     * Get the geojson from geoserver
     * @param layerName the full wfs layername ie 'alpac:pointcloud_layer'
     */
    async getGeoJson(layerName) {

        //enable passing in (workspace, layer)
        if(arguments.length === 2) {
            layerName = layerName + ':' + arguments[1]
        }

        //todo options
        let time = Date.now();

        let params = {
            service: this.service,
            version: this.version,
            request: "GetFeature",
            typeNames: layerName,
            outputFormat: 'application/json',
        }

        let url = this.url + new URLSearchParams(params).toString();
        //
        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin', // include, *same-origin, omit
        });

        if (!response.ok) {
            console.error(`Response Not OK: ${response.statusText}`);
        }
        console.debug(`Done Fetching wfs GetFeature in ${Date.now() - time}ms`);
        time = Date.now();

        let json = await response.json();

        console.debug(`Done Parsing wfs GetFeature in ${Date.now() - time}ms`);
        return json;
    }


    /**
     *
     * @param layer - workspace:layername in geoserver
     * @param properties - the feature properties to return
     * @param filter - the filter object key value pairs
     * @param count - num of rows
     * @param sortby - filed to sort by
     * @returns {Promise<void>}
     */
    async getFeatures(layer, properties, filter, count, sortby) {

        // this.re


        // ['reservation_number', ]
    }


    async  clrSearch(num) {


        //first we get all options from geoserver
        let url = bs.routeToURL('/data6/ows?service=WFS&version=2.0.0&request=GetFeature&typeNames=base_data%3Av_did_clr_lt&outputFormat=application%2Fjson&propertyName=reservation_number&valueReference=reservation_number&value=CLR990004')

        let data = await bs.sendJson('get', url)
        // console.log(data);
        // let json = await JSON.parse(data);


        function getFeatureById(layer, id) {

            let url = bs.routeToURL(
                '/data6/ows?service=WFS&version=2.0.0&request=GetFeature&typeNames=base_data%3Av_did_clr_lt&outputFormat=application%2Fjson&featureID'
            );


        }

        let allCLRNums =  data.features.map(r=>r.properties.reservation_number.includes(num))

        let numIdMap =  Object.fromEntries(data.features.map(r=>{
            return [r.properties.reservation_number, r.id]
        }))

        // function

        let idUrlMap =  Object.fromEntries(data.features.map(r=>{
            return [r.id, bs.re]
        }))


        //then we filter avalible options
        num = num + '';//cast to string
        let opts = data.features.filter(r=>r.properties.reservation_number.includes(num))

        console.log(opts)


        return opts;
        // https://portal3.lorrnel.com/data6/ows?service=WFS&version=2.0.0&request=GetFeature&typeNames=base_data%3Av_did_clr_lt&outputFormat=application%2Fjson&propertyName=reservation_number&valueReference=reservation_number&value=CLR990004
        // num = num ||  'CLR990004'
        // this.search('base_data:v_did_clr_lt', 'reservation_number', num)
    }
// clrSear,ch('CLR990004')
    /**
     *
     * @param layerName
     * @param propertyName
     * @param valueReference
     * @param count
     * @param sortby
     * @returns {Promise<any>}
     */
    async search(layerName,propertyName, valueReference, count, sortby) {

        //enable passing in (workspace, layer)
        if(arguments.length === 2) {
            layerName = layerName + ':' + arguments[1]
        }

        //todo options
        let time = Date.now();

        let params = {
            service: this.service,
            version: this.version,
            request: "GetFeature",
            typeNames: layerName,
            outputFormat: 'application/json',

            propertyName,
            valueReference,
            //where
            // ...properties
        }

        if(sortby) params.sortby= sortby;
        if(count) params.count = count;

        let url = this.url + new URLSearchParams(params).toString();
        //
        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin', // include, *same-origin, omit
        });

        if (!response.ok) {
            console.error(`Response Not OK: ${response.statusText}`);
        }
        console.debug(`Done Fetching wfs GetFeature in ${Date.now() - time}ms`);
        time = Date.now();

        let json = await response.json();

        console.debug(`Done Parsing wfs GetFeature in ${Date.now() - time}ms`);
        return json;
    }

}
// https://portal3.lorrnel.com/data6/ows?service=WFS&version=2.0.0&request=GetFeature&typeNames=base_data%3Av_did_clr_lt&outputFormat=application%2Fjson
// &propertyName=reservation_number&valueReference=reservation_number&value=CLR990004
