//Utilitys writen by kier lindsays
//dependancys
// bootstrap 4
// jquery
// leaflet 1.0+
//

// bootstrap
class BSUtil {
    constructor(id) {
        this.nextcheckid = 0;
        this.nexttabid = 0;
        this.id = id;

    }

    //
    // options:
    //  id: the id to use for the checkbox input
    //  inputClass: optional additional classes for the input tag ie 'my-input'
    //  labelClass: optional additional classes for the label tag ie:'my-label mb3'
    //  checked: bool, wether to cheack the box by deafult
    /**
     * Creates a checkbox and appends it to container
     * @param labelText - the label text
     * @param container - the container to append it to
     * @param options optional options
     * @param options.id: the id to use for the checkbox input
     * @param options.inputClass: optional additional classes for the input tag ie 'my-input'
     * @param options.labelClass - optional additional classes for the label tag ie:'my-label mb3'
     * @param options.checked - bool, wether to cheack the box by deafult
     * @return {{input: any, label: any, id: (*|string)}}
     */
    createCheckbox(labelText, container, options) {

        if (!options) {
            options = {}
        }
        let id = (typeof options.id != "undefined") ? options.id : 'bsutilcheck_' + this.id + '_' + (this.nextcheckid++).toString();
        let inputClass = (typeof options.inputClass != "undefined") ? options.inputClass : '';
        let labelClass = (typeof options.labelClass != "undefined") ? options.labelClass : '';
        let checked = (typeof options.checked != "undefined") ? options.checked : false;


        let label = L.DomUtil.create('label', labelClass, container);
        label.setAttribute('for', id);

        let input = L.DomUtil.create('input', inputClass, label);
        input.id = id;
        input.type = 'checkbox';
        input.checked = checked;

        let span = L.DomUtil.create('span', '', label);
        span.innerText = labelText;

        return {label: label, input: input, id: id};

    }

    // Options
    // append/prepend: bool, wether to ad a append/prepend div
    createInputGroup(container, prepend, append) {

        let ret = {}
        ret.inputGroup = L.DomUtil.create('div', 'input-group  mb-3', container);
        if (prepend) {
            ret.prepend = L.DomUtil.create('div', 'input-group-prepend', ret.inputGroup);
        }
        ret.input = L.DomUtil.create('input', 'form-control', ret.inputGroup);

        if (append) {
            ret.append = L.DomUtil.create('div', 'input-group-append', ret.inputGroup);

        }

        return ret;

    }


    //items = [{labelText: 'my Box', options:{same as bsutil.createCheackbox options}},{...}]
    createCheckDropdown(buttonHTML, items, container) {
        let self = this;
// <ul class="dropdown-menu checkbox-menu allow-focus">
//   <li >
//     <label>
//       <input type="checkbox"> Cheese
//     </label>
//   </li>
// </ul>
        let ret = {};
        // ret.dropdown = L.DomUtil.create('ul', "dropdown-menu checkbox-menu allow-focus", container);
        // ret.dropdown = L.DomUtil.create('div', "dropdown", container);

        ret.button = L.DomUtil.create('button', "btn btn-default dropdown-toggle", container);
        ret.button.type = "button";
        ret.button.innerHTML = buttonHTML;
        ret.button.setAttribute('data-toggle', "dropdown");
        ret.button.setAttribute('aria-haspopup', "true");
        ret.button.setAttribute('aria-expanded', "false");
        ret.dropdownMenu = L.DomUtil.create('div', "dropdown-menu", container);
        ret.dropdownMenu.setAttribute('aria-labelledby', "dropdownMenuButton");

        ret.items = [];
        items.forEach(i => {
            let li = L.DomUtil.create('a', 'dropdown-item', ret.dropdownMenu);
            // li.innerHTML = i.labelText;
            let box = self.createCheckbox(i.labelText, li, i.options);
            li.addEventListener('click', (e) => {
                if (e.target.tagName == 'A')
                    box.input.checked = !box.input.checked;
            })
            ret.items.push(box);
        });


        // $(".checkbox-menu").on("change", "input[type='checkbox']", function() {
        //     $(this).closest("li").toggleClass("active", this.checked);
        // });
        //
        // $(document).on('click', '.allow-focus', function (e) {
        //     e.stopPropagation();
        // });
        return ret;
    }

    //tabs: ['Tab 1', 'Cool Tab']
    //@return {
    //         container: tabsdiv,
    //         currentTab: 0,
    //         tabs: [...]
    //         }
    //https://gitlab.com/vgeo1/asmt/-/issues/89
    createTabs(tabs, container) {
        let tabsdiv = L.DomUtil.create('div', '', container);
        // let ids = [];
        // let outTabs = [];
        let ret = {
            container: tabsdiv,
            currentTab: 0,
            tabs: []
        }
        let list = L.DomUtil.create('ul', 'nav nav-tabs', tabsdiv);

        for (let i = 0; i < tabs.length; i++) {
            let tab = {}
            tab.id = `bsutiltab_${this.id}_${this.nexttabid++}`
            tab.li = L.DomUtil.create('li', 'nav-item', list);
            tab.a = L.DomUtil.create('a', 'nav-link', tab.li);
            tab.a.setAttribute('href', '#' + tab.id);
            tab.a.innerHTML = tabs[i];
            tab.div = L.DomUtil.create('div', '', tabsdiv);
            tab.div.id = tab.id;
            tab.name = tabs[i];
            $(tab.div).hide();

            if (i === 0) {
                $(tab.a).toggleClass('active', true);
                $(tab.div).show();
            }

            var oldTab
            tab.li.addEventListener('click', () => {
                oldTab = ret.tabs[ret.currentTab]
                $(oldTab.div).hide();
                $(oldTab.a).toggleClass('active', false);
                $(tab.div).show();
                $(tab.a).toggleClass('active', true);

                ret.currentTab = ret.tabs.indexOf(tab);

            })
            ret.tabs.push(tab);
        }

        return ret;
    }


}

// bsutil = new BSUtil();
// b.createCheckDropdown('my dropdown', [
//     {
//         labelText: 'my Box',
//         options: {
//             id: 'layerbox',
//             checked: true
//         }
//     }], asset_toolbar.getContainer())
