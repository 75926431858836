// import Grid from 'Grid.js'

class DatabaseGrid extends Grid {

    /**
     * This is ment to fully construct a mangment grid for databases
     * @param route the route to the db api ie /api/db/sites
     * @param options grid options as well as some config for 'this'
     * @param options.table the name of the table
     */
    constructor(route, options) {

        let defaults = {
            containerType: 'jspanel',
            jspanelOptions: {
                panelSize: {
                    width: window.innerWidth * 0.9,
                    height: 400,
                },
                position: {
                    my: 'right-bottom',
                    at: 'right-bottom',
                    offsetX: 5,
                    offsetY: 5
                },

                headerTitle: 'Database Grid',

            },

            gridOptions: {
                // enableAddRow: true,
                autosizeColsMode: Slick.GridAutosizeColsMode.LegacyForceFit,
                // autosizeColsMode: Slick.GridAutosizeColsMode.FitViewportToCols,

                multiColumnSort: true,
                numberedMultiColumnSort: true,
                tristateMultiColumnSort: true,
                sortColNumberInSeparateSpan: false,
            }

        }

        options = bs.mergeDeep(defaults, options);

        //this initalizes the object with grid defaults our defults should take priority but doesn't initalize the grid yet
        super([], [], options, false);
        //now 'this' can be used


        this.url = bs.routeToURL(route);

        bs.sendJsonAndNotify('get', bs.routeToURL(route + '/selectAll')).then(res => {
            console.log("Row0", res.data[0]);
            this.data = res.data;
            this.columns = [
                {
                    id: "#",
                    name: "",
                    width: 40,
                    behavior: "selectAndMove",
                    selectable: false,
                    resizable: false,
                    cssClass: "cell-reorder dnd",
                    formatter: () => "<a class='fas fa-bars'></a>"
                }];

            if (!this.data || this.data[0] == null) {
                console.error("No Data", this.data);
            }
            this.template = {}
            this._uniqueValues = {};

            Object.keys(this.data[0]).forEach(key => {//key = column name/ field


                if ((key.includes('id') && !options.tables) ||//exclude id from non relation tables
                    key.includes('password')) {
                    //skip
                    return;
                } else {

                }

                let column = {id: key, name: bs.niceName(key), field: key, width: 75, sortable: true};
                this.columns.push(column);
                let noedit = ['id', 'createdby', 'createddate', 'lastmodified', 'modifiedby', 'last_login', 'password', 'updatedatabaseusers']
                if (noedit.includes(key)) return;


                let values = this.data.map(row => row[key]);
                let uniqueValues = bs.uniqueArray(values);

                this._uniqueValues[key] = uniqueValues;

                console.log(key, values[0])
                console.log("Typeof: ", typeof values[0]);

                if (values[0] && typeof values[0] == 'object') {
                    this.template[key] = 'json';

                } else {
                    // if(uniqueValues.length == 0 ||(uniqueValues.length==1 &&uniqueValues[0]== null) )
                    this.template[key] = {type: 'datalist', data: bs.uniqueArray(values), placeholder: key};

                    if (uniqueValues.includes(null)) this.template[key].notRequired = true;//todo might be broken

                }


            });

            if (!('id' in res.data[0])) {//if no id make one
                this.columns.push({id: "id", name: "id", field: "id", minWidth: 30});
                let i = 0;
                res.data.forEach(row => {
                    row.id = i++;
                })
            }


            let length = 0;
            if (Array.isArray(this.options.relations))
                length = this.options.relations.length;

            let w = (length * 85) + 90
            this.columns.push({
                id: 'actions',
                name: 'Actions',
                field: 'id',
                formatter: this.actionFormatter,

                minWidth: w,
                width: w,
                maxWidth: w
            });

//SETUP TOOLBAR
            this.options.jspanelOptions.headerToolbar = [
                // ...headerToolbar,
                '<span name="addRow" title="Adds the selected preset to the selected site" class="btn btn-sm">Add Row</span>',
                // '<span name="createPreset" title="Create a new Empty Preset" class="btn btn-sm">Create New Preset</span>',
                // '<span name="group" title="Toggle Grouping On or off" class="btn btn-sm">Grouping <a class="fas fa-bars"></span>',

            ]
            this.options.jspanelOptions.callback = (panel) => {
                // handler for the icons

                let addRow = panel.headertoolbar.querySelector('span[name=addRow]');
                // let configSelect = panel.headertoolbar.querySelector('select[name=configSelect]');
                console.log("jspanal callback", panel)
                addRow.addEventListener('click', (e) => {
                    console.log('Add Row: ', e.target.value);
                    this.addRow();
                });


            }

            //no this.data and this.comumns should be set so we can init;
            this.sortcol = "id";
            this.sortdir = 1;
            //Initalize the grid
            this.init();

            this.addRowSearch();

            // this.bsutil = new BSUtil('dbgrid' + this.id);
        })

        // this.setupToolBar();
    }

    /**
     * We have to do it this way so the formatters have access to 'this' since js class objects are just normal functions :/
     */
    initFormatters() {

        this.actionFormatter = (row, cell, value, columnDef, dataContext) => {

            let html = ``;


            html += `<button onclick="_globalGrids[${this._id}].delete('${dataContext.id}')" class="slick-icon-btn btn btn-danger btn-sm p-1 m-1  float-right" title="Delete Row">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                         </button>`

            html += `<button onclick="_globalGrids[${this._id}].rowEdit('${dataContext.id}')" class="slick-icon-btn btn btn-success btn-sm p-1 m-1 float-right" title="Edit Row Prompt">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                 </button>`
            html += `<button onclick="_globalGrids[${this._id}].jsonEdit('${dataContext.id}')" class="slick-icon-btn btn btn-success btn-sm p-1 m-1 float-right" title="Edit Row JSON">
                                    <i class="fa fa-outdent" aria-hidden="true"></i>
                                 </button>`

            if (this.options.relations) {
                this.options.relations.forEach(r => {
                    let split = r.split('_');
                    let label = split
                        .map(s => s.substr(0, 1).toUpperCase())
                        .join('⨝');//user_groups -> UG

                    let tip =  "Edit The " + split
                        .map(s => s.substr(0, 1).toUpperCase()
                            +s.substr(1, s.length-1).toLowerCase())
                        .join(' ') + " Relation";//user_groups -> User Groups
                    html += `<button onclick="_globalGrids[${this._id}].editRelation('${dataContext.id}', '${r}')" 
                                title="${tip}"
                                class="slick-icon-btn btn btn-danger btn-sm p-1 m-1  float-right" >
                            ${label} 
                         </button>`
                })
            }
            return html;
        };
    }

    addRow() {
        bs.prompt('Add Row', this.template).then(data => {
            // data.org_id = client.org_id;

            if(!data.org_id && this.data[0].org_id) {
                data.org_id = client.org_id;
            }
            if(!data.client_id && this.data[0].client_id) {
                data.client_id = client.id;
            }//
            // delete data.org_id; //todo do this in a better way so that we set the client ptroperly (maybe on the server)
            console.log("Row:", data);
            bs.sendJsonAndNotify('post', this.url + '/insert', {row: data}).then(res => {
                console.log(res);
                if (res.success) {
                    this.addItem(res.data[0]);
                }
            })
        })
    }

    rowEdit(id) {
        let row = this.data.find((r) => r.id == id);
        let name = row.nice_name || row.name || row.label || row.id;
        bs.prompt('Edit:' + name, this.template, row).then(data => {
            // console.log("id roe data",id,row,data);
            bs.sendJsonAndNotify('post', this.url + '/update', {row: data, key: 'id', value: id}).then(res => {
                console.log(res);
                if (res.success) this.dataView.updateItem(id, res.data[0]);//todo; maybe update all returned rows from and update request
            })
        })

    }

    jsonEdit(id) {
        let row = this.data.find((r) => r.id == id);
        let name = row.nice_name || row.name || row.label || row.id;
        bs.editJson('JSON Edit: ' + name, row).then(data => {

            console.log("Result:", data)

            bs.sendJsonAndNotify('post', this.url + '/update', {row: data, key: 'id', value: id}).then(res => {
                console.log(res);
                if (res.success) this.dataView.updateItem(id, res.data[0]);//todo; maybe update all returned rows from and update request
            })
        })
    }

    delete(id) {
        // let row = this.data.find((r) => r.id == id);
        let p = prompt("Are you sure? y/yes/YES");
        if (p == 'y' || p.toLowerCase() == 'yes') {
            bs.sendJsonAndNotify('delete', this.url + '/delete', {key: 'id', value: id}).then(res => {
                console.log(res);
                if (res.success) this.dataView.deleteItem(id)//todo; maybe update all returned rows from and update request
            });

        }

    }

    editRelation(id, relation) {

        if (!this.relations) {
            this.relations = {};
        }
        if (!this.relations[relation]) {
            let rgrid = _globalGrids.find(g => g.options.table == relation)
            let ftable = rgrid.options.tables.find(t => t != this.options.table);
            if (!ftable) console.error('No Foreign Table with: ', relation);

            let fgrid = _globalGrids.find(g => g.options.table == ftable);
            if (!fgrid) console.error('No Forging Grid with: ', ftable);

            this.relations[relation] = {
                relation: relation,
                rgrid: rgrid,
                ftable: ftable,
                fgrid: fgrid,
            }


            console.log(this.relations[relation])
        }

        let r = this.relations[relation];
        let data = r.fgrid.dataView.getItems()
        let $modal = bs.createModal('editRelation' + bs.id++, "Edit Relations: " + bs.niceName(relation), '', false);
        let body = $modal[0].querySelector('.modal-body')
        body.style.display = 'grid';
        let tableName = r.fgrid.url.split('/').pop();

        let uClients = []

        let boxes = data.map(d => {
            // console.log( "JKGHSAJKHADSAJKL", r.fgrid)
            // switch (relation) {
            //
            // }
            let label = '~ ';
            console.log(tableName);
            if(tableName == 'users')
                label += d.username +': '+d.fname + ' ' + d.lname;

            else if(tableName == 'sites') {
                label += d.site_name + ' - ' + d.nice_name + ': ' + d.remarks;
            }

            else if(tableName == 'groups')
                label += d.group_name + ': ' + d.remarks;

            else {
                label += d.nice_name || d[Object.keys(d).find(k => k.includes('name'))] || d.id;
            }

            // let nn = d.nice_name;
            // let an = d[Object.keys(d).find(k => k.includes('name'))];
            // let label = ""
            // if(nn) label += an
            // if(an) label += ' - '+ an;
            //
            // if(!label) label = d.id;

            // let label = d.nice_name || d[Object.keys(d).find(k => k.includes('name'))] || d.id;

            let relation = {}//the relation row for the relation table
            // let checked = false;
            r.relation.split('_').forEach(keyPart => {//site_group => site, group
                let key = keyPart + '_id';// site => site_id
                if (r.ftable.includes(keyPart)) {//is forigin grid
                    relation[key] = d.id; // the checkboxes data.id
                } else {
                    relation[key] = id // the ide of the clicked row
                }
            })

            //we need to see if our relation exists in the relation grid;
            let checked = r.rgrid.dataView.getItems().find(row => {
                let ret = true;
                Object.keys(relation).forEach(k => {
                    if (relation[k] != row[k]) ret = false;
                })
                return ret;
            });

            console.log('checked', checked);

            if(checked) {
                uClients.push(d.client_id);
            }

            let box = this._createCheckbox(label, body, {checked: checked});
            console.log(box);
            box.input.addEventListener('click', (e) => {

                console.log(relation);

                if (box.input.checked) {
                    console.log("ADD REF", label);

                    if(!uClients.includes(d.label)) {
                        let v = prompt(`
Warning this is about to add site belonging to ANOTHER CLIENT to this group.
This is dangerous because IF a client is given access to another clients data The LEGAL AND SECURITY IMPLICATION ARE SEVERE.

 Are you sure? 'y' to confirm`)
                        if(v != 'y') {
                            box.input.checked = false;
                            return;
                        }
                    }

                    bs.sendJsonAndNotify('post', r.rgrid.url + '/insert', {row: relation}).then(res => {
                        console.log(res);

                        if (res.success) r.rgrid.addItem(res.data[0])//todo; maybe update all returned rows from and update request
                    })


                } else {
                    console.log("DEL REF", label);
                    bs.sendJsonAndNotify('delete', r.rgrid.url + '/deleteRow', {row: relation}).then(res => {
                        console.log(res);
                        // r.rgrid.dataView.getItems().find(row =>

                        if (res.success) r.rgrid.dataView.deleteItem(checked.id)//todo; maybe update all returned rows from and update request
                    })
                }
            })

            return box;
            // bs.createCheckbox()
        });


        uClients = bs.uniqueArray(uClients);

        if(uClients.length > 1) bs.resNotify({success:false, msg: 'WARNING THIS GROUP HAS MORE THEN ONE CLIENT IN IT. num: ' +uClients.length , color: 'warning'})
        $modal.modal('show');
        // bs.prompt(bs.niceName(relation),)

    }

    //
    // options:
    //  id: the id to use for the checkbox input
    //  inputClass: optional additional classes for the input tag ie 'my-input'
    //  labelClass: optional additional classes for the label tag ie:'my-label mb3'
    //  checked: bool, wether to cheack the box by deafult
    /**
     * Creates a checkbox and appends it to container
     * @param labelText - the label text
     * @param container - the container to append it to
     * @param options optional options
     * @param options.id: the id to use for the checkbox input
     * @param options.inputClass: optional additional classes for the input tag ie 'my-input'
     * @param options.labelClass - optional additional classes for the label tag ie:'my-label mb3'
     * @param options.checked - bool, wether to cheack the box by deafult
     * @return {{input: any, label: any, id: (*|string)}}
     */
    _createCheckbox(labelText, container, options) {

        if (!options) {
            options = {}
        }
        let id = (typeof options.id != "undefined") ? options.id : 'bsutilcheck_' + this.id + '_' + (this.nextcheckid++).toString();
        let inputClass = (typeof options.inputClass != "undefined") ? options.inputClass : '';
        let labelClass = (typeof options.labelClass != "undefined") ? options.labelClass : '';
        let checked = (typeof options.checked != "undefined") ? options.checked : false;


        let label = L.DomUtil.create('label', labelClass, container);
        label.setAttribute('for', id);

        let input = L.DomUtil.create('input', inputClass, label);
        input.id = id;
        input.type = 'checkbox';
        input.checked = checked;

        let span = L.DomUtil.create('span', '', label);
        span.innerText = labelText;

        return {label: label, input: input, id: id};

    }


}
