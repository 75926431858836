/**
 * Example mixin function for a js class lets see if docs find it( yes it does woo)
 * So this adds tabs to the slick grid so we can display other info you are responsible for
 * managing the tabs contents
 * also requires BSUtil.js
 * https://www.chartjs.org/docs/latest/general/options.html#chart
 * @param tabOptions the config options for tabs
 * @param [tabOptions.tabs=['grid', 'chart']] - the tabs you want
 * @param [tabOptions.gridTab=0] - the index of the tab to put the grid in
 * @param tabOptions
 */
Grid.prototype.initTabs = function (tabOptions) {
    console.log('Init Tabs', this);

    if (!this._bsutil) this._bsutil = new BSUtil('grid-tabs-' + this._id);

    let defaults = {
        tabs: ['grid', 'chart'],
        gridTab: 0,

    }


    if (!tabOptions) tabOptions = {}
    if (this.options.tabOptions) {
        tabOptions = bs.mergeDeep(this.options.tabOptions, tabOptions)
    }
    let opts = bs.mergeDeep(defaults, tabOptions);
    this.config.tabOptions = opts;

    //_tabs = {container , currentTab, tabs}
    this._tabs = this._bsutil.createTabs(opts.tabs, this.panel.content);


    // pkill -f node

    let g = this.grid.getContainerNode()
    this._tabs.tabs[opts.gridTab].div.appendChild(g); // apparently moving dom nodes is that easy

    //override the get panal; size function when we add tabs
    this.getPanelSize = function () {
        return {//if we have tabs
            width: this._tabs.container.parentElement.offsetWidth - 2,//-2 so the scroll bar doesn't spaz
            height: this._tabs.container.parentElement.offsetHeight - this._tabs.container.firstElementChild.clientHeight - 2,
        }
    }.bind(this);

    this.onInitPanel.subscribe(e => {
        this._tabs = this._bsutil.createTabs(opts.tabs, this.panel.content);
        let g = this.grid.getContainerNode()
        this._tabs[opts.gridTab].div.appendChild(g); // apparently moving dom nodes is that easy

        this.initResizer();
    });
    this.initResizer();
    this.resize();

}
// wwtp_sites.initTabs();


// let gridGetPanelSize = Grid.prototype.getPanelSize;
// /**
//  * Override the panel Size
//  * @return {{width: number, height: number}}
//  */
// Grid.prototype.getPanelSize = function() {
//     if(this._tabs) {
//         return {//if we have tabs
//             width: this._tabs.container.parentElement.offsetWidth - 2,//-2 so the scroll bar doesent spaz
//             height: this._tabs.container.parentElement.offsetHeight - this._tabs.container.firstElementChild.clientHeight - 2,
//         }
//     } else {
//         return gridGetPanelSize()
//     }
//
//
//
// }

/**
 * get the array of tabs
 * @returns {*}
 */
Grid.prototype.getTabs = function () {
    return this._tabs.tabs
}

/**
 * get the index should also accept tab name from the config (initTabs)
 * @param index - number index from getCurrentTab or name
 * @returns {*} - bsutil tab
 */
Grid.prototype.getTab = function (index) {
    if(this._tabs.tabs[i]) {
        return this._tabs.tabs[i]
    } else {
        return this._tabs.tabs.find(t=>t.name == index)
    }
}


/**
 *
 * @returns {number|number|*} - tab index of current tab
 */
Grid.prototype.getCurrentTab = function () {
    return this._tabs.currentTab

}
/**
 * returns the tab container div
 * @returns {*} - div element
 */
Grid.prototype.getTabContainer = function () {
    return this._tabs.container

}

/**
 * If tabs have been initalized for this grid
 * @returns {boolean}
 */
Grid.prototype.hasTabs = function () {
    return !!this._tabs

}

