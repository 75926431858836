/**
 * A manager class to store multiple wms sources
 */
class OWSSourceManager {

    getSourceByPanelLayer(l) {
        if (!l) {
            console.error('No Layer Provided')
            return undefined;
        }
        if (!l._source._url) {
            console.error('Layer Not Linked To Source :(', l)
            return undefined;
        }


        return Object.values(this.sources).find(s => s.url == l._source._url)
    }

    /***
     * Construct a new source object from a config.  this is ment to be users with the sources from the layer_config
     * @param {Array<Object> | Array<OWSSource>} sources An Array of source config objects {@see OWSSource#constructor}
     */
    constructor(sources, gun) {
        this.gun = gun;
        this.sourceConfig = sources;
        this.sources = {}
        this.keys = []
        this._capibilitiePromisies = [];
        this._fastCapibilitiePromisies = [];
        sources.forEach(source => {
            if (!(source instanceof OWSSource)) {
                source = new OWSSource(source, gun);
            }
            this.addSource(source);
        });


    }

    /**
     * Add a wms addSource object to the manager
     * @param {OWSSource} wmsSource
     */
    addSource(wmsSource) {
        let key = wmsSource.name
        this.keys.push(key);
        this.sources[key] = wmsSource;
        let capabilitiesPromise = wmsSource._capabilities;

        this._capibilitiePromisies.push(capabilitiesPromise);

        if (this.gun) {
            //add a promise for when we get the data out of gun
            this._fastCapibilitiePromisies.push(new Promise(resolve => {
                let node = this.gun.get('geoserver').get(key)
                var ev = null
                let s = wmsSource;

                let a = '';
                let failCount = 0
                var listenerHandler = (value, key, _msg, _ev) => {
                    //...
                    // console.log( 'GUN ON CAP Value :' ,value)
                    if (value) {
                        _ev.off();
                        let flat = flatted.parse(value)
                        console.log("GUN CAP FOR ", key, " :", flat);
                        s.wms.restoreCapabilities(flat);
                        a = `Using cached capabilities to mitigate!`;
                        resolve(value);
                    } else {
                        // resolve('FAILED FUCK');
                        failCount++;
                        console.log('CAP Bad Value :', value)
                    }
                }//on will call fast if we found a cached version in local storage
                node.on(listenerHandler)

                capabilitiesPromise.then(ret => {
                    let string = flatted.stringify(s.wms.capabilities)
                    // console.log('CAP RET: ',ret, " string:  ", string);
                    node.put(string)
                }).catch(async e => {
                        if (e.name === 'AbortError') {
                            console.log('Aborted geoserver cuz it took to long');

                            bs.resNotify({
                                success: false,
                                msg: `Error: Geoserver took to long to respond some layers may not work.
                                (${key} took more then 10s trying request again). ${a}`
                            });

                            //lets try again if there was no cached version in gun
                            if (!a) {
                                s.wms.getCapabilities({timeout: 60000}).then(ret => {
                                    let string = flatted.stringify(s.wms.capabilities)
                                    // console.log('CAP RET: ',ret, " string:  ", string);
                                    node.put(string)
                                }).catch(async e => {

                                    bs.sendJson('post', '/api/sendEmail/geoserverDown', {
                                        url: s.wms.url
                                    })

                                    if (e.name === 'AbortError') {
                                        console.log('Aborted geoserver cuz it took to long');

                                        bs.resNotify({
                                            success: false,
                                            msg: `Error: Geoserver failed to respond!
                                (${key} took more then 60s aborting request). Please Refresh!`
                                        });

                                    }
                                });
                            }
                        }
                    }
                )
            }))

        }//end gun

    }

    /**
     * Get a WMSSource by name
     * @param key the WMSSource.name of the source you want
     * @return {OWSSource | undefined}
     */
    getSource(key) {
        return this.sources[key];
    }

    /**
     * Returns all the sources that have the rasterTool Option turned on.
     * @return {OWSSource[]}
     */
    getRasterToolSources() {
        let ret = [];
        this.keys.forEach(k => {
            if (this.sources[k].sourceConfig.options.rasterTool === true) {
                ret.push(this.sources[k]);
            }
        })

        return ret;
    }

    /**
     * get a layer from a source forwards to WMSSource.getLayer
     * @param source the source name
     * @param workspace the wms workspace/namespace
     * @param layer the wms layer name
     * @return {L.Layer}
     */
    getLayer(source, workspace, layer) {
        return this.getSource(source).getLayer(workspace, layer);
    }

    /**
     * @param source the source name
     * @param workspace the wms workspace/namespace
     * @param layer the wms layer name
     * @return {L.Layer}
     */
    getWMSLayer(source, workspace, layer) {
        return this.getSource(source).getWMSLayer(workspace, layer);
    }

    /**
     * get a layer from a source forwards to WMSSource.getOverlay
     * @param source the source name
     * @param workspace the wms workspace/namespace
     * @param layer the wms layer name
     * @return {L.Layer}
     */
    getOverlay(source, workspace, layer) {
        return this.getSource(source).getOverlay(workspace, layer);
    }


}

/**
 * A class to represent a wms source and add layers to a leaflet map
 * @requires L.WMW
 */
class OWSSource {
    /***
     * A class that represents a layer source ie a geoserver
     * @param {Object} source a config object for seting up the source
     * @param {string} source.name A name for this server
     * @param {string} source.url The url to the wms service
     * @param {string} source.workspaces  An array of all workspaces for this server
     * @param {string} [source.options] Optional options configuration for L.WMS.source
     * @param {boolean} [source.options.bringToBack] Optional option configuration for L.WMS.source see its sorce file for docs
     * @param {boolean} [source.options.splitLayers=false] If set to ture a new leafelt.wms.source objecte will be made for each layer.
     *      This may efect performace if every layer is split and we are displaying many layers but if you need to contrile the layer
     *      images indapendantly for sidebyside and opacity then this must be true.
     *
     * @example source = new WMSSource( {
     "name": "lorrnel",
     "url": "https://geoserver.lorrnel.com:8084/geoserver/wms?",
     "workspaces": [
     "alpac",
     "base_data"
     ],
     "options": {
     "bringToBack": true
     }
     })
     */
    constructor(source) {
        if (typeof source.options == "undefined" || !source.options) {
            source.options = {};
        }
        //https://stackoverflow.com/questions/37881983/merge-two-javascript-objects-with-priority
        source.options = Object.assign({
            transparent: true,
            tiled: false,
            format: 'image/png',
            info_format: 'text/html', //'application/json'
            identify: false,
            maxZoom: 20,
        }, source.options);

        this.name = source.name;
        this.url = source.url;
        this.workspaces = source.workspaces;
        this.sourceConfig = source;

        this.leafletWMSSource = L.WMS.source(source.url, source.options);

        if (this.sourceConfig.options.splitLayers == true) {
            this.leafletWMSSources = {};
        }

        this.wfs = new WFS(source.url, {version: '2.0.0'});
        this.wms = new WMS(source.url, {version: '1.1.1'});

        try {
            this._capabilities = this.wms.getCapabilities();
        } catch (e) {
            console.error("Failed To Get Capability Is Your URL right? ", e);
        }

    }


    /**
     * @return {*} The L.WMS.source object
     */
    getLeafletSource() {
        return this.leafletWMSSource;
    }

    //
    /**
     * Gets a wms layer from the wms source if splitLayers is specified in the source options then this layer will be
     * Have its own wms sources one image per layer insted of one image per source
     * @param workspace the wms workspace/namespace ie base_data
     * @param layer the wms layer name
     * @param {string} [pane] the leafelt pain to add the layer too this overrides the source pane for this layer and is
     *  only usefull when spliting the layeres so each layer can be on its own pane
     * @return {L.Layer} the leaflet layer
     */
    getLayer(workspace, layer, pane) {

        if (typeof workspace == "undefined") {
            workspace = '';
        } else {
            workspace += ':';
        }
        let name = workspace + layer;

        return this.leafletWMSSource.getLayer(name);
    }

    getWMSLayer(workspace, layer, pane) {

        if (typeof workspace == "undefined") {
            workspace = '';
        } else {
            workspace += ':';
        }
        let name = workspace + layer;

        return this.wms.getAllLayers().find(l => l.name == name);
    }


    /**
     * Gets a L.WMS.Overlay from the wms source this is a single image layer
     * @param workspace the wms workspace/namespace ie base_data
     * @param layer the wms layer name
     * @param {string} [pane] the leafelt pain to add the layer too this overrides the source pane for this layer and is
     *  only usefull when spliting the layeres so each layer can be on its own pane
     * @return {L.Layer} the leaflet layer
     */
    getOverlay(workspace, layer, pane) {

        if (typeof workspace == "undefined") {
            workspace = '';
        } else {
            workspace += ':';
        }
        let name = workspace + layer;

        if (typeof pane == "undefined") {
            pane = "overlayPane"
        }

        let options = this.sourceConfig.options;
        options.opacity = 1;
        options.layers = name;
        options.pane = pane;
        console.log(options);
        return L.wms.overlay(this.url, options);
        // }
    }


    /**
     * idk why i did it this way we should probably be using L.wms.overlay use getOverlay() when possible probably

     * the creates a new L.WMS.Source and adds you layer to it. sorces can have multiple layers so this is handy if you
     * want to get a source for a module ie rasterTools
     * @param workspace
     * @param layer
     * @param {string} [pane] the leafelt pain to add the layer too this overrides the source pane for this layer and is
     *  only usefull when spliting the layeres so each layer can be on its own pane
     * @return {L.Layer} the leaflet layer
     */
    getLayerSource(workspace, layer, pane) {
        if (typeof workspace == "undefined") {
            workspace = '';
        } else {
            workspace += ':';
        }
        let name = workspace + layer;

        let options = this.sourceConfig.options;

        if (typeof pane != "undefined") {
            options["pane"] = pane;
        }

        console.log(typeof pane != "undefined", pane)
        console.log(options)
        //create a new l.wms.source for every layer
        let s = L.WMS.source(this.sourceConfig.url, options);
        s.addSubLayer(name);
        s.refreshOverlay();
        return s;
        /*
                if (typeof this.leafletWMSSources[name] == "undefined" || typeof pane != "undefined") {
                    this.leafletWMSSources[name] = L.WMS.source(this.sourceConfig.url, options);
                    this.leafletWMSSources[name].addSubLayer(name);
                }
                // this.leafletWMSSources[name] = L.WMS.source(this.sourceConfig.url, this.sourceConfig.options);
                // this.leafletWMSSources[name].refreshOverlay();
                return this.leafletWMSSources[name];
        */


    }


    /**
     * Brings the source to the back ( calls leafletWMSSource.bringToBack();)
     */
    bringToBack() {
        this.leafletWMSSource.bringToBack();
    }

    /**
     * Brings the source to the front ( calls leafletWMSSource.bringToFront();)
     */
    bringToFront() {
        this.leafletWMSSource.bringToFront();
    }
}


/**
 * A manager that keeps track of all site_config._layer_config.layers from the layer config
 */
class LayerManager {

    /**
     * Gets the deafult bas layer panal this will be called unless its defined and provided.
     * @param activeLayer
     * @return {*}
     */
    static getDefaultBaseLayersPanel(activeLayer, map) {


        let overLayers1 = {
            title: 'Layer Tree',
            layers: [
                {
                    group: "Base Maps",
                    collapsed: true,
                    layers: [
                        {
                            name: "Open Street Map",
                            //tooltip:"Open Street Map base layer",
                            active: activeLayer === 'openStreetMap',
                            icon: '<div class="icon"><span class="glyphicon glyphicon-picture"></span></div>',
                            //icon:iconByName('basemap'),
                            layer: LayerManager.getBaseLayer('openStreetMap')
                        },
                        {
                            name: "Open Topo Map",
                            //tooltip:"Open Street Map topograpical base layer",
                            active: activeLayer === 'openTopoMap',
                            icon: '<div class="icon"><span class="glyphicon glyphicon-picture"></span></div>',
                            layer: LayerManager.getBaseLayer('openTopoMap')
                        },
                        // {
                        //     name: "Esri WorldImagery",
                        //     active: activeLayer === 'esriWorldImagery',
                        //     //tooltip:"Esri world imagery base layer",
                        //     icon: '<div class="icon"><span class="glyphicon glyphicon-picture"></span></div>',
                        //     layer: LayerManager.getBaseLayer('esriWorldImagery')
                        // },
                        // {
                        //     name: "Esri WorldTopoMap",
                        //     active: activeLayer === 'esriWorldTopoMap',
                        //     //tooltip:"Esri topography world image base layer",
                        //     icon: '<div class="icon"><span class="glyphicon glyphicon-picture"></span></div>',
                        //     layer: LayerManager.getBaseLayer('esriWorldTopoMap')
                        // },
                        /*      {
                                  name: "Google Satellite",
                                  active: activeLayer === 'googleSatellite',
                                  //tooltip:"Google maps satellite base layer",
                                  icon: '<div class="icon"><span class="glyphicon glyphicon-picture"></span></div>',
                                  layer: LayerManager.getBaseLayer('googleSatellite')
                              },

                              {
                                  name: "Google Hybrid",
                                  active: activeLayer === 'googleHybrid',
                                  //tooltip:"Google maps hybrid base layer",
                                  icon: '<div class="icon"><span class="glyphicon glyphicon-picture"></span></div>',
                                  layer: LayerManager.getBaseLayer('googleHybrid')
                              },
                              {
                                  name: "USGS Imagery Topo",
                                  active: activeLayer === 'usgsImageryTopo',
                                  //tooltip:"Google maps hybrid base layer",
                                  icon: '<div class="icon"><span class="glyphicon glyphicon-picture"></span></div>',
                                  layer: LayerManager.getBaseLayer('usgsImageryTopo')
                              },*/
                        {
                            name: "Bing Aerial With Labels",
                            active: activeLayer === 'bingAerialWithLabels',
                            //tooltip:"Google maps hybrid base layer",
                            icon: '<div class="icon"><span class="glyphicon glyphicon-picture"></span></div>',
                            layer: LayerManager.getBaseLayer('bingAerialWithLabels')
                        },
                        {
                            name: "Bing Aerial",
                            active: activeLayer === 'bingAerial',
                            //tooltip:"Google maps hybrid base layer",
                            icon: '<div class="icon"><span class="glyphicon glyphicon-picture"></span></div>',
                            layer: LayerManager.getBaseLayer('bingAerial')
                        },
                        {
                            name: "Bing Road",
                            active: activeLayer === 'bingRoad',
                            //tooltip:"Google maps hybrid base layer",
                            icon: '<div class="icon"><span class="glyphicon glyphicon-picture"></span></div>',
                            layer: LayerManager.getBaseLayer('bingRoad')
                        },
                        {
                            name: "Bing Canvas Light",
                            active: activeLayer === 'bingCanvasLight',
                            //tooltip:"Google maps hybrid base layer",
                            icon: '<div class="icon"><span class="glyphicon glyphicon-picture"></span></div>',
                            layer: LayerManager.getBaseLayer('bingCanvasLight')
                        },
                        {
                            name: "Bing Canvas Gray",
                            active: activeLayer === 'bingCanvasGray',
                            //tooltip:"Google maps hybrid base layer",
                            icon: '<div class="icon"><span class="glyphicon glyphicon-picture"></span></div>',
                            layer: LayerManager.getBaseLayer('bingCanvasGray')
                        },
                        {
                            name: "Bing Canvas Dark",
                            active: activeLayer === 'bingCanvasDark',
                            //tooltip:"Google maps hybrid base layer",
                            icon: '<div class="icon"><span class="glyphicon glyphicon-picture"></span></div>',
                            layer: LayerManager.getBaseLayer('bingCanvasDark')
                        }
                    ]
                }

            ]
        };


        let over1 = L.control.panelLayers(overLayers1.layers, null, {
            title: overLayers1.title,
            position: 'topleft',
            compact: true,//panle will take up only space needed instead of page height
            compactOffset: 150, //map.getSize.y/2
            collapsibleGroups: true,//alows group to be colapsed by user
            collapsiblePanel: false,//allow the entire panel to be colapsed.
            collapsed: false //panel will colaps on mouse off
        }).addTo(map); //creates the layer tree object on page load. show/hide on easy button

        over1.hide(); //hidden on page load. if removed, the layer tree is destroyed and remove layer will not work as there is
        // nothing to reference. we want the layer tree to exist all the time

        return over1;
    }

    // static baseLayers = {}
    /**
     * Gets all avalible Base Layers
     * @return {string[]}
     */
    static getAvailableBaseLayers() {
        return [
            'openStreetMap',
            'openTopoMap',
            'esriWorldImagery',
            'esriWorldTopoMap',
            'googleSatellite',
            'googleHybrid',
            'usgsImageryTopo',
            'bingAerialWithLabels',
            'bingAerial',
            'bingCanvasGray',
            'bingCanvasDark',
            'bingCanvasLight',
            'bingRoad',
        ];
    }

    /**
     * Get leaflet base layer by its name
     * @param name the base layer name should be from getAvailableBaseLayers()
     * @return {L.Layer} the base layer
     */
    static getBaseLayer(name) {

        if (LayerManager.baseLayers[name]) {
            return LayerManager.baseLayers[name]
        }
        let getIt = (name) => {
            switch (name) {
                case 'openStreetMap':
                    return L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        maxZoom: maxZoomVal,
                        maxNativeZoom: 19,
                        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    }).addTo(map);
                case 'openTopoMap':
                    return L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
                        attribution: '<p>Basemap: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a></p>',
                        maxZoom: maxZoomVal,
                        maxNativeZoom: 17
                    });
                case 'esriWorldImagery':
                    return L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
                        attribution: '<p>Basemap: &copy; ESRI</p>',
                        maxZoom: maxZoomVal,
                        maxNativeZoom: 17
                    });
                case 'esriWorldTopoMap':
                    return L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
                        attribution: '<p>Basemap: &copy; ESRI</p>',
                        maxZoom: maxZoomVal,
                        maxNativeZoom: 17
                    });
                // Google Backgrounds: valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'

                case 'usgsImageryTopo':
                    return L.tileLayer('https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}', {
                        maxNativeZoom: 8,

                        maxZoom: maxZoomVal,
                        attribution: 'Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>'
                    });

                case 'bingAerialWithLabels':
                    return L.tileLayer.bing(
                        {
                            maxNativeZoom: 18,
                            maxZoom: maxZoomVal,
                            imagerySet: 'AerialWithLabels',
                            // imagerySet:'CanvasGray',
                            bingMapsKey: 'Ap675NcYu-15enVIQVEzW9ppWlXNT3aZzsR_0oix1-jhsRCVThkwt6Mn_kkYXeY8'
                        }
                    );

                case 'bingAerial':
                    return L.tileLayer.bing(
                        {
                            maxNativeZoom: 18,
                            maxZoom: maxZoomVal,
                            imagerySet: 'Aerial',
                            bingMapsKey: 'Ap675NcYu-15enVIQVEzW9ppWlXNT3aZzsR_0oix1-jhsRCVThkwt6Mn_kkYXeY8'
                        }
                    );

                case 'bingCanvasDark':
                    return L.tileLayer.bing(
                        {
                            // maxNativeZoom: 18,
                            maxZoom: maxZoomVal,
                            imagerySet: 'CanvasDark',
                            bingMapsKey: 'Ap675NcYu-15enVIQVEzW9ppWlXNT3aZzsR_0oix1-jhsRCVThkwt6Mn_kkYXeY8'
                        }
                    );
                case 'bingCanvasGray':
                    return L.tileLayer.bing(
                        {
                            // maxNativeZoom: 18,
                            maxZoom: maxZoomVal,
                            imagerySet: 'CanvasGray',
                            bingMapsKey: 'Ap675NcYu-15enVIQVEzW9ppWlXNT3aZzsR_0oix1-jhsRCVThkwt6Mn_kkYXeY8'
                        }
                    );

                case 'bingCanvasLight':
                    return L.tileLayer.bing(
                        {
                            // maxNativeZoom: 18,
                            maxZoom: maxZoomVal,
                            imagerySet: 'CanvasLight',
                            bingMapsKey: 'Ap675NcYu-15enVIQVEzW9ppWlXNT3aZzsR_0oix1-jhsRCVThkwt6Mn_kkYXeY8'
                        }
                    );

                case 'bingRoad':
                    return L.tileLayer.bing(
                        {
                            // maxNativeZoom: 1,
                            maxZoom: maxZoomVal,
                            imagerySet: 'Road',
                            bingMapsKey: 'Ap675NcYu-15enVIQVEzW9ppWlXNT3aZzsR_0oix1-jhsRCVThkwt6Mn_kkYXeY8'
                        }
                    );


                case 'googleSatellite':
                    return L.gridLayer.googleMutant({
                        type: 'satellite',
                        maxZoom: maxZoomVal,
                        maxNativeZoom: 17
                    });
                case 'googleHybrid':
                    return L.gridLayer.googleMutant({
                        type: 'hybrid',
                        maxZoom: maxZoomVal,
                        maxNativeZoom: 17
                    });


            }
        }

        LayerManager.baseLayers[name] = getIt(name);
        return LayerManager.baseLayers[name];
    }

    /**
     * Create a new layer manager instance
     * @param map the map the layers will be added to.
     * @param layerConfig the layer configuration to initalize
     * @param panel the L.control.panel to add the layers to
     */
    constructor(map, layerConfig, panel, gun) {
        loadingMask.waitOnMe('layer manager');
        layerConfig.activeBaseLayer = site_config.activeBaseLayer;
        //INIT LEAFLET PANAL LAYERS
        console.log("LAYER MANAGER: baselayer", layerConfig.activeBaseLayer);
        if (!panel) {
            console.log("[Layer Manager] No leaflet layer panel passed, constructing one!")
            if (!LayerManager.getAvailableBaseLayers().includes(layerConfig.activeBaseLayer)) {
                console.warn('ERROR: No Base Layer with name ' + layerConfig.activeBaseLayer
                    + ' setting Active base Layer to openStreetMap');
                layerConfig.activeBaseLayer = 'openStreetMap'

            } else if (!layerConfig.activeBaseLayer) {
                layerConfig.activeBaseLayer = 'openStreetMap'
            }

            panel = LayerManager.getDefaultBaseLayersPanel(layerConfig.activeBaseLayer, map);
        }

        this.currentBaseLayer = LayerManager.getBaseLayer(layerConfig.activeBaseLayer)

        this.sourceManager = new OWSSourceManager(layerConfig.sources, gun);
        this.map = map;
        this.layerConfig = layerConfig;
        this.panel = panel;
        this._layers = []

        this.map.on('baselayerchange', (e) => {
            this.currentBaseLayer = e.layer;
            console.log("Base Layer Changed: ", e.layer);
        });
        // this.map.on('baselayerchange', (e) => {
        //     this.currentBaseLayer = e.layer;
        //     console.log(e.layer);
        // });
//add layers from config 1 by one        console.log(`Done Refreshing layer in ${Date.now() - time}ms`);
        let time = Date.now();
        //todo only wait for abstract do the rest first maybe.

        let presets

        Promise.all(this.sourceManager._capibilitiePromisies).then(vals => {
            console.log(`Waiting For All Capabilities Delayed us By  ${Date.now() - time}ms`);
        });

        Promise.all(this.sourceManager._fastCapibilitiePromisies).then(vals => {

            console.log(`Waiting For Fast All Capabilities Delayed us By  ${Date.now() - time}ms`);


            this.layerConfig.layers.forEach(config => {
                if (this.sourceManager.getSource(config.source).sourceConfig.options.disablePanel !== true) {
                    let wmsLayer = this.sourceManager.getWMSLayer(config.source, config.workspace, config.layer);
                    // console.log("WMSLAYER: ", wmsLayer);
                    if (!wmsLayer) {
                        bs.resNotify({
                            success: false,
                            msg: "Layer Manager: Layer not found in geoserver something is not configured correct for "
                                + config.workspace + ':' + config.layer + '! (Hiding from layer tree)'
                        })
                        console.error("no wmslayer for:", config);
                        return;
                    }

                    if (config) {
                        this.addLayer(config);
                    }
                }
            });

            this._initGeoImg()

            this.layerGrid = new LayerGrid(this, {});

            //todo make it offical
            //This code adds a button to the top of the layer tree that opens the gen two version of the layer tree.
            // let t = document.querySelector('.leaflet-panel-layers-title');
            // if (t) {
            //
            //     t.innerHTML += '<a href="#">(click for beta)</a>'
            //     t.addEventListener('click', (e) => {
            //         layerManager.layerGrid.show()
            //     })
            //
            // }

            loadingMask.done();
            console.log('Done Loading Layer Manager')
            // topbar.hide();
            $(ctlLayerTreeButton.button).toggleClass('disabled', false);
        })


        // this.layerGrid.initPanel();

        this.initState()


    }

    initState() {

        this.saveToJSON = () => {

            let items = layerManager.layerGrid.dataView.getAllSelectedItems()
            let layers = this._layers.filter(l => map.hasLayer(l.mapLayer)).map(l => {
                let layer = {}//delete l.mapLayer but in a deep copy
                Object.keys(l).forEach(k => {
                    if (k === 'mapLayer') return;
                    layer[k] = l[k];
                })
                return layer;
            });

            // let layers = items.filter(item => {
            //     return typeof item != "undefined"
            // }).map(item => {
            //
            //     let layer = {}
            //     Object.keys(item).forEach(k => {
            //         if (k === 'mapLayer') return;
            //         layer[k] = item[k];
            //     })
            //
            //     return layer;
            // });
            //

            let data = {
                layers: layers
            }
            return data;
        }

        /**
         * this = pm_state Module
         * @param data data.layers = array of layers
         */
        this.loadFromJSON = (data) => {
            if (data.clearLayers) {
                layerManager._layers.forEach(l => {
                    let id = L.stamp(l.mapLayer)

                    // let panelLayer = this.getPanelLayer(l);
                    let $container = $(over1.getContainer());

                    let $input = $container.find('#' + id)
                    let input = $input[0];

                    if (input.checked) {
                        input.click();
                    }
                })
            }
            data.layers.forEach(layer => {
                try {

                    let l = layerManager.layerGrid.dataView.getItems().find(row =>
                        row.layer == layer.layer && row.workspace == layer.workspace
                    );

                    console.log("Restoring Layer: ", l);

                    let id = L.stamp(l.mapLayer)

                    // let panelLayer = this.getPanelLayer(l);
                    let $container = $(over1.getContainer());

                    let $input = $container.find('#' + id)
                    let input = $input[0];
                    if (!input.checked) {//only turn stuff on
                        input.click();
                    }


                    // layerManager.layerGrid.checkboxSelector.selectRows([id]);
                } catch (e) {
                    console.warn("Failed to restore layer: ", layer.workspace, ":", layer.layer);
                    console.error(e);
                }


            });


        }

        pm_state.addModule({
            name: 'layerManager',
            serialize: this.saveToJSON,
            deserialize: this.loadFromJSON,
            layerManager: this
        });
    }


    /**
     * Get the Leaflet Panel Layers Layer object from a layer.
     * @param layer - object found in layerManager._layers
     * @return {Object} from over1._getLayer
     */
    getPanelLayer(layer) {
        let stamp = l.stamp(layer.mapLayer);
        return over1._getLayer(stamp);
    }

    /**
     * @return {L.Map}
     */
    getMap() {
        return this.map;
    }

    /**
     * @return {*}
     */
    getPanel() {
        return this.panel;
    }

    getGrid() {
        return this.grid;
    }


    /**
     * This get a list of layers that are in raster tool sources. just retuns the layers as iss for raster tool to set them up as needed.
     * @return {Array}
     */
    getRasterToolLayers() {
        let sources = this.sourceManager.getRasterToolSources();

        let sourceNames = sources.map(s => {
            return s.sourceConfig.name
        })

        let layers = []
        this.layerConfig.layers.forEach(layer => {
            if (sourceNames.includes(layer.source)) {
                layers.push(layer);
            }
        })

        return layers;
    }

    /**
     * Get the WMSSource 's that have the rasterTool option enabled;
     * @return {OWSSource[]}
     */
    getRasterToolSources() {
        return this.sourceManager.getRasterToolSources();
    }

    /**
     * Retursn true if this layer manager has at least one layer in a raster tool source.
     * @return {boolean} true if there are raster tool layers
     */
    hasRasterToolLayers() {
        let layers = this.getRasterToolLayers();
        return layers.length > 0;
    }


    /**
     * @return {*}
     */
    getLayerConfig() {
        return this.layerConfig;
    }

    /**
     * Gets the currently active base layer
     * @return {L.Layer|*}
     */
    getCurrentBaseLayer() {
        return this.currentBaseLayer;
    }

    /**
     * Add A layer by config
     * @param config the configuration object
     * @param {boolean} config.enabled Should the layer be in this layerManager if false no layer will be added
     * @param {string} config.source - The WMS source that the layer belongs too
     * @param {string} config.workspace - The WMS workspace that the layer belongs too
     * @param {string} config.layer - The WMS layer name
     * @param {string} config.niceName - A nice name for the layer to display to user
     * @param {string} config.group - The Layer Tree Group this layer belongs too
     * @param {boolean} config.on Should the layer be turned on (added to map) by default. @deprecated use visible;
     * @param {boolean} config.visible Should the layer be turned on startup (added to map) by default.
     * @param {boolean} config.collapsed  it makes the group collapsed by default. ALL layers have to be set to true for the group to be collapsed.
     * @param {string} config.tooltip - A tooltip for this layer NOTE: ignored if geoserverAbstract=true
     * @param {string} config.geoserverAbstract - If ture the geoserver abstract will be used and the tooltip will be ignored
     * @param {int} [config.opacity=100] - the opacity #todo
     * @param {int} [config.zindex=0] - The Z index that the layers should be shown in. a z index of -1 would be lower then 0 and 1 would be higher #todo
     *       If two layers share the same zindex there is no guarantee about the order but it should fallow the order they were added to the map.
     * @param {boolean} [config.geo_img=false] - This indicate that the layer is intedned for the geo_img module and shouldbe avalible as geojson and have file properties
     * @param {boolean} [config.pointcloudLayer=false] - wether this layer should be used as a pointcloudLayer.js
     * @param {boolean} [config.rasterLayer=false] - this is not implemented yet but indicates that a layer is ment for rasterTool
     *
     * @return {boolean} true if successfully added layer
     *
     * @example
     * layerManager.addLayer({
     *   "source": "lorrnel",
     *   "workspace": "base_data",
     *   "layer": "ATS",
     *   "niceName": "ATS Grid",
     *   "group": "ATS Grid",
     *   "enabled": true,
     *   "on": false,
     *   "collapsed": true,
     *   "tooltip": null
     * });
     */
    addLayer(config) {

        let source = this.sourceManager.getSource(config.source);
        // console.log(source);
        if (config.pointcloudLayer == true) {

            this.hasPointcloudLayer = true;

            source.wfs.getGeoJson(config.workspace + ":" + config.layer).then(geojson => {

                if (typeof this.pointcloudLayer == "undefined") {
                    this.pointcloudLayer = L.featureGroup();
                }


                let color = bs.getRandomColor()
                let layer = L.geoJson(geojson, {
                    // http://leafletjs.com/reference.html#geojson-style
                    //this is applied to points
                    pointToLayer: function (feature, latlng) {
                        //Initial style: radius: size of circle, weight: thickness of outline, color: color of outline (black so easy to see), fillColor: color inside
                        return L.circleMarker(latlng, {
                            radius: 12,
                            color: "#b3b300",
                            weight: 5,
                            opacity: 1,
                            fillColor: "#ffff99",
                            fillOpacity: 0.4,
                            interactive: false,
                            clickable: true //just guessing here

                        });
                    },
                    //this is applied to polylines and polygons

                    style: function (feature) {
                        return {
                            color: "#b3b300",
                            weight: 5
                        };
                    }
                });


                console.log(layer);

                layer.getLayers().forEach(l => {
                    let links = JSON.parse(l.feature.properties.links);

                    // links = [
                    //     {
                    //         "name": "google",
                    //         "url": "http://google.com"
                    //     },
                    //     {
                    //         "name": "syon",
                    //         "url": "http://syon.ca"
                    //     },
                    //
                    // ]

                    if (typeof links !== typeof []) {
                        console.error("Links No Array");
                        return;
                    }

                    let html = ``;
                    // console.log(links);
                    links.forEach(link => {
                        html += ` 
                        <div  class="p-0 m-0" style="border: 1px inset rgba(0,0,0,0.15);">
                        <button name='${link.url}' class="pcButton btn btn-sm btn-success p-1 mr-2 m-0" style="">View</button>  
                        <span name='${link.url}' class="p-0 m-0 mr-1">${link.name}</span>
                        </div>
                        `
                    });
                    // https://stackoverflow.com/a/13699060

                    let container = $(document.createElement('div'));

                    container.html(html);
                    //methid 1 for events
                    container.on('click', '.pcButton', (e) => {
                        console.log(e, links);
                    });
                    /*        for (let i = 0; i < 5; i++) {
                                html += l.feature.properties.label + " (" + i + `)<button name='${url}' class="btn btn-sm btn-success  pl-1 pr-1 ml-2 p-0 m-0">View</button><br>`
                            }*/
                    // let tooltip = l.bindTooltip(l.feature.properties.label + `<button class="btn btn-sm btn-success  pl-1 pr-1 ml-2 p-0 m-0">View</button>`

                    let tooltip = l.bindTooltip(container[0]
                        , {
                            direction: 'auto',
                            // @option permanent: Boolean = false
                            // Whether to open the tooltip permanently or only on mouseover.
                            permanent: true,
                            interactive: true,
                        }
                    )
                    tooltip.openTooltip();
                    tooltip.on('click', (e) => {

                        console.log("Clicked", e);

                        try {
                            let url = e.originalEvent.target.attributes.name.value
                            console.log(url);
                            // if (bs.validateUrl(url)) {
                            if (url) {
                                //open link in new tab
                                window.open(url, '_blank');
                            } else {
                                bs.resNotify({
                                    success: false,
                                    msg: "No 3d View Currently Available",
                                    debug: "url invalid"
                                });
                            }
                        } catch (e) {
                            console.error(e);
                            bs.resNotify({
                                success: false,
                                msg: "No 3d View Currently Available",
                                debug: e
                            })
                        }
                    });
                });

                this.pointcloudLayer.addLayer(layer);
                if (ctlPointcloudLayer) {
                    $(ctlPointcloudLayer.button).toggleClass('disabled', false);
                }
            });
        }

        if (config.downloadFileLayer == true) {

            this.hasDownloadFileLayer = true;

            source.wfs.getGeoJson(config.workspace + ":" + config.layer).then(geojson => {

                this.downloadFileLayer = new DownloadFileLayer(geojson, this.map, this);

                $(ctlDownloadFileLayer.button).toggleClass('disabled', false);


            });

        }

        if (config.geo_img == true) {

            // console.log("has GeoImg");
            let geoImgPromise = source.wfs.getGeoJson(config.workspace + ":" + config.layer)
                .then(geojson => {
                    return {geojson, config}
                }); // map the data in an ordered aray as apering in the layer config for correct adition to the layer tree ehrn we have data!
            if (!Array.isArray(this._geoImgGeojsonPromises)) this._geoImgGeojsonPromises = [];// ensure array exists
            this._geoImgGeojsonPromises.push(geoImgPromise)

            //later we will add all geoimg layers in order
        }

        if (!config.enabled) {
            return false;
        }

        if (typeof config.on != "undefined")
            config.visible = config.on;

        if (typeof config.opacity == "undefined")
            config.opacity = 100;

        if (typeof config.zindex == "undefined")
            config.zindex = 0;


        let layer = this.sourceManager.getLayer(config.source, config.workspace, config.layer);


        config.mapLayer = layer; //todo add mapLayer suport for other

        config._id = LayerManager._nextID++;
        config.id = config._id;
        this._layers.push(config);

        if (config.visible) {
            layer.addTo(this.map);
            // this.panel.addOverlay({layer: layer}, config.niceName, config.group);
        }

        if (config.geoserverAbstract) {
            let wmsLayer = this.sourceManager.getWMSLayer(config.source, config.workspace, config.layer);
            if (wmsLayer) {
                console.log("Geoserver abstract: ", config.niceName, "-", wmsLayer.abstract)
                config.tooltip = wmsLayer.abstract;
            } else {
                console.warn("LayerManager: geoserverAbstract flag but could not get wmsLayer for ", config.source, config.workspace, config.layer)
            }

        }
        if (config.geoserverTitle) {

            let wmsLayer = this.sourceManager.getWMSLayer(config.source, config.workspace, config.layer);
            if (wmsLayer) {
                config.niceName = wmsLayer.title;
            } else {
                console.warn("LayerManager: geoserverTitle flag but could not get wmsLayer for ", config.source, config.workspace, config.layer)
            }
        }

        this.panel.addOverlay({
            layer: layer,
            tooltip: config.tooltip
        }, config.niceName, config.group, config.collapsed);
        //over1.items[L.stamp(lyrData)].find('input')[0].click();   //[L.stamp(lyrData)]).find('input')[0].click();


        /*
        TODO - make this work, zoom to the layer if isZoomKey = true
        if (isZoomKey){
                lyrData.addTo(map);
                map.fitBounds(lyrData.getBounds());
        }//zoom map to the current layer if the isZoomKey variable is true
        */


    }

    //this is nessasary so we add the geoimg layer in the order they were defined see https://gitlab.com/vgeo1/plan-webmap/-/issues/336
    _initGeoImg() {

        if (!this._geoImgGeojsonPromises) {
            console.warn("[Layer Manager] Geo Image Initialized but there are no layers. Aborting!")
            return false;
        }

        return Promise.all(this._geoImgGeojsonPromises)
            .then(vals => {
                vals.forEach(obj => {
                    let {geojson, config} = obj;

                    // this adds the geoimg layer to the layer tree as well
                    let gi = new GeoImg(geojson, this.map, this);
                    if (!this._gi) this._gi = [];
                    this._gi.push(gi);//geo img layer

                    layerManager.panel.addOverlay({
                        layer: gi.photo,
                        tooltip: config.tooltip
                    }, config.niceName, config.group, config.collapsed);

                    if (!this.hasGeoImgLayer) {
                        this.hasGeoImgLayer = true;
                        this.geoImgGeojson = geojson;
                        // this.geoImgLayers
                    } else {
                        geojson = bs.concatGeoJSON(this.geoImgGeojson, geojson);
                    }

                    this.geoImg = new GeoImg(geojson, this.map, this);


                    $(ctlGeoImg.button).toggleClass('disabled', false);
                    return this.geoImg;
                })


                // if (ctlPointcloudLayer)
                //     ctlPointcloudLayer.button.disabled = false;

            })
            .catch(e => {
                console.error("[Layer Manager] Unknown error in geoserver wfs promises; ", e)
                return false;
            })

        return


    }


    /**
     * Find a layer using the layerMangerId (layer._id)
     * @param id the id
     */
    findLayerByID(id) {
        return this._layers.find(l => {
            l._id == id
        })
    }

    /**
     * Find a layer based on its wms soruce information Note that if workspace and or source are excluded will search all
     * workspaces and sources and return the first one
     * @param layer - the layer name
     * @param workspace - the workspace
     * @param source - the wms source for5m the source config
     */
    findLayerByWMS(layer, workspace, source) {
        return this._layers.find(l => {
            let ret = true
            if (source && l.source != source) {
                ret = false;
            }
            if (workspace && l.workspace != workspace) {
                ret = false;
            }
            return ret && l.layer == layer
        })
    }

    /**
     * Get all layers managed
     * @param {string}
     * [groupBy] - if set will group the enterys in an object by __ field
     * @return {Array | *}
     */
    getAllLayers(groupBy) {
        let layers = this._layers;

        if (groupBy) {
            return bs.groupBy(layers, groupBy)
        } else {
            return layers
        }
    }

    /**
     * Returns all layers that are on the map user
     * @param {String} groupBy - the filed to group the layers by
     * @return {Array | Object}
     */
    getActiveLayers(groupBy) {

        // let layers = this.panel.getOverlays();
        let layers = this._layers.filter(l => l.mapLayer && this.map.hasLayer(l.mapLayer));
        if (groupBy) {
            return bs.groupBy(layers, groupBy)
        } else {
            return layers
        }

    }


    /**
     * Gets wmsinfo from geoserver this uses the wms.getFeaturInfo
     * @param layers - array of layers from getAllLayers or equivalent
     * @param x - x of map image in pixels
     * @param y - y in map image in pixels
     * @return {Promise<string>} html table with info
     */
    wmsInfo(layers, x, y, options) {

        //iterate over servers and make requests
        let infos = Object.entries(bs.groupBy(layers, 'source')).map(e => {
            let server = e[0];
            let layers = e[1];
            let names = layers.map(l => {
                if (l.workspace) {
                    return l.workspace + ':' + l.layer
                } else {
                    return l.layer;
                }
            }).join(',');


            console.log(server, layers);

            console.log(this.sourceManager.getSource(server));
            let info = this.sourceManager.getSource(server).wms.getFeatureInfo({
                x: x,
                y: y,
                layers: names,
            }, this.map);

            return info;
        })

        return Promise.all(infos).then(values => {

            let ret = '';

            if (values.length == 1) {
                return values[0]
            }
            let first = true;
            for (let i = 0; i < values.length; i++) {
                let info = values[i];

                if (info && first) {//start and body

                    //hyjack header here
                    //todo: if we want to hyjack then injecto our header and just use the body otherwise use
                    // the first header

                    ret += info.split('</body>')[0];
                    first = false;
                } else if (i == values.length - 1) { // body and end
                    ret += info.split('<body>')[1];

                } else { //just the content;
                    ret += info.split('<body>')[1].split('</body>')[0];
                }
            }

            console.log(ret);
            return ret;
        })

    }


    findLayerByLeafletLayer(layer) {
        console.error("NOT IMPLEMENTED")
    }

    findLayerByLeafletID(leafletID) {
        console.error("NOT IMPLEMENTED")
    }

}

LayerManager.baseLayers = {} //make stupid firfox understand
//https://stackoverflow.com/questions/56083707/how-to-fix-syntaxerror-fields-are-not-currently-supported-error-in-javascript
LayerManager._nextID = 0;
