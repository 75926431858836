_globalGrids = []
jsPanel.ziBase = 1000;

/**
 * @class
 * This is a grid class it combines dialogs windows from jquery-ui, bootstrap, jspanels,
 * slick grid etc In practice it is used with core_bundle.js and a bunch of random css. look at views/mangment/geoserver/geojson.ejs
 * for example of base project
 *
 * features:
 * expose most of slick grid functional it and provide helpers and defults
 * that work
 * and Grids option which can be passed into the constructor {@link https://github.com/6pac/SlickGrid/wiki/Grid-Options}
 *
 * as well as column options {@link https://github.com/6pac/SlickGrid/wiki/Column-Options}
 *
 * Also see Slick Grid events which can be accessed via myGrid.grid.event {@link https://github.com/6pac/SlickGrid/wiki/Grid-Events}
 *
 * And data view Events which can be accessed via myGrid.dataview.event {@link https://github.com/6pac/SlickGrid/wiki/Dataview-Events}
 *
 * action buttons - these let you add actions to rows and assign custom functions
 * toolbar - similer to action buttons this lets you add stuff to the top toolbar
 * filters - a filter by function that lets you filter columsn by cellValue
 * customFilters - are also provided so you can arbitrary setup your worn filter see addRowSearch for example
 *
 */
class Grid {

    /**
     * looks up a grid refrence by an event for jspanel events
     * @param event
     * @returns {*}
     * @private
     */
    static _event2grid(event) {

        let data = event.panel.options.data; // set from grid constructor to reference the panel
        if (!data || typeof data.globalGridIndex == "undefined") {
            console.warn("GRID: No Panel Options set, No Grid found for Panel: ", event.panel.id);
            return false
        }
        let grid = _globalGrids[event.panel.options.data.globalGridIndex];
        // let grid = _globalGrids.find(g => g.panel && g.panel.id == event.panel.id);//todo make this not search all grids (get grid id from event)
        if (!grid) {
            console.warn("GRID: No Grid found for Panel: ", event.panel.id);
            return false
        }
        return grid;
    }

    static _jspanelLoaded(event) {
        console.log(event)
        let panel = event.panel;
        let grid = Grid._event2grid(event);
        if (grid) {
            grid._jspanelLoaded.call(grid, event)
        }
    };

    static _jspanelClosed(event) {
        console.log(event)
        let grid = Grid._event2grid(event)
        if (grid) {
            grid._jspanelClosed.call(grid, event)
        }
    };

    static _jspanelMinimized(event) {
        console.log(event)
        let grid = Grid._event2grid(event)
        if (grid) {
            grid._jspanelMinimized.call(grid, event)
        }
    };

    static _jspanelNormalized(event) {
        console.log(event)
        let grid = Grid._event2grid(event)
        if (grid) {
            grid._jspanelNormalized.call(grid, event)
        }
    };

    static _jspanelMaximized(event) {
        console.log(event)
        let grid = Grid._event2grid(event)
        if (grid) {
            grid._jspanelMaximized.call(grid, event)
        }
    };

    /** https://jspanel.de/#events/jspanelresizestop
     * @param event
     * @param event.panel returns the panel triggering the event
     * @param event.detail returns the ID attribute value of the panel triggering the event should be the grid._id
     * @private
     */
    static _jspanelResize(event) {
        // console.log(event);
        let grid = Grid._event2grid(event);
        if (grid) {
            grid.resize()
        }

    }

    //event are used to make this code more sppgetie and let other plugins hook in XD

    /**
     * EVENTS:
     * oninit - (e) called when all init code has ran
     * onshow - (e) called when the table is opened
     * onhide - (e) called when the table is closed or minimized
     * onAddItem - (e, item) called immediately after item is added to the dataView
     * onDelItem - (e, id) called immediately before item is deleted to the table
     * onFilter - (e) called when the filter is updated
     */
    _initEvents() {
        //todo maybe refactor events to camel case
        this.oninit = new Slick.Event();
        this.onInit = this.oninit;
        if (typeof this.options.oninit == 'function') {
            this.oninit.subscribe(this.options.oninit);
        }
        this.onInitPanel = new Slick.Event();
        this.onshow = new Slick.Event();
        this.onShow = this.onshow;
        if (typeof this.options.onshow == 'function') {
            this.onshow.subscribe(this.options.onshow);
        }
        this.onhide = new Slick.Event();
        this.onHide = this.onhide;
        if (typeof this.options.onhide == 'function') {
            this.onhide.subscribe(this.options.onhide);
        }
        this.onAddItem = new Slick.Event();
        if (typeof this.options.onAddItem == 'function') {
            this.onAddItem.subscribe(this.options.onAddItem);
        }

        this.onDelItem = new Slick.Event();
        if (typeof this.options.onDelItem == 'function') {
            this.onDelItem.subscribe(this.options.onDelItem);
        }

        this.onFilter = new Slick.Event();
        if (typeof this.options.onFilter == 'function') {
            this.onFilter.subscribe(this.options.onFilter);
        }


    }

    /**
     * https://github.com/6pac/SlickGrid/wiki/Grid-Events
     * https://github.com/6pac/SlickGrid/wiki/Dataview-Events
     * Construct a new grid
     *
     * @param {Array} data - of files (essentially rows out of the file database ypu want in the dataView)
     *
     * @param {Array} columns - Slick Grid Columns array  {@link https://github.com/6pac/SlickGrid/wiki/Column-Options}
     *
     * @param {Object} [options] - extra optional option
     * @param {Object} [options.checkboxes=false] - if enabled checkboxes will be added to the rows
     * @param {Element} [options.gridElement] -  the dom element for the grid.
     * @param {String} [options.containerSelector] - the css selector for the grids container
     * @param {String} [options.containerType] - Weather or not Grid should try and make this into a dialog
     * @param {boolean} [options.initPanel] - if true will show the panel when ready;
     * @param {String} [options.exportTypes] - csv, json The export types suported Look into export code for how to extend and add a new type
     * @param {Object} [options.gridOptions] - Slick.Grid Options if you wish to override the defaults {@link https://github.com/6pac/SlickGrid/wiki/Grid-Options}
     * @param {Boolean} [options.dialog=true] - [@deprecated (use options.containerType) ] Whether or not Grid should try and make this into a dialog
     * @param {Object} [options.dialogOptions] - Jquery Dialog Options if you wish to override the defaults;
     * @param {Array<Column>} [options.availableColumns=columns] - an array of available columns if some are hidden by default.
     * @param {function} [options.oninit] - a function to execute on init finish good for if data is fetched in the constructor
     *
     * @param {Boolean} [init=true] - set to false if you wish to initialize after constructing this is useful for extending if you wish to modify configuration
     */
    constructor(data,
                columns,
                options = {},
                init = true) {

        let width = 700;
        let height = 400;

        if (!options) options = {};

        this.resizeOfsets = {
            clientWidth: -15,
            clintHeight: -15,
            uiWidth: -25,
            uiHeight: -60
        }

        let defaults = {
            // width: 700,
            // height: 400,
            containerType: 'jspanel',
            checkboxes: false,
            dialog: false,
            dialogOptions: {
                autoOpen: false,
                draggable: true,
                resizable: true,
                title: 'My Grid',
                minHeight: 180,
                minWidth: 300,
                height: height,
                width: width,
                // position: {my: "left top", at: "right bottom", of: "#asset_toolbar"},
                show: {effect: "clip", duration: 500},
                hide: {effect: "clip", duration: 500},
                open: (e) => {
                    // console.log(ui);
                    // this.dialog.dialog(ui.size);
                    let size = {
                        width: e.target.clientWidth + this.resizeOfsets.clientWidth,
                        height: e.target.clientHeight + this.resizeOfsets.clintHeight,
                    }

                    this.resize(size);
                },
                resize: (event, ui) => {
                    console.log(ui);
                    this.dialog.dialog(ui.size);
                    let size = {
                        width: ui.size.width + this.resizeOfsets.uiWidth,
                        height: ui.size.height + this.resizeOfsets.uiHeight,
                    }

                    this.resize(size);
                }

            },
            exportTypes: ['csv', 'json'],//todo add pdf
            jspanelOptions: {
                headerTitle: 'My Grid',
                theme: bs.getCssVar("--panel-theme", true),
                borderRadius: '0.5rem',
                panelSize: {
                    width: width,
                    height: height,
                },
                aspectRatio: "panel",
                data: {},
                dragit: {
                    containment: 0,
                    snap: {
                        sensitivity: 70,
                        trigger: 'panel',
                        active: 'both',
                    },
                },
                onclosed: () => {
                    this.panel = undefined;
                }


            },
            gridOptions: {
                editable: false,
                enableAddRow: false,
                // explicitInitialization: true,
                autoHeight: false,//true effects performance
                // enableAutoSizeColumns: true,  // you can use the grid "autosizeColumns()" method after the resize, or use forceFitColumns
                // // autoWidth: true
                multiColumnSort: true,
                // forceFitColumns: false,
                enableAutoSizeColumns: true,// you can use the grid "autosizeColumns()" method after the resize, or use forceFitColumns
                autosizeColsMode: Slick.GridAutosizeColsMode.FitColsToViewport,
                enableTextSelectionOnCells: true,
                // autosizeColsMode: Slick.GridAutosizeColsMode.ContentIntelligent,
                enableCellNavigation: true,
                // sanitizer: bs.sanitizeHtmlString,
                sanitizer: function (dirtyHtml) {
                    // return dirtyHtml; // return the same input string without sanitizing it

                    // or try a different sanitizer that will let `onX` events get through but block any other JavaScript code
                    // something like this maybe:
                    return dirtyHtml.replace(/javascript:([^>]*)[^>]*|(<\s*)(\/*)script([<>]*).*(<\s*)(\/*)script(>*)|(&lt;)(\/*)(script|script defer)(.*)(&gt;|&gt;">)/gi, '');
                },

                // forceFitColumns: true
            }
        }

        this.exportTypes = ['csv', 'json'];//todo make config work
        this._exportTypes = {
            csv: this.exportCSV,
            json: this.exportJson
        };

        console.log("defualts: ", defaults);

        console.log("options: ", options)

        this.options = bs.mergeDeep(defaults, options);

        console.log("this.options: ", this.options)


        //todo convert to uuid
        this._id = _globalGrids.length;
        _globalGrids.push(this);
        this.options.jspanelOptions.data.globalGridIndex = this._id;

        // this.options.jspanelOptions.id = '' + this._id;

        this._initEvents();

        this.containerSelector = options.containerSelector;
        this.gridElement = options.gridElement;

        // <div id='fileGridDialog2'>
        //     <div id="myGrid2" style="/*width:700px;height:700px;*/ z-index: 1000"></div>
        // </div>
        if (typeof this.containerSelector == "undefined" &&
            typeof this.gridElement == "undefined") {
            let dialogDiv = document.createElement('div');
            dialogDiv.id = "GridDialogDiv_" + this._id;
            this.containerSelector = '#' + dialogDiv.id
            this.gridElement = document.createElement('div');
            this.gridElement.style.width = (width - 10) + 'px';
            if (options.height) {

                this.gridElement.style.height = options.height + 'px';
            }

            dialogDiv.appendChild(this.gridElement);
            document.body.appendChild(dialogDiv);
            this.dialogDiv = dialogDiv;
        } else {
            this.dialogDiv = document.querySelector(this.containerSelector);
        }


        this.options.jspanelOptions.content = this.gridElement;

        if (this.options.toolbar) {
            // this.gridElement.parentElement.appendChild(this.options.toolbar);
        }


        this.initFormatters();
        // this.hijackFileFunctions();

        this.data = data;

        if (!columns) {
            console.warn("Grid: no columns provided attempting to generate off of data");
            if (data && data[0]) {
                columns = Object.keys(data[0]).map(k => {
                    let length = bs.testTextLength(data[0][k]);

                    return { //column
                        id: k,
                        name: bs.niceName(k),
                        field: k,
                        sortable: true,
                        width: length + 8,//just a bit of padding
                    }

                })
            }
        }
        this.columns = columns;


        if (this.data && this.data[0] && !this.data[0].id) {
            for (let i = 0; i < this.data.length; i++) {
                this.data[i].id = i;
            }
        }


        //
        // this.sortcol = columns[0].name;
        // this.sortdir = 1;


        // var columnpicker = new Slick.Controls.ColumnPicker(columns, grid, options);


        if (this.options.checkboxes) {

            var checkboxSelector = new Slick.CheckboxSelectColumn({
                // cssClass: "slick-cell-checkboxsel",
                // selectableOverride: this._selectableOverride,
                hideInFilterHeaderRow: false,
            });
            this.checkboxSelector = checkboxSelector;
            let check_col = checkboxSelector.getColumnDefinition()
            check_col.maxWidth = 20;

            this.groupItemMetadataProvider = new Slick.Data.GroupItemMetadataProvider({
                checkboxSelect: true,
                checkboxSelectPlugin: checkboxSelector
            });

            //insert the checkbokes at the begining
            this.columns.splice(0, 0, check_col);

        } else {
            this.groupItemMetadataProvider = new Slick.Data.GroupItemMetadataProvider({
                checkboxSelect: false,
                // checkboxSelectPlugin: checkboxSelector
            });
        }

        if (init) {
            this.init();
        }
    }//end constructor


    /**
     * This can be called after super(..., false) in a constructor and is where the essential grid is constructed
     */
    init() {

        // initialize the model

        if (!this.dataViewOptions) this.dataViewOptions = {inlineFilters: true}
        this.dataView = new Slick.Data.DataView(this.dataViewOptions);

        this.grid = new Slick.Grid(this.gridElement, this.dataView, this.columns, this.options.gridOptions);

        this.moveRowsPlugin = new Slick.RowMoveManager({
            cancelEditOnDrag: true
        });

        this.moveRowsPlugin.onBeforeMoveRows.subscribe((e, data) => {
            for (let i = 0; i < data.rows.length; i++) {
                // no point in moving before or after itself
                if (data.rows[i] == data.insertBefore || data.rows[i] == data.insertBefore - 1) {
                    e.stopPropagation();
                    return false;
                }
            }
            return true;
        });

        this.moveRowsPlugin.onMoveRows.subscribe((e, args) => {
            let extractedRows = [], left, right;
            let rows = args.rows;
            let insertBefore = args.insertBefore;
            let data = this.dataView.getItems();

            console.log(rows, insertBefore);
            left = data.slice(0, insertBefore);
            right = data.slice(insertBefore, data.length);


            rows.sort(function (a, b) {
                return a - b;
            });

            for (var i = 0; i < rows.length; i++) {
                extractedRows.push(data[rows[i]]);
            }

            rows.reverse();

            for (var i = 0; i < rows.length; i++) {
                var row = rows[i];
                if (row < insertBefore) {
                    left.splice(row, 1);
                } else {
                    right.splice(row - insertBefore, 1);
                }
            }

            data = left.concat(extractedRows.concat(right));

            this.dataView.setItems(data);

            var selectedRows = [];
            for (var i = 0; i < rows.length; i++)
                selectedRows.push(left.length + i);

            this.grid.resetActiveCell();
            this.grid.invalidateAllRows()
            // this.grid.setData(data);
            // this.grid.setSelectedRows(selectedRows);
            this.grid.render();
        });

        this.grid.registerPlugin(this.moveRowsPlugin);


        // register the group item metadata provider to add expand/collapse group handlers
        this.grid.registerPlugin(this.groupItemMetadataProvider);
        this.grid.setSelectionModel(new Slick.RowSelectionModel({selectActiveRow: false}));
        // grid.registerPlugin(checkboxSelector);

        this.dataView.syncGridSelection(this.grid, true, true);

        if (this.options.checkboxes) {
            this.grid.registerPlugin(this.checkboxSelector);
        }

        this.comparer = (a, b) => {
            let x = a[this.sortcol], y = b[this.sortcol];
            return (x == y ? 0 : (x > y ? 1 : -1));//no ===
        }
        // wire up model events to drive the grid
        this.dataView.onRowCountChanged.subscribe((e, args) => {
            this.grid.updateRowCount();
            this.grid.render();
        });

        this.dataView.onRowsChanged.subscribe((e, args) => {
            this.grid.invalidateRows(args.rows);
            this.grid.render();
        });

        this.grid.onSort.subscribe((e, args) => {
            var cols = args.sortCols;
            console.log("onSort", args);

            this.dataView.sort((dataRow1, dataRow2) => {
                for (let i = 0, l = cols.length; i < l; i++) {
                    let field = cols[i].sortCol.field;
                    var sign = cols[i].sortAsc ? 1 : -1;
                    var value1 = dataRow1[field], value2 = dataRow2[field];
                    var result = (value1 == value2 ? 0 : (value1 > value2 ? 1 : -1)) * sign;
                    if (result != 0) {
                        return result;
                    }
                }
                return 0;
            });
            this.grid.invalidate();
            this.grid.render();
        });

        this.grid.onClick.subscribe((e) => {
            // let cell = this.grid.getCellFromEvent(e);
            // if (this.grid.getColumns()[cell.cell].id == "buttonLink") {
            //     if (!this.grid.getEditorLock().commitCurrentEdit()) {
            //         return;
            //     }
            //     console.log('LINK')
            //
            //     console.log(cell);
            //     let row = this.grid.getData()[cell.row]
            //     console.log(row);
            //
            //     window.location.href =                 bs.routeToURL('/api/file/');
            //     e.stopPropagation();
            // }
            // if (this.grid.getColumns()[cell.cell].id == "buttonView") {
            //     if (!this.grid.getEditorLock().commitCurrentEdit()) {
            //         return;
            //     }
            //     console.log('VIEW');
            //
            //     console.log(cell);
            //     let row = this.grid.getData()[cell.row]
            //     console.log(row);
            //     e.stopPropagation();
            // }

        });


        this.grid.onCellChange.subscribe((e, args) => {
            console.log(args);
            if (typeof this.inlineEdit == "function") {
                args._grid = this;
                this.inlineEdit(e, args);
            }
        });

        //
        // this.dataView.beginUpdate();
        if (typeof this.data.concat == 'function') {
            this.dataView.setItems(this.data);
        }

        if (this.options.containerType === 'dialog') {
            this.dialog = $(this.dialogDiv).dialog(this.options.dialogOptions);
            this.initResizer();
            this.resize();

            // this.dialog.dialog({
            //     resize: (event, ui) => {
            //         this.resize();
            //     }
            // });

            console.log(this.dialog);
        } else if (this.options.containerType === 'jspanel') {

        }

        this.initFilters();

        this.initHighlight();

        if (typeof this.options.initCallback == "function") {
            this.options.initCallback();
        }

        if (this.options.initPanel) {
            console.log("Init panel")
            this.initPanel();
        }

        this.avalibleColumns = this.options.avalibleColumns || this.columns;

        this.isInit = true;
        this.oninit.notify();
    }

    /**
     * Given an array of data items will set the grid and rerender
     * @param data
     */
    setItems(data) {
        this.grid.invalidateAllRows();
        this.dataView.setItems(data, "id");
        this.grid.render();
    }


    /**
     * gridElement.parentElement.offsetWidth/Height
     * @return {{width: number, height: number}}
     */
    getPanelSize() {
        return {
            // width: this.gridElement.parentElement.clientWidth,
            // height: this.gridElement.parentElement.clientHeight,
            width: this.gridElement.parentElement.offsetWidth - 2,//-2 so the scroll bar doesent spaz
            height: this.gridElement.parentElement.offsetHeight - 2,
        }
    }

    /**
     * set size of slick grid called from _jspanelResize, also see slick.resize.js but this resizes the panel to the grids parent if no param is passes
     * @param {Object} [newSize] - the size default = getPanelSize = gridElement.parentElement.ofsetWidth/Height
     * @param {int} [newSize.width] - width in px
     * @param {int} [newSize.height] - height in px
     */
    resize(newSize) {
        if (this.isOpen()) {
            if (newSize) {
                this.resizer.resizeGrid(1, newSize);
            } else {
                this.resizer.resizeGrid(1, this.getPanelSize());
            }
        }
    }

    initResizer(noRegister) {
        if (this.resizer) {
            this.grid.unregisterPlugin(this.resizer);
        }
        // create the Resizer plugin
        // you need to provide a DOM element container for the plugin to calculate available space
        console.warn("ELEMENT:", this.gridElement.parentElement)
        this.resizer = new Slick.Plugins.Resizer({
                container: this.gridElement.parentElement, // DOM element selector, can be an ID or a class name

                // optionally define some padding and dimensions
                rightPadding: 20,    // defaults to 0
                leftPadding: 10,
                bottomPadding: 10,  // defaults to 20
                minHeight: 150,     // defaults to 180
                minWidth: 150, // defaults to 300


                // you can also add some max values (none by default)
                // maxHeight: 1000
                // maxWidth: 2000
            },
            // the 2nd argument is an object and is optional
            // you could pass fixed dimensions, you can pass both height/width or a single dimension (passing both would obviously disable the auto-resize completely)
            // for example if we pass only the height (as shown below), it will use a fixed height but will auto-resize only the width
            // { height: 300 }
        );

        if (noRegister !== true)
            this.grid.registerPlugin(this.resizer);

        this.resizer.pauseResizer(true);


    }

    //## TOOLBAR ##

    /**
     * clears a jspanel toolbar
     */
    clearToolbar() {
        if (this.panel) {
            this.panel.addToolbar('header', '', (panel) => {
                console.log('cleared')
            });
        }
        this._toolbarItems = [];
    }

    /**
     * uses the contents of this._toolbarItems to poulate jspanel toolbar
     * should respect stringsarray in jspanel options
     */
    _getSetupToolbarEventsFunction() {
        return (panel) => {
            this._toolbarItems.forEach(item => {
                let btn = panel.headertoolbar.querySelector(item.selector);
                if (typeof item.cb == 'function') {
                    item.cb(this, item, btn);
                }
                // let configSelect = panel.headertoolbar.querySelector('select[name=configSelect]');
                console.log("jspanel callback", item.selector);
                btn.addEventListener(item.event, (e) => {
                    console.log('item ', item.event, 'ed ', item.selector);
                    item.fn(this, item, e);
                });
            })
        }
    }

    initToolbar() {
        if (!this._toolbarItems) this._toolbarItems = [];

        if (this.panel) {
            let strings = this._toolbarItems.map(i => i.string);
            if (this.options.jspanelOptions.headerToolbar) {
                strings = [...this.options.jspanelOptions.headerToolbar, ...strings];
            }

            // this.panel.addToolbar('header', [item.string], this._setupToolbarEvents);

            this.panel.addToolbar('header', strings, this._getSetupToolbarEventsFunction());

            if (this.options.jspanelOptions.headerToolbar) {
                console.log("calling old callback")
                this.options.jspanelOptions.callback(this.panel);
            }
        } else {
            console.warn("Warning no panel to initialize toolbar of")
        }
    }

    /**
     * recreate the toolbar
     */
    resetToolbar() {
        if (this.panel) {
            this.panel.addToolbar('header', '', (panel) => {
                console.log('cleared')
            });
        }
        this.initToolbar();
    }

    /**
     * add an toolbar item
     * @param {String|Object} item - a full item or the css selector for the item
     * @param {String} string - the html string for the item
     * @param {Function} fn - the fn(grid, item, event) to be executed on click
     * @param {Function} cb - a cb(grid, item, element) to be executed when the item is created
     * @param {Object} options - Optional options
     * @param {Object} [options.event='click'] - select which event the fn gets called by
     */
    addToolbarItem(item, string, fn, cb, options) {
        let selector = item
        if (bs.isObject(item)) {
            string = item.string || string;
            fn = item.fn || fn;
            cb = item.cb;
            options = item.options;
            selector = item.selector;
        }
        if (typeof string == 'undefined') {
            throw Error("item.string not provided in object and string is undefined");
        }
        if (typeof fn == 'undefined') {
            throw Error("item.fn not provided in object and fn is undefined");
        }
        if (typeof item == 'undefined') {
            console.warn("No Item");
        }
        if (typeof options == "undefined") {
            options = {}
        }

        item = {
            selector: selector,
            string: string,
            fn: fn,
            cb: cb,
            event: options.event || 'click'
        }


        if (!this._toolbarItems) {//init
            this._toolbarItems = [];
        }

        this._toolbarItems.push(item);

        if (this.panel) {
            this.panel.addToolbar('header', [item.string], this._getSetupToolbarEventsFunction());
        } else {
            console.warn('Grid: Cant find a panel to add toolbar item too.');
        }

        return item;
    }

    updateToolbarItem(selector, string) {
        let item = this._toolbarItems.find(i => i.selector == selector);

        item.string = string;
        this.resetToolbar();
    }

    /**
     * Deletes a toolbar item by the selector you gave in addToolbarItem
     * @param selector
     */
    delToolbarItem(selector) {
        this._toolbarItems = this._toolbarItems.filter(i => i.selector != selector);
        this.resetToolbar();
    }

    /**
     * Delete a toolbar button by the name attribute you gave in addToolbarButton
     * @param name
     */
    delToolbarButton(name) {
        let selector = `span[name=${name}]`
        this.delToolbarItem(selector);
    }

    /**
     * Add an action to the grid this will show up in the actions column
     * this is a utility extension of addToolbarItem
     * @param {String} name - the name for a selector not really used
     * @param {String} title - the tooltip
     * @param {String} html - innerHtml for button
     * @param {Function} fn - fn(grid, item) called onclick
     */
    addToolbarButton(name, title, html, fn) {

        let item = {
            selector: `span[name=${name}]`,
            string: `<span name="${name}" title="${title}" style="white-space: nowrap;" class="btn btn-sm btn-outline-dark">${html}</span>`,
            fn: fn
        }

        return this.addToolbarItem(item);
        // this._toolbarItems.push(item)
        //
        // if (this.panel) this.resetToolbar();
    }


    /**
     * Add an action to the grid this will show up in the actions column
     * this is a utility extension of addToolbarItem
     * https://www.w3schools.com/tags/tag_select.asp
     *
     * @param {String} name - the name for a selector not really used
     * @param {String} title - the tooltip
     * @param {Array<string>} options -  list of options will be run through bs.niceName for label if string,  also accepts html Element options. or objects with {label, value}
     * @param {Function} fn - fn(grid, item) called onchange
     */
    addToolbarDropdown(name, title, options, fn) {

        let html = '';
        options.forEach(o => {
            let opt;
            //accept array of string HTMLOptionElement or {value, label}
            if (typeof o == 'string') {
                opt = document.createElement('option');
                opt.value = o;
                opt.innerText = bs.niceName(o);
            } else if (o instanceof HTMLOptionElement) {
                opt = o;
            } else {
                if (!o.value || !o.label) {
                    console.warn("No value or label attempting to recover");
                    if (o.value) o.label = bs.niceName(o.value);
                    else {
                        console.error('Failed please provide options object with at least {value}');
                        return;
                    }
                    let opt = document.createElement('option');
                    opt.innerText = o.label
                    if (o.cb) o.cb(opt);
                }
            }

            //should now hav opt
            if (!opt) {
                console.error("IDK why we dont have an opt element", opt);
                return;
            }

            html += opt.outerHTML;

        })

        let item = {
            selector: `select[name=${name}]`,
            string: `<select name="${name}" title="${title}" style="white-space: nowrap;" class="btn btn-sm btn-outline-dark">
                        ${html}
                     </select>`,
            fn: fn,
            cb: (grid, item, element) => {
                console.log('Grid Dropdown Create')
            },
            options: {event: 'change'}
        }

        return this.addToolbarItem(item);
        // this._toolbarItems.push(item)
        //
        // if (this.panel) this.resetToolbar();
    }

    /**
     * Adds a search box that serches the grid
     * great example for capabilities of both Filters and Toolbar items / Buttons
     */
    addRowSearch(useData, hideSettings, debounceDelay = 350) {

        //wait until after initialised
        if (!this.isInit) {
            this.oninit.subscribe(() => {
                this.addRowSearch();
            })
            return;
        }

        let id = 'rowSearch' + bs.id++

        let template = {};
        let keys;
        if (useData) {
            keys = Object.keys(this.data[0]);
        } else {
            keys = this.avalibleColumns.map(c => c.id);
        }
        this._searchArgsValues = {}
        let args = {
            string: '',
            fields: {},
            mode: 'OR'
        }
        keys.forEach(k => {
            template[k] = 'checkbox'
            args.fields[k] = true;
        })

        this.addCustomFilter({
                name: 'rowSearch',
                fn: (item, args) => {

                    if (args && args.string && args.string != ' ') {
                        let search = args.string.toLowerCase();//ignore case
                        let keys = Object.keys(item)

                        function simpleSearch(search) {

                            for (let i = 0; i < keys.length; i++) {
                                let key = keys[i];
                                if (!args.fields || (args.fields && args.fields[key])) {

                                    let val = JSON.stringify(item[key]).toLowerCase();// to lowwer string

                                    if (val.includes(search)) {//if we find the search string
                                        // console.log("found ", search, ' in ', val, ' key: ', key);
                                        return true;
                                    }

                                }
                            }
                            return false
                        }

                        // console.log(args.mode);
                        switch (args.mode) {
                            case "OR": {
                                let ss = search.split(',');
                                for (let j = 0; j < ss.length; j++) {
                                    let s = ss[j].trim();
                                    let found = simpleSearch(s)
                                    // console.log(s, found)
                                    if (found) {//if we find the search string
                                        return true;
                                    }
                                }
                                return false
                                break;
                            }
                            case "AND": {
                                let ss = search.split(',');
                                for (let j = 0; j < ss.length; j++) {
                                    let s = ss[j].trim();

                                    let found = simpleSearch(s)
                                    // console.log(s, found)
                                    if (!found) {//if we cant find something return false
                                        return false;
                                    }
                                }
                                return true;
                                break;
                            }
                            case "OFF":
                            default: {

                                return simpleSearch(search)
                                break;
                            }
                        }


                    }
                    //if we didn't find it

                    return true
                },
                args: args
            }
        )

        this.addToolbarItem(
            "#" + id,
            `<input id="${id}" type="search" class="form-control" title="Allows for searching the table for a csv of values ie 'a, b' => is a OR b in the row?" placeholder="Search Row"/>`,
            bs.debounce((grid, item, event) => {
                console.log("Search Row", item, event);
                let args = this.getCustomFilterArgs('rowSearch');
                args.string = event.target.value;
                grid.setCustomFilterArgs('rowSearch', args)
            }, debounceDelay),
            undefined,
            {event: 'input'}
        )


        this.addToolbarButton(
            "rowSearchMode",
            `Row Search Mode (OR, AND, OFF)`,
            `OR`,
            (grid, item, event) => {
                console.log("Search Settings", item, event);
                let args = this.getCustomFilterArgs('rowSearch');
                switch (args.mode) {
                    case "OR":
                        args.mode = "AND";
                        break;
                    case "AND":
                        args.mode = "OFF";
                        break;
                    case "OFF":
                        args.mode = "OR";
                        break;
                    default:
                        args.mode = "OR";
                        break;
                }

                grid.panel.querySelector(item.selector).innerHTML = args.mode;
                grid.setCustomFilterArgs('rowSearch', args)
                // grid.setCustomFilterArgs('rowSearch', {string: event.target.value, fields: this._searchArgsValues})
            }
        );


        //for colum settings
        if (!hideSettings) {

            this.addToolbarButton(
                "rowSearchSettings" + id,
                `Row Search Settings`,
                `<i class="fa fa-cog" aria-hidden="true" style="font-size: 116%; padding-top: 3px;"></i>`,
                (grid, item, event) => {
                    console.log("Search Settings", item, event);

                    let args = this.getCustomFilterArgs('rowSearch');
                    bs.prompt('Row Search Settings', template, args.fields).then(res => {
                        args.fields = res;
                        grid.setCustomFilterArgs('rowSearch', args)
                        // grid.setCustomFilterArgs('rowSearch', {string: event.target.value, fields: this._searchArgsValues})
                    })
                }
            );
        }

    }

    /**
     *
     * @param availableColumns - Available columns are columns that can be added they must be defined slick columns (same options as columns)
     *   note columns are available in the sense they are ready to use, and therefore must be in the data
     *   setting this will set the available columns as well
     */
    addColumnSettings(availableColumns, opts) {
        //
        // let a = buildHtmlTable([{
        //     a: `<input title="Enter A Value" id="bsid_578" name="expiry_date" type="checkbox" aria-label="Checkbox for following label" checked>`,
        //     b: `<input title="Enter A Value" id="bsid_577867" name="expiry_date" type="checkbox" aria-label="Checkbox for following label">`
        // }, {
        //     a: `<input title="Enter A Value" id="bsid_567867" name="expiry_date" type="checkbox" aria-label="Checkbox for following label" checked>`,
        //     b: `<input title="Enter A Value" id="bsid_57687" name="expiry_date" type="checkbox" aria-label="Checkbox for following label">`
        // }])


        if (availableColumns) {
            this.avalibleColumns = availableColumns;
        } else {
            console.warn("setting available columns to current columns")
            this.avalibleColumns = this.columns;
        }

        // let keys = this.avalibleColumns.map(c => c.name);
        // let template = {};
        // keys.forEach(k => {
        //     template[k] = 'checkbox'
        // });
        //idk which is cleaner

        opts = opts || ['search', 'visible', 'export']

        let optHelp = {
            search: `toggles whether or not the column value is searched when using the search box`,
            visible: `toggles whether the column shows up in the grid.`,
            export: `toggles whether the column shows up in exported data.`,
        }


        let optTitles = opts.map(o => optHelp[o]);

        let template = {
            'select_all': {
                type: 'checklist',
                data: opts,
                title: 'if a select all box is checked when saved all columns will be selected'

            },
            'select_none': {
                type: 'checklist',
                data: opts,
                title: 'if a select none box is checked when saved all columns will be unselected'
            }
        };
        this.avalibleColumns.forEach(c => {
            if (c.id == 'actions') {
                return;
            }
            // template[c.name] = 'checkbox'
            template[c.id] = {
                type: 'checklist',
                data: opts,
                title: "Select Column Options",
                titles: optTitles
            }
        })

        this.addToolbarButton(
            "columnSettings" + this._id,
            `Column Settings`,
            `<i class="fa fa-cog" aria-hidden="true" style="font-size: 116%; padding-top: 3px;"></i>`,
            (grid, item, event) => {

                console.log("Column Settings", item, event);
                // let vals = this.columns.map(c => c.name);
                let vals = {};

                this.columns.forEach(c => {

                    // vals[c.name] = true;
                    if (!vals[c.id]) {
                        vals[c.id] = [];
                    }
                    vals[c.id].push('visible')

                })

                let args = grid.getCustomFilterArgs('rowSearch');
                Object.keys(args.fields).forEach(k => {

                    if (args.fields[k]) {
                        if (!vals[k]) {
                            vals[k] = [];
                        }
                        vals[k].push('search');
                    }

                })

                if (!grid._exportCols) {
                    grid._exportCols = grid.columns.map(c => c.id);
                }
                grid._exportCols.forEach(c => {
                    if (!vals[c]) {
                        vals[c] = [];
                    }
                    vals[c].push('export')
                })


                let helpStr = `<div class="alert alert-info " role="alert" style="font-size: small;">
                        These are the column settings. To select all/none click the desired action and then save. <br>`

                opts.forEach(o => {
                    helpStr += `<b>${o}</b> - ${optHelp[o]} <br>`;
                })
                helpStr += '</div>'


                bs.prompt('Column Settings', template, vals, {
                    prependHTML: helpStr,
                    //     callback: (modal) => { //todo
                    //
                    // }
                }).then(vals => {
                    //this is a interesting use of reduce
                    //to both filter and map in one function
                    //this may not be 1 to 1 if there are invalid values

                    let selectAllVisible = vals.select_all.includes('visible');
                    let selectAllSearch = vals.select_all.includes('search');
                    let selectAllExport = vals.select_all.includes('export');

                    let selectNoneVisible = vals.select_none.includes('visible');
                    let selectNoneSearch = vals.select_none.includes('search');
                    let selectNoneExport = vals.select_none.includes('export');

                    let accu = Object.entries(vals).reduce((acc, e) => {//(accumulator , value)

                        let id = e[0];
                        let opts = e[1]

                        let visible = (opts.includes('visible') || selectAllVisible) && !selectNoneVisible;
                        let search = (opts.includes('search') || selectAllSearch) && !selectNoneSearch;
                        let shouldExport = (opts.includes('export') || selectAllExport) && !selectNoneExport;

                        let col = this.avalibleColumns.find(c => c.id == id);
                        if (!col) {
                            console.warn("Grid: No Columns found with name " + name + ". Skipping it")
                            return acc;
                        }
                        if (visible) {
                            acc.cols.push(col);
                        }

                        if (shouldExport) {
                            acc.export.push(col.id);
                        }

                        acc.args.fields[id] = !!search;

                        return acc;
                    }, {
                        cols: [this.columns.find(c => c.id === 'actions')],
                        args: {
                            fields: {},
                            string: args.string,
                            mode: args.mode,
                        },
                        export: []
                    });//the initialize value


                    grid.columns = accu.cols;
                    grid.grid.setColumns(accu.cols);

                    grid._exportCols = accu.export;

                    grid.setCustomFilterArgs('rowSearch', accu.args)

                })
            }
        );
    }

    setColumns(cols) {

    }

    /**
     * This adds a button to the toolbar that will export the table
     */
    addExport() {

        let id = 'export' + bs.id++

        this.addToolbarButton(
            "Export" + id,
            `Export Table`,
            `<i class="fa fa-download" aria-hidden="true" style="font-size: 115%; padding: 3px;"></i>`,
            (grid, item, event) => {
                let title;
                try {
                    title = grid.options.jspanelOptions.headerTitle;
                } catch (e) {
                    console.warn(e);
                    title = this.options.tableName || 'Table'
                }

                let name = `${title.replaceAll(' ', '_').toLowerCase()}_export_${moment(Date.now()).format('DD-MM-YYYY')}`

                bs.prompt(`Export ${title} Data`, {
                    file_name: 'input',
                    columns_source: {
                        type: 'radiolist',
                        data: ["data", 'available', 'visible', 'settings'],
                        title: `
                        These set how the export will decide which columns to include.
                        data - this will export all columns from the source data
                        available - this will export all columns available to the grid
                        visible - this will export all columns you can see in the grid
                        settings - this will export the commons selected for export in the settings 
                        `
                    },
                    filter_data: {
                        type: 'radiolist',
                        data: ["off", 'on'],
                        title: 'Whether or not to export the filtered data(spatial/ search) or all rows if off'
                    },
                    extension: {
                        type: 'radiolist',
                        data: grid.exportTypes,
                        title: 'What data type would you like?'
                    },

                    /*       modifiers: {
                               type: 'checklist',
                               data: ['filter', 'hide_header', 'email_me', 'open_if_possible' ],
                               title: 'theres are optional other export features'
                           },*/

                }, {
                    file_name: name,
                    columns_source: 'settings',
                    extension: 'csv',
                    filter_data: 'on'

                }).then(data => {
                    //todo: add column selection

                    grid.export(data.extension, {
                        download: true,
                        fileName: data.file_name + '.' + data.extension,
                        columnMode: data.columns_source,
                        filter: data.filter_data == 'on',
                    });
                }).catch(e => {
                    console.error(e)
                })

            }
        );
    }

    /**
     * Adds the default tools to a table
     */
    addGenericTools() {
        this.addHelp()
        this.addRowSearch(false, true);
        this.addColumnSettings();
        this.addExport();
    }

    /**
     * Lets an feature register its help steps
     * @param cb
     * @private
     */
    _addIntroSteps(cb) {

    }

    addHelp() {

        this.addToolbarButton(
            "Help",
            `Table Grid Help Guide (3-5 min)`,
            `<i class="fa fa-question" aria-hidden="true" style="font-size: 115%; padding: 3px;"></i>`,
            (grid, item, event) => {
                grid.buildHelp();
            });
    }

    buildHelp() {


        console.log("Building Grid Intro")
        let scope = this.panel;
        let steps = []
        steps.push({
            title: 'Welcome To The Table Grid Guide',
            intro: 'This guide provides a self guided tour of the generic table grid features. ' +
                'For quick reference, note there are also tooltips accessible by hovering over most things.' +
                '<br/><br/> Clicking anywhere outside this guide will close it.'
        })

        steps.push({
            title: 'The Table Panel',
            intro: 'The entire table and associated tools are contained within a movable resizable panel.',
            element: scope
        })
        steps.push({
            title: 'The Table Panel Controls',
            intro: 'Panels can be collapsed, minimized, maximized and closed.' +
                'Closing a panel will also remove the associated spatial features (points, lines, or areas) from the map.',
            element: scope.querySelector('.jsPanel-controlbar')
        })


        steps.push({
            title: 'The Table Grid Toolbar',
            intro: 'This toolbar contains buttons and other features which perform actions related to the table. ' +
                'As this is a generic guide some module specific features may not be included.',
            element: scope.querySelector('.jsPanel-hdr-toolbar'),
            position: 'bottom',

        })

        let tmp = scope.querySelector('input[type="search"]')
        if (tmp) {
            steps.push({
                title: 'The Search Window',
                intro: 'Typing a value in this window will filter the table for any rows ' +
                    'that contain the value. All searchable columns will be searched simultaneously. See search setting searchable columns.' +
                    'This supports searching the table for comma seperated values (csv). ie \'a, b\' => is a OR b in the row?',
                position: 'bottom',

                element: tmp
            })
        }

        tmp = scope.querySelector('span[name="rowSearchMode"]')
        if (tmp) {

            // Step 1: Introduction to "Row Search Mode" and Options
            steps.push({
                title: 'Understanding Row Search Mode',
                intro: 'When searching for specific text within a list of rows, you can specify the logical operators OR, AND, or OFF  represent by  a comma(,) in a CSV. These options allow you to customize how your search works.',
                element: tmp,
            });

            // Step 2: Explanation of "Row Search Mode" - OR
            steps.push({
                title: 'Option 1: OR',
                intro: 'OR: This mode returns all rows that contain at least one of the comma-separated values. For example, searching for "apple, banana" will find rows with "apple pie" and "banana bread."',
                element: tmp,
            });

            // Step 3: Explanation of "Row Search Mode" - AND
            steps.push({
                title: 'Option 2: AND',
                intro: 'AND: This mode returns rows that contain ALL of the comma-separated values. For instance, searching for "apple, banana" will find rows with "description: apple pies and bananas are in this string"',
                element: tmp,
            });

            // Step 4: Explanation of "Row Search Mode" - OFF
            steps.push({
                title: 'Option 3: OFF',
                intro: 'OFF: This mode searches for the exact text you’ve typed, including any commas. It\'s useful when you need to search for text that includes a comma. For example, searching for "apple, banana" will look for that specific phrase.',
                element: tmp,
            });

            // You can use the "steps" array as needed for your application's tour or guidance.

            // This code block combines all the steps into one, providing a clear and concise explanation of each "Row Search Mode" option and its functionality. You can use this code to guide users through the different options when searching for specific text within rows.

        }

        tmp = scope.querySelector('span[title="Column Settings"]')
        if (tmp) {
            steps.push({
                title: 'Column Settings',
                intro: 'Configure which columns are searched, visible, and exported.',
                element: tmp
            })
        }
        tmp = scope.querySelector('span[title="Export Table"]')
        if (tmp) {
            steps.push({
                    title: 'Export Table',
                    intro: 'Export the data to a variety of file formats. <br/>' +
                        'The export tool has options to customize exactly what is exported by ' +
                        'selecting columns in the column settings, applying a value in the search window, ' +
                        'and/or filtering spatially by changing the visible area through zooming and panning the map.',
                    position: 'bottom',

                    element: tmp
                }
            )
        }
        tmp = scope.querySelector('span[name="syncMap"]')
        if (tmp) {
            steps.push({
                    title: 'Sync to Map (Spatial)',
                    intro: 'Synchronize the table to show only rows within the current map extent. ' +
                        '(note this includes rows covered by any open window which includes the table grid, layer tree, etc. )',
                    position: 'bottom',

                    element: tmp
                }
            )
        }


        tmp = scope.querySelector('span[name="showAll"]')
        if (tmp) {
            steps.push({
                    title: 'Sync to Data',
                    intro: 'Synchronize the table to the master database. ' +
                        'Use this tool to reload the table data without reloading the entire application. This is useful if the application has been open for a long time.' +
                        'All changes made by yourself and other users will be loaded. <br/><br/> PS we are releasing an update which will enable live synchronization very soon.',
                    position: 'bottom',

                    element: tmp
                }
            )
        }
        tmp = scope.querySelector('span[name="autoSync"]')
        if (tmp) {
            steps.push({
                    title: 'Toggle Auto Map Sync',
                    intro: 'Turn automatic map Synchronization ON or OFF. When ON the visible rows will stay in sync with the ' +
                        'map extent while panning and zooming. Check it out!',

                    position: 'bottom',
                    element: tmp
                }
            )
        }

        tmp = scope.querySelector('span[name="onlyMe"]')
        if (tmp) {
            steps.push({
                    title: 'Toggle Custom User Filter',
                    intro: 'Toggle the user filter between ALL USERS and MINE ONLY modes. ' +
                        ' When in MINE ONLY mode the table is filtered to rows where pm_username = your username. Only rows created by you are visible. ' +
                        ' When in ALL USERS mode all available rows are visible. <br/>' +
                        'Hint: to filter the table to a single user who is not you, type their user name in the search window.',
                    position: 'bottom',
                    element: tmp
                }
            )
        }

        tmp = scope.querySelector('div.frozen')
        if (tmp) {
            steps.push({
                    title: 'Actions',
                    intro: 'Perform row specific actions such as delete, edit and upload files.',
                    element: tmp
                }
            )

            tmp = scope.querySelector('div.slick-viewport-left')
            let tmp2 = tmp.querySelector('button[name="delete"]')
            if (tmp2) {
                steps.push({
                        title: 'Actions - Delete',
                        intro: 'The red trash can will DELETE a row. Caution this is irreversible use with care. ' +
                            'User is asked to confirm the action before it is executed with an option to cancel. ',
                        element: tmp
                    }
                )
            }
            tmp2 = tmp.querySelector('button[name="delete"]')
            if (tmp2) {
                steps.push({
                        title: 'Actions - Edit',
                        intro: 'The blue Pencil opens an edit prompt which includes a row summery and a form for all editable fields. ' +
                            'This is nice when editing lots of columns. <br/> ' +
                            'The row summery can be copied if a copy of the row content is needed but you don\'t need a full export.',
                        element: tmp
                    }
                )
            }

            tmp2 = tmp.querySelector('button[name="notification"]')
            if (tmp2) {
                steps.push({
                        title: 'Actions - Notification',
                        intro: 'The orange Bell toggles row based notification preferences. <br/>' +
                            'OR In advanced implementation allows for editing of individual notification preferences. <br/>' +
                            'Tooltip: ' + tmp2.title,
                        element: tmp
                    }
                )
            }

            tmp2 = tmp.querySelector('i.fa-file')
            if (tmp2) {
                steps.push({
                        title: 'Actions - Files',
                        intro: 'The blue File opens the file browser panel from which files ' +
                            'previously associated with the row can be viewed or new files can be added.',
                        element: tmp
                    }
                )
                steps.push({
                        title: 'Actions - Files Other Icons',
                        intro: 'This tool may be implemented in several ways. If an eye icon is beside the file icon use it to directly open the file. <br/>' +
                            'If an upload icon is visible instead of a file icon this means no ' +
                            'files have been uploaded for the row. The file icon will open the file manager.',
                        element: tmp
                    }
                )
            }

        }

        tmp = scope.querySelector('div.slick-header-right')
        if (tmp) {
            steps.push({
                    title: 'Column Headers',
                    intro: 'This shows the column names for the table.  Click on the column name to toggle sorting' +
                        ' Ascending, Descending and Off.  Multiple columns can be sorted simultaneously. ' +
                        'The sort order is set by the order in which column sorting is turned on. Try it out to see it in action.',
                    element: tmp
                }
            )
        }

        tmp = scope.querySelector('div.slick-viewport-right')
        if (tmp) {
            steps.push({
                    title: 'Data',
                    intro: 'The table data area where all cells are displayed. If there are many rows and columns use the scroll bars to navigate.',
                    element: tmp
                }
            )
            steps.push({
                    title: 'Data - Select2 Edit',
                    intro: 'When using the edit form:\n' +
                        'Fields with Drop Downs:\n' +
                        'Fields with an [x] and down arrow are drop-down fields which also accept new values.\n' +
                        'After typing a NEW value you can SELECT it from the drop down OR hit ENTER to keep the value.\n' +
                        'Fields with a down arrow (but no x) are drop-down fields which do NOT accept new values. You must select a value from the list.',

                    element: tmp
                }
            )
            steps.push({
                    title: 'Data - Cell Navigation',
                    intro: 'Navigate through the data by clicking or using the keyboard arrow keys. From an editable cell ' +
                        'ENTER submits and moves Down.<br/> TAB Submits and moves right.<br/> ESC Cancels ',
                    element: tmp
                }
            )

            steps.push({
                title: 'Data - Edit Hints/Tricks',
                intro: 'If stuck in an editable cell ESC then  left or right<br/>' +
                    'After editing Clicking in SAME column SUBMITS.<br/>' +
                    'But clicking in an DIFFERENT column CANCELS the edit.',
                element: tmp
            })

            steps.push({
                title: 'Data - Highlighting',
                intro: 'clicking anywhere on a row will highlight it. The corresponding ' +
                    'spatial feature (point, line or area) will also be highlighted in the same color ' +
                    'and the map will be panned and zoomed in an intelligently way placing the point in the largest uncovered area of the map.<br/>' +
                    'Highlight colors cycle so that you can keep track of multiple points of interest.',
                element: tmp
            })
            steps.push({
                title: 'Data - Map Highlighting',
                intro: 'Clicking on a feature in the map will also highlight the feature AND the corresponding row in the table.' +
                    'The table will automatically scroll so that the ' +
                    'desired row is in view.',
                element: tmp
            })

        }

        steps.push({
            title: 'That\'s All',
            intro: 'We hope this guide has helped you familiarize yourself with Grids if you have feedback or run into ' +
                'issues send us a message from the Module help in the main toolbar.',
            // element: scope
        })

        introJs().setOptions({
            steps: steps,
            autoPosition: true
        }).start();


    }


    /**
     * Export
     * @param type - the fn to call
     * @param options - to be passed to export fn
     * @return {String}
     */
    export(type, options) {
        // types = this.config.exportTypes
        switch (type) {
            case 'csv': {
                return this.exportCSV(options);
                break;
            }
            case 'json': {
                return this.exportJson(options)
                break;
            }
            case 'pdf': {
                console.error("NOT IMPLEMENTED")
                break;
            }
            default: {
                let fn = this._exportTypes[type]
                if (fn) {
                    fn.call(this, options);
                    return;
                }
                console.error("NOT IMPLEMENTED Or PLANNED YET")
                break;
            }

        }

    }

    /**
     * Used to register a new export type
     * @param type - the extention for the file
     * @param fn(options) - called with the export options and contact file export options:
     *      download: true,
     *      fileName: data.file_name + '.' + data.extension,
     *      columnMode: data.columns_source,
     *      filter: data.filter_data == 'on',
     */
    registerExport(type, fn) {
        if (!this._exportTypes) this._exportTypes = {}
        this._exportTypes[type] = fn;
        this.exportTypes.push(type);

    }


    /**
     * returns csv as a string
     * @param options
     * @param {Boolean} [options.download=false] - if true will trigger a download of the file
     * @param {String} [options.fileName='${view}_export_${date}.csv'] - if true will trigger a download of the file if you want more control use bs.downloadString
     * @param {Boolean} [options.filter=true] - whether to use all data or the dataview filtered data
     * @param {Boolean} [options.header=true] - csv option if false the header row of the csv will be removed
     * @return {String}
     */
    exportCSV(options) {

        let defaults = {
            download: false,
            fileName: `table_export_${moment(Date.now()).format('DD-MM-YYYY')}.csv`,
            header: true,
            filter: true,
        }
        options = bs.mergeDeep(defaults, options);
        // let data
        // if (options.filtered) {
        //     data = this.dataView.getFilteredItems()
        // } else {
        //     data = this.data
        // }

        let data = this.getExportData(options.columnMode, options.filter);

        let csv = bs.convertToCSV(data, options.header);

        if (options.download) {
            bs.downloadString(csv, 'text/csv', options.fileName);
        }

        return csv;

    }

    /**
     * A function to get the data based on a the export mode and filter options
     * @param colMode - 'data' returns all data keys
     *                  'available' - all available add columns
     *                  'visible' - all visible add columns
     *                  'settings' - the columns set by the settings in this._exportCols
     * @param filter - whether to give all rows or the result of dataView.getFilteredItems.
     * @return {*}
     */
    getExportData(colMode, filter, forceId) {
        let data;
        if (filter) {
            data = this.dataView.getFilteredItems()
        } else {
            data = this.data
        }

        let cols;
        switch (colMode) {
            case "data":
                if (data[0]) {
                    cols = Object.keys(data[0])
                }
                break;
            case "available":
                cols = this.avalibleColumns.map(c => c.id);
                break;
            case "visible":
                cols = this.columns.map(c => c.id);
                break;
            case "settings":
                if (!this._exportCols) {
                    this._exportCols = this.columns.map(c => c.id);
                }
                cols = this._exportCols
                break;
        }

        if (forceId && !cols.includes('id')) {
            cols.push('id');
        }
        let ret = data.map(d => bs.filterObject(d, cols));
        return ret;


    }

    /**
     * alias for this.exportJson see that
     */
    exportJSON(options) {
        return this.exportJson(options)
    }

    /**
     * Export the table as json
     * @param {Boolean} [options.download=false] - if true will trigger a download of the file
     * @param {String} [options.fileName='${view}_export_${date}.json'] - if true will trigger a download of the file if you want more control use bs.downloadString
     * @return {String}
     */
    exportJson(options) {

        let defaults = {
            download: false,
            fileName: `${this.config.view}_export_${moment(Date.now()).format('DD-MM-YYYY')}.json`,
            filter: true,
        }
        options = bs.mergeDeep(defaults, options);


        // let data
        // if (options.filtered) {
        //     data = this.dataView.getFilteredItems()
        // } else {
        //     data = this.data
        // }
        let data = this.getExportData(options.columnMode, options.filter);


        let json = JSON.stringify(data, null, 4);
        if (options.download) {
            bs.downloadString(json, 'text/json', options.fileName);
        }

        return csv;

    }

    uniqueVals(column) {
        return bs.uniqueArray(this.data.map(d => d[column]));
    }

    /**
     * Add a dropdown to filter to ONE unique value of a column.
     * @param column - the column to make the filter for
     * @param [options] - optionally define the dropdown options if you dont want to use the columns unique values
     */
    addDropdownFilter(column, options) {

        this.addCustomFilter({
                name: column,
                fn: (item, args) => {
                    let val = args.value;
                    return !val || item[column] == val;
                },
                args: {value: false},
            }
        )

        if (!options) {
            options = ['all_' + column, ...this.uniqueVals(column)];
        }

        this.addToolbarDropdown(column, 'Filter table to selected ' + column, options, (grid, item, e) => {
            let val = e.target.value;
            if (val == 'all_' + column) {
                this.setCustomFilterArgs(column, {value: false});
            } else {
                this.setCustomFilterArgs(column, {value: val});
            }
        })

    }

    /**
     * Takes a dom node/element from an event.target and a selector and returns the selector if its a parent of the target.
     * @param parentSelector - a css selector
     * @param target - html node / element from an event.target
     */
    targetExpand(parentSelector, target) {
        let parent = this.grid.getCanvasNode().querySelector('parentSelector');
        let child = parent;


        let recursiveDive = (parent, target) => {

            if (parent == target) {
                return true;
            }
            if (parent.children.length == 0) {
                return false;
            }

            for (let i = 0; i < parent.children.length; i++) {
                let child = parent.children[i];
                let found = recursiveDive(child, target);

                if (found) {
                    return true;
                }
            }
            return false;

        }


    }

    /**
     * add an action WARNING USE addActionButton or make sure string calls the fn you need
     * @param item - a full item or the css selector for the item
     * @param string - the html string for the item $id in this string will be replaced with the row.id in the formatter
     * @param fn - the fn to be executed on click
     */
    addAction(item, string, fn, formatter) {
        // this.formatter = formatter;

        if (typeof item == "object") {
            string = item.string || string;
            fn = item.fn || fn;
            formatter = item.formatter || formatter;
            item = item.selector;
        }

        if (typeof string == 'undefined') {
            throw Error("item.string not provided in object and string is undefined");
        }
        if (typeof fn == 'undefined') {
            throw Error("item.fn not provided in object and fn is undefined");
        }
        if (typeof item == 'undefined') {
            console.warn("No Item");
        }
        item = {
            selector: item,
            string: string,
            fn: fn,
            formatter: formatter
        }

        if (!this._actions) {//init
            this._actions = [];
        }

        this._actions.push(item);

        //this is so that the first few rows that have already rendered get updated with the new action
        if (this.grid) {// only if the grid is initialized
            this.grid.invalidateAllRows();
            this.grid.render();
        }

    }

    updateActionString(itemSelector, string) {
        let a = this._actions.find(a => a.selector == itemSelector)
        if (!a) {
            console.error("No Action found with selector: ", itemSelector);
            return;
        }

        a.string = string;

        if (this.grid) {// only if the grid is initialized
            this.grid.invalidateAllRows();
            this.grid.render();
        }
    }

    /**
     * This is convoluted but it works there is probably a better way
     * @param index
     * @param row_id
     * @private
     */
    _executeAction(index, row_id) {
        this._actions[index].fn(row_id, this);
    }

    /**
     * Add an action to the grid this will show up in the actions column
     * this is a utility extension of addAction
     * @param name - the name for a selector not really used
     * @param title - the tooltip
     * @param html - innerHtml for button
     * @param fn - fn(row_id, this) called onclick
     * @param options - optional options
     * @param {string} [options.colorClass='btn-info'] - a class for styling the button ususaly color
     * @param {function} [options.formatter] - a function to be called from the slick formatter see initFormatters()

     */
    addActionButton(name, title, html, fn, options) {

        console.debug("Action: add Action Button: ", name)
        options = options || {}
        options.colorClass = options.colorClass || 'btn-info'


        // options = bs.mergeDeep(defaults, options);

        if (!this._actions) this._actions = [];

        // `<span name="${name}" title="${title}" class="btn btn-sm btn-outline-dark">${html}</span>`,
        let actionIndex = this._actions.length;

        // let btn = document.createElement('button');
        // btn.name = name;
        // btn.setAttribute('class', `slick-icon-btn btn btn-sm float-right ${options.colorClass}` );
        // btn.setAttribute('title', title);
        // btn.innerHTML = html;
        // btn.addEventListener("click", e=>{
        //     console.log("WOW THIS WORKS:", e);
        //     this._executeAction(actionIndex,'$id')
        //
        // })
        let item = {
            selector: `button[name=${name}]`,
            // string: btn.outerHTML,
            string: `<button
                        name="${name}" onclick="_globalGrids[${this._id}]._executeAction(${actionIndex},'$id')"
                        class="slick-icon-btn btn btn-sm float-right ${options.colorClass}"
                        title="${title}">
                            ${html}
                    </button>`,
            fn: fn
        }
        if (typeof options.formatter == 'function') {
            item.formatter = options.formatter;
            console.warn("Action: FORMATTER: ", item.formatter)

        } else {

            console.warn("Action: NO FORMATTER: ", options.formatter)
        }

        this.addAction(item);
    }

    /*
        updateActionButton(name, title, html, fn, options) {

            let defaults = {
                colorClass: 'btn-info'
            }

            options = bs.mergeDeep(defaults, options);

            if (!this._actions) this._actions = [];

            // `<span name="${name}" title="${title}" class="btn btn-sm btn-outline-dark">${html}</span>`,
            let actionIndex = this._actions.length;
            let item = {
                selector: `button[name=${name}]`,
                string: `<button name="${name}" onclick="_globalGrids[${this._id}]._executeAction(${actionIndex},'$id')" class="slick-icon-btn btn btn-sm float-right ${options.colorClass}" title="${title}">
                                ${html}
                             </button>`,
                fn: fn
            }
            let a = this._actions.find(a => a.selector == selector)
            if (!a) {
                console.error("No Action found with selector: ", itemSelector);
                return;
            }

            a = item;

            // this.addAction(item);
        }
    */

    updateActionButton(name, html) {
        let selector = `button[name=${name}]`
        let a = this._actions.find(a => a.selector == selector)
        if (!a) {
            console.error("No Action found with selector: ", itemSelector);
            return;
        }
        let dom = $(a.string)[0];
        dom.innerHTML = html;
        let string = com.outerHTML;
        a.string = string;

        if (this.grid) {// only if the grid is initialized
            this.grid.invalidateAllRows();
            this.grid.render();
        }
        return a;
    }

    /**
     * action handler
     * @param row_id
     * @param grid
     * @private
     */


    // findById() {
    //     return this.data.find(r=>r[this.])
    // }

    /**
     * Setup an action that links to planmap files using the reference table pattern
     *
     * A file action allows user to up
     *
     * @param config
     * @param config.refTable - the name of the reference table as defined in the server config
     * @param config.refField - the field in this table that has the id to go in the reference id spot
     * @param config.fileField - the filed in this table that has the version_id's of associated files
     * @param {Array<Object>} config.types - array of user defined types to classify files.
     * @param {String} config.types.value - the value to save in the file table
     * @param {String} config.types.name - A nice name for this type
     * @param {String} config.types.pos - the position in the select box to place it
     * @param {String} [config.types.default] - set to true to make this type selected by default
     * @param config.clientID - the client id for the file table.
     * @param config.groupID - the group id for the file table
     * @param config.meta - extra meta data to attach to the file
     */
    addFileAction(config) {

        console.log("Add FIle Action")

        //check if the row has file references
        this._hasFiles = (row) => {
            let version_ids = row[config.fileField];

            //if we didn't get an array make it an array
            if (!Array.isArray(version_ids)) {
                version_ids = [version_ids];
            }

            return !(version_ids == null || (Array.isArray(version_ids) && version_ids[0] == null))
            //we have an array and its null
        }
        fileGridPromise.then(d=>{
            fileGrid.data.forEach(d => {
                if (d.meta && d.meta.ref_table && config.refTable == d.meta.ref_table) {
                    //
                    Files.magicFile(d, config.types)
                }
            })
        })

        //get the files from the filegrid
        this._getFiles = (row) => {
            let res;

            let hasFiles = this._hasFiles(row);
            if (hasFiles) {

                console.log("File ID: ", row[config.fileField])
                let version_ids = row[config.fileField];
                // if (version_ids == null || (Array.isArray(version_ids) && version_ids[0] == null)) {
                //if we didn't get an array make it an array
                if (!Array.isArray(version_ids)) {
                    version_ids = [version_ids];
                }

                //filter out bad references
                let files = [];
                let bad_references = []
                version_ids.forEach(vid => {
                    let file = fileGrid.getLatestVersion(vid);
                    if (!file) {
                        console.log("Bad Reference: ", vid);
                        bad_references.push(vid);
                    } else {
                        files.push(file);
                    }
                });


                let file = files[0];
                console.log('Files: ', files);
                if (!file) {
                    console.log("All References are bad", bad_references);
                    res = {
                        success: false,
                        msg: "Bad File References",
                        data: bad_references,
                        bad_references: bad_references
                    }
                } else {
                    res = {
                        success: true,
                        msg: "Files Founds",
                        data: files,
                        bad_references: bad_references
                    }
                }

                // next(row, grid, res);
            } else {   //we have an array and its null
                // DID.setupNewFile(row, index);

                res = {success: false, msg: "No File References"}
                // next(e, value, row, index, res);
            }


            return res;

        }

        this._openFileGrid = async (row_id, grid) => {


            let row = grid.dataView.getItemById(row_id);
            if (!row) row = grid.dataView.getItemById(parseInt(row_id));
            if (!row) console.error("Unable to find row in data view with that ID you sure its right? id:", row_id);
            let res = this._getFiles(row);
            //now we know if there are files or just bad references
            // bs.resNotify(res);
            let label;
            try {//this will user tableGrid name filed if present
                label = row[this.config.nameField];
            } catch (e) {
                label = row[config.refField];
            }

            config.meta = config.meta ?? {};


            this._newFileFn = (row) => {
                //   let label ;
                // try {//this will user tableGrid name filed if present
                //     label = row[this.config.nameField];
                // } catch (e) {
                //     label = row[config.refField];
                // }
                newFile.setup("Upload New Documents For: " + label,
                    config.types,
                    config.groupID,
                    config.clientID,
                    config.refTable,
                    row[config.refField],
                    config.meta,
                    (res, rres) => {
                        console.log('callback; ', res, rres);
                        if (res.success && rres.success) {

                            let files = row[config.fileField];

                            console.log("BEFORE: ", files)
                            //Update row
                            // let layer = didDashboard.layer.getLayer(row.leaflet_stamp);
                            if (files[0] == null) {
                                files = [res.data.version_id];
                            } else {
                                files = [...files, res.data.version_id];
                            }
                            // layer.feature.properties[config.refField] = row[config.refField];

                            console.log("AFTER: ", files)

                            row[config.fileField] = files;

                            grid.dataView.updateItem(row.id, row);
                            // fileGrid.addFile(res.data);
                            fileGrid.filterByVersionID(files);

                            //this causes the filegrid to open because there is now a reference
                            newFile.hide();
                            // this.addFileAction(config);
                            this._openFileGrid(row_id, grid);

                        }
                    }
                );

                newFile.show();
            }

            //res is file res from above
            if (res.success) {
                //do the grid setup stuff
                console.log("Show Files: ", row[config.fileField]);
                fileGrid.filterByVersionID(row[config.fileField]);

                fileGrid.didGrouping(row, this._newFileFn, config.refField, label);
                // fileGrid.sortByMeta(key)
                // fileGrid.btnNew.style.display = 'unset';
                fileGrid.show();

            } else {

                //delete a bad reference
                if (res.bad_references && res.bad_references.length > 0) {

                    bs.resNotify({
                        success: false,
                        msg: "No File Corresponding To that Reference (Deleting Reference)"
                    });
                    let version_ids = res.bad_references;
                    let res2 = await Files.deleteFileReference(config.refTable, row[config.refField], version_ids[0]);

                    // grid.
                    // let layer = didDashboard.layer.getLayer(row.leaflet_stamp);
                    row[config.fileField] = version_ids.filter(v => v !== version_ids[0]);
                    grid.dataView.updateItem(row_id, row);
                    // layer.feature.properties[config.fileField] = row[config.fileField]

                    // $(didDashboard.table).bootstrapTable('updateRow', {
                    //     index: index,
                    //     row: row
                    // });

                    if (res2.route == "/api/auth/dbLogin") {
                        console.log("Confirming password");
                        confirmDBPassword(res)
                    }
                }

                //if clicked and no files upload a new one
                this._newFileFn(row);
                // DID.setupNewFile(row, index);
            }
        }

        this._fileActionFormatter = (item, dataContext, grid, row, cell, value, columnDef) => {

            // let html = '';
            if (!item) {
                console.error("No Action passed in to formatter");
                return;
            }
            let string = item.string;
            if (this._hasFiles(dataContext) == false) {
                let dom = $(item.string)[0];
                dom.innerHTML = '<i class="fa fa-upload"></i>';
                string = dom.outerHTML;
            }

            string = string.replace('$id', dataContext.id);

            return string;

            // if (this.grid) {// only if the grid is initialized
            //     this.grid.invalidateAllRows();
            //     this.grid.render();
            // }
            // return a;
        };

        this.addActionButton(config.refTable,
            "Manage Files for this row",
            '<i class="fa fa-file"></i>',
            this._openFileGrid,
            {
                colorClass: 'btn-info',
                formatter: this._fileActionFormatter

            })


    }


    // the simple way is to use meta as a refrence
    /**
     *
     * @param config.types - the difrent types for this fileAction
     * @param config.types.pos - the position to sort the types in the drop down
     * @param config.types.name - what gets saved to db
     * @param config.types.value - what the user sees
     * @param [config.types.default] - the option to select by default otherws i user must input one
     * @param config.paths - an array of defult file paths som character limits may apply
     * @param +
     */
    addFileAction2(config) {

        let fileRefMap = {}
        let refFileMap = Files.refMap || {};

        // we have file.meta.refId
        // we have table.id
        // if a row in this
        let field = this.options.refField || this.options.idField;
        //then we know 100% its a file we care about for this table
        // ... we can also add this

        // Step one find our files
        // handy global files var of all files we have access to ;)
        let ours = files.filter(f => {
            // client id and group id shouldhapen server side
            // so we should have all files for this user but whitch ones are for this table
            // pop files is dependant on meta data
            // notibaly the type, path, refId, refTable
            if (f.meta && f.meta.refId) {

                let rows = this.data.filter((r) => {
                    return r[field] === f.meta[field];
                })
                // if we have multiple files here that means this single file is linked
                // to multiple rows in this same table ... why not?

                // theoreticaly we only need the one to know its safe to show the user
                if (rows.length) {

                    //we have succesfuly linked this file to this table
                    return true
                }
            }
            return false; //defalt dont show file
        })

        //bad For each file for each row
        // my bad is a singe convolution in ml so grain of salt


    }

    setBtn(btn, wireUpActivation = true) {
        this.btn = btn;

        if (!this.btn.addEventListener) {
            console.error("This button is not valid give me a dom button! ", this.btn);
            return;//todo create a button?
        } else {
            this.btn.addEventListener('click', (e) => {
                this.toggle();
            })
        }
        //


        if (wireUpActivation) this.wireButtonActivation();
    }

    /**
     * Utility function to toggle active class n a dom element when the grid is shown and hidden
     * @param {Element} button - the dom button
     */
    wireButtonActivation(button) {
        //you gota do this
        // button.addEventListener('click', ()=>{
        //     this.toggle();
        // })
        this.btn = button || this.btn;
        if (!this.btn) {
            console.warn("No button found to wirth up activations! maybe use setBtn()");
            return
        }
        this.onshow.subscribe(() => {
            this.btn.classList.toggle('active', true);
        })
        this.onhide.subscribe(() => {
            this.btn.classList.toggle('active', false);
        })
    }

    /**
     * So the info Action is for providing a info popup for an row
     * this is meant to be pretty flexible but anything majorly different you might want to make you own custom action button
     * @param [options] - options for various setting
     * @param {String} [options.prependHTML] - html to put before the row summery
     * @param {Boolean} [options.rowSummery=true] - set to false to hide the row summery
     * @param {String} [options.midHTML] - html to put after the row summery but before prompt template
     * @param {String} [options.appendHTML] - html to put at the end after the prompt template
     * @param {Boolean} [options.expanded='false'] - set to true to have the row summery expanded  by default
     * @param {Object} [options.template={}] - a bs.prompt template if you need advanced inputs
     * @param {Object} [options.values={}] - the bs prompt default values
     * @param {Boolean} [options.submit=false] - the text for submit button if you need
     * @param {Function} [options.created] - ($modal, row) Called when the modal is initialized
     * @param {Function} [options.callback] - (data, $modal, row) Called when the data is submitted
     * @param {Function} [options.closed] - ($modal, row) Called right before modal is closed. returning true from this function can cancel the close.
     * @param [options.name='info'] - the action name
     * @param [options.title="View Row Summery"] - the action tooltip
     * @param [options.icon='<i class="fa fa-info"></i>'] - the action icon
     * @param [options.colorClass='btn-info'] - the css class for the bootstrap button
     * @param [options.formatter=false] - Optionally override the formatter for advanced use cases.  this must define a button with name="options.name" for grid to find
     * @example
     grid.addInfoAction({
     prependHTML: '<span name="myDynamic">prependHTML Click me</span>',
     midHTML: 'Middle HTML',
     appendHTML: 'Append HTML',
     expanded: true,
     submit: 'alert!',
     template: {msg: 'input'},
     values: {msg: 'Hello World'},
     callback: (data, $modal, row)=>{alert(data.msg)},//
     closed: ($modal, row)=>{ //
     let c = prompt('Do You Want To Cancel Closing Info (y)?');
     return c.toLowerCase().includes('y') //if we return true the modal will not be closed
     },
     created: ($modal, row)=>{ //we can now add the event listeners to our html (this is jquery way)
     $modal.find('span[name="myDynamic"]').on('click', e=>{e.target.style.background = bs.getRandomColor()})
     }
     });
     */
    addInfoAction(options) {
        options = options ?? {};
        options.values = options.values ?? {}
        options.template = options.template ?? {}
        options.submit = options.submit ?? false;
        options.prependHTML = options.prependHTML ?? '';
        options.midHTML = options.midHTML ?? '';
        options.appendHTML = options.appendHTML ?? '';
        options.name = options.name ?? 'info'
        options.title = options.title ?? "View Row Summery"
        options.icon = options.icon ?? '<i class="fa fa-info"></i>'
        this._openInfo = (id, grid) => {

            // row[this.config.nameField]
            let row = grid.data.find((r) => r.id == id);
            let name = row[grid.config.nameField] || row.id;
            let headingid = 'info_summery_heading' + id;
            let collapseid = 'info_summery_collapse' + id;

            let vals = Object.entries(row)//['key', 'value']

            if (grid.fieldConfigs) {
                vals = vals.filter(e => {
                    let fc = grid.fieldConfigs.find(c => c.name == e[0]);
                    return fc && fc.visible && !fc.hideInfo;
                })//filter out the non visible enteries
            }

            vals = vals.map(e => {
                return {Field: bs.niceName(e[0]), Value: e[1]}
            });//map them into a for table builder
            // grid.fieldConfigs
            let table = buildHtmlTable(vals);

            let expanded = 'false';
            let show = ''
            if (options.expanded) {
                expanded = 'true';
                show = 'show'
            }
            let rowSummery = `
             <div class="card-header p-0" id="${headingid}">
                <h5 class="mb-0">
                    <button class="btn btn-secondary" style="width: 100%" data-toggle="collapse" data-target="#${collapseid}" aria-expanded="${expanded}" aria-controls="collapseOne">
                    View Row Summary
                    </button>
                </h5>
            </div>
            
            <div id="${collapseid}" class="collapse ${show}" aria-labelledby="${headingid}" >
                <div class="card-body">
                    ${table.outerHTML}
                </div>
            </div>
            `
            //enforce rowSummery option
            if (options.rowSummery === false) rowSummery = '';

            let summery = `
        <div class="card mb-3">
            ${options.prependHTML}
           ${rowSummery}
            ${options.midHTML}
        </div>
        `
            bs.prompt('Info: ' + name, options.template, options.values, {
                // submit: 'Save ' + name,
                submit: options.submit,
                prependHTML: summery,
                stringifyArrays: true,
                callback: (data, $modal) => {
                    options.callback(data, $modal, row)
                },
                created: ($modal) => {
                    if (typeof options.created == 'function') {
                        options.created($modal, row);
                    }
                },
                closed: ($modal) => {
                    if (typeof options.closed == 'function') {
                        options.closed($modal, row);
                    }
                },
                appendHTML: options.appendHTML
            }).then(data => {
                console.log('Info Data (Doing Nothing): ', data)
            });

        }

        this.addActionButton(options.name,
            options.title,
            options.icon,
            this._openInfo,
            {
                colorClass: options.buttonClass ?? 'btn-info',
                formatter: options.formatter
                // formatter: this._fileActionFormatter
            })


    }


    /**
     * called by the global handle when grid is found //todo this is a wee convulutade
     * @param event
     * @private
     */
    _jspanelLoaded(event) {

        this.panel = event.panel;

        this.onInitPanel.notify(event, this);

        this.initResizer();
        // grid.resize({
        //     width: panel.clientWidth,
        //     height: panel.clientHeight,this.resize();
        // })
        // this.resize(this.getPanelSize());
        // setTimeout(1000, ()=>{this.resize()});
        this.resetToolbar();
        this.resize();
        this.onshow.notify();
    }

    _jspanelClosed(event) {
        //before close
        //the refrence is deleted in the onclose event see defults in constructor
        this.clearCustomFilters(true);
        // this.defaultCustomFilterArgs
        this.onhide.notify()

    }

    _jspanelMinimized(event) {
        this.onhide.notify()
    }

    _jspanelMaximized(event) {
        if (event.panel.statusBefore && event.panel.statusBefore == 'minimized') {
            this.onshow.notify();
        }
        this.resize();

    }

    _jspanelNormalized(event) {
        if (event.panel.statusBefore && event.panel.statusBefore == 'minimized') {
            this.onshow.notify();
        }
        this.resize();
    }

    initPanel() {
        // create a demo jsPane
        if (this.options.jspanelOptions.headerToolbar) {
            console.warn("WARNING: JSpanel headerToolbar option is getting wiped out");
        }
        // this.options.jspanelOptions.id
        jsPanel.create(this.options.jspanelOptions);//see _jspanelLoaded


        // setTimeout(1000, ()=>{this.resize()});
        // this.panel = jsPanel.create(this.options.jspanelOptions, () => {
        //     this.resetToolbar();
        // });//

        // this.initResizer();
        // this.panel = jsPanel.create({
        //     headerTitle: 'Download Project Data',
        //     theme:  bs.getCssVar("--panel-theme", true),
        //     content: this.gridElement,
        //     callback: function () {
        //         // this.content.style.padding = '10px';
        //         console.log("callback");
        //     },
        //     onclosed: () => {
        //         console.log("Closed Help");
        //         help.panel = undefined;
        //     },
        // });

    }

    /**
     * Adds a data item to the data view triggers onAddItem(e, item) Event
     * @param item
     */
    addItem(item) {
        this.dataView.addItem(item);
        this.onAddItem.notify(item, undefined, this);
    }

    /**
     * deletes an item please use this over this.dataview.deleteItem so that the event is trigered
     * @param id
     */
    delItem(id) {
        this.onDelItem.notify(id, undefined, this)
        this.dataView.deleteItem(id);
    }

    /**
     * use dataView.setFilterArgs({
     * filterBy:{
     *     version_id:'xyz',
     *     ...
     * },
     * invert: false,
     * customFilters:[
     *     {
     *         name: 'filter1'
     *         fn: (item, args)=>{return boolean}
     *         args: any, // passed into fn
     *         invert: false, // if true fn result is inverted
     *         or: false // if true this filter is || with the preceding ones rather then &&
     *     },
     * ]
     * })
     * @param item
     * @param args
     * @return {boolean}
     * @private
     */
    _filterBy(item, args) {
        // console.log("FilterBy", item, args);

        //custom1 || custom2 && filterbu
        // true || false && true => true
        let ret = true;

        if (args.customFilters) {

            let filters = args.customFilters
            if (bs.isObject(filters)) {
                filters = [filters];
            }
            if (!Array.isArray(filters)) {
                console.error("Grid Filter Error: invalid custom filters not an Object or an Array");
            } else {
                let isFirst = true;
                filters.forEach(filter => {//if no filters ret is left true
                    let tmpRet = filter.fn(item, filter.args);//execute custom filter fn
                    if (filter.invert == true) {
                        tmpRet = !tmpRet;
                    }

                    if (isFirst) {//the first one is just the result
                        ret = tmpRet;
                        isFirst = false;
                    } else if (filter.or === true) {
                        ret = ret || tmpRet;
                    } else {
                        ret = ret && tmpRet;
                    }
                })
            }
        }

        let filterRet = true;
        if (bs.isObject(args.filterBy)) {
            let entries = Object.entries(args.filterBy);
            for (let i = 0; i < entries.length; i++) {
                let key = entries[i][0];//the filed name
                let value = entries[i][1];// the filter values
                // console.log(key, value, item[key]);

                //if its not in this turn it to false and break.
                //the effect is fitlera && filterb && filterc


                if (Array.isArray(item[key])) {

                    console.log('array filter item: ', item[key])
                    item[key].some(item => value.includes(item))

                    if (Array.isArray(value)) {
                        if (!item[key].some(iii => value.includes(iii))) {
                            filterRet = false;
                            break;
                        }
                    } else {
                        if (!item[key].some(iii => iii == value)) {
                            filterRet = false;
                            break;
                        }
                    }


                } else {

                    if (Array.isArray(value)) {
                        if (!value.includes(item[key])) {
                            filterRet = false;
                            break;
                        }
                    } else {
                        if (item[key] != value) {
                            filterRet = false;
                            break;
                        }
                    }
                }
            }
        }

        if (args.invert == true) {
            filterRet = !filterRet;
        }

        return ret && filterRet;
    }

    percentCompleteSort(a, b) {
        return a["percentComplete"] - b["percentComplete"];
    }

    inlineEdit(e, args) {//args._grid is a thing if you want to override from outside the class :)
        console.warn("Cell Change called and not overridden Maybe you want to save to db or something. ", e, args)

    }

    /**
     * hies the dialog
     */
    hide() {
        if (this.options.containerType == "dialog") {
            this.dialog.dialog('close');
            this.onhide.notify();

        } else if (this.options.containerType == 'jspanel') {
            this.panel.minimize()
        } else {
            $(this.containerSelector).show();
        }

    }

    /**
     * shows the dialog /jspanel or removes display:none
     */
    show() {
        if (this.options.containerType == "dialog") {
            this.dialog.dialog('open');
            this.onshow.notify();
        } else if (this.options.containerType == 'jspanel') {

            let isclosed = !this.panel || jsPanel.getPanels().find(p => p.id === this.panel.id) === undefined
            console.log('Showing jspanel isClosed:', isclosed, this.panal);
            if (isclosed) {
                this.initPanel()

            } else {
                this.panel.normalize();
            }
        } else {
            $(this.containerSelector).show();
        }
        // this.onshow.notify();//hapens in jspanel events
        // this.dialog.resize();

    }


    /**
     * Refresh the gird render of all rows
     * or if id is provided just the row with that id
     * @param [id]
     */
    render(id) {
        if (this.grid) {// only if the grid is initialized
            if (id) this.grid.invalidateRow(this.dataView.getIdxById(id));
            else this.grid.invalidateAllRows();
            this.grid.render();
        }
    }

    /**
     * Toggles the dialog
     * @param {Boolean} [state] Accepts a boolian state optional
     * @return true if grid is now opened false if closed
     */
    toggle(state) {
        let current = this.isOpen()
        if (state === current) {// has to be ===
            return;//do nothing
        }
        if (this.isOpen()) {
            this.hide();
            return false;
        } else {
            this.show();
            return true;
        }


    }

    /**
     * @returns boolean true if dialog is open
     */
    isOpen() {
        if (this.options.containerType == "dialog") {
            return this.dialog.dialog("isOpen")
        } else {
            return !!this.panel && this.panel.status != 'minimized';
        }
    }

    // FileNameFormatter(row, cell, value, columnDef, dataContext) {
    //     if (value == null || value == undefined || dataContext === undefined) {
    //         return "";
    //     }
    //
    //     value = value.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
    //     var spacer = "<span style='display:inline-block;height:1px;width:" + (15 * dataContext["indent"]) + "px'></span>";
    //     var idx = dataView.getIdxById(dataContext.id);
    //     if (data[idx + 1] && data[idx + 1].indent > data[idx].indent) {
    //         if (dataContext._collapsed) {
    //             return spacer + " <span class='toggle expand'></span>&nbsp;" + value;
    //         } else {
    //             return spacer + " <span class='toggle collapse'></span>&nbsp;" + value;
    //         }
    //     } else {
    //         return spacer + " <span class='toggle'></span>&nbsp;" + value;
    //     }
    // };

    /**
     * Initializes the filters
     */
    initFilters() {

        this._filterArgs = {
            customFilters: [],
            filterBy: false,
            invert: false,
        }

        this.dataView.setFilterArgs({_filterID: null});

        // this.dataView.setFilter(this._filterID);

        // this.dataView.setFilter(this._filterVersionID);

        this.dataView.setFilter(this._filterBy);


        // this.dataView.setFilter(this._filterByVersionID);

        // this.dataView.setFilter(this.filterByVersionID);

    }

    updateFilter(noSpaital) {
        this.dataView.setFilterArgs(this._filterArgs);
        this.dataView.refresh();
        if (this.isSpatial && !noSpaital) {
            this.spatialFilterSync();
        }

    }

    setFilterArgs(filterArgs, update = true) {
        this._filterArgs = filterArgs;
        if (update) {
            this.updateFilter()
        }
    }

    /**
     * @callback filterCallback
     * @param  {Object} item - the row/item data
     * @param  {*} args   - the args set when filter was created
     */
    /**
     * Adds a custom filter function to ve executed on row filtering
     * @param {Object} filter - object representing the custom filter
     * @param {String} filter.name - a name for you filter for delCustomFilter(name)
     * @param {filterCallback} filter.fn - (item, args)=>{return boolean}
     * @param {*} [filter.args] - argument to be  passed into fn
     * @param {Boolean} [filter.invert=false] - if true fn result is inverted
     * @param {Boolean} [filter.or=false] - if true this filter is || with the preceding ones rather then &&
     * @param {Boolean} [update=true] - if false the grid will not be filtered after adding
     */
    addCustomFilter(filter, update = true) {
        this._filterArgs.customFilters.push(filter);
        //default args to restore to on close

        if (!this._defaultCustomFilterArgs) this._defaultCustomFilterArgs = {}

        let clone = bs.mergeDeep({}, filter.args);
        this._defaultCustomFilterArgs[filter.name] = clone;

        if (update) {
            this.updateFilter();
        }
    }

    defaultCustomFilterArgs(update) {

        if (!this._defaultCustomFilterArgs) this._defaultCustomFilterArgs = {}

        Object.entries(this._defaultCustomFilterArgs).forEach(e => {
            this.setCustomFilterArgs(e[0], e[1], false);
        })
        if (update) {
            this.updateFilter()
        }
    }

    saveFilterArgs(key) {
        key = key || "custom_filter_" + this._id;
        return localStorage.setItem(key, JSON.stringify(this._filterArgs.customFilters));
    }

    restoreFilterArgs(key) {
        key = key || "custom_filter_" + this._id;
        //todo finsih
        return localStorage.getItem(key);
    }


    /**
     * Update the args for a custom filter
     * @param name - the name of the custom filter
     * @param args - the args for that filter
     * @param {Boolean} [update=true] - if false the grid will not be filtered after adding
     */
    setCustomFilterArgs(name, args, update = true) {
        let filter = this._filterArgs.customFilters.find(f => f.name == name);
        filter.args = args;
        if (update) {
            this.updateFilter();
        }
    }

    getCustomFilterArgs(name) {
        let filter = this._filterArgs.customFilters.find(f => f.name == name);
        return filter.args;
    }

    /**
     * Dellete a custom filter
     * @param {String} name - The name of the filter to delete
     * @param {Boolean} [update=true] - if false the grid will not be filtered after adding
     */
    delCustomFilter(name, update = true) {
        this._filterArgs.customFilters = this._filterArgs.customFilters.filter(f => f.name != name);
        if (update) {
            this.updateFilter()
        }
    }

    /**
     * Clears custom filter args (happens on panel close)
     * @param {Boolean} [update=true] - if false the grid will not be filtered after clearing
     */
    clearCustomFilters(update = true) {
        // this.setFilterArgs({});
        // this.setCustomFilterArgs(this
        return this.defaultCustomFilterArgs(update);
        // this._customFilters = [];
        if (update) {
            this.updateFilter()
        }
    }

    /**
     * Filter the file grid by file id
     * @param id the id of the file we want
     * @deprecated
     */
    filterByID(id) {
        this.filterBy({file_id: id}, false)
    }

    /**
     * Filters the file grid by version id
     * @param version_id the file version_id
     */
    filterByVersionID(version_id) {
        this.filterBy({version_id: version_id}, false)
    }

    filterByRefID(version_id) {
        this.filterBy({version_id: version_id}, false)
    }

    /**
     * lets you filter by key value object
     * @example
     *      myGrid.filterBy(id: [3,5,8,13]);
     * @param filterByArg an object with keys = field, value = value or array of values
     * @param invert - inverts the filter selection
     * @param {Boolean} [update=true] - if false the grid will not be filtered after updating args

     */
    filterBy(filterByArg, invert, update = true) {
        this._filterArgs.invert = invert
        this._filterArgs.filterBy = filterByArg;
        if (update) {
            this.updateFilter()
        }
    }

    /**
     * Get all filter args usualy if you dont want to wipe out other filters you can get the args then
     * modify them with your aditional filter
     * @returns {Object} the slickgrid filter args
     */
    getFilterArgs() {
        return this._filterArgs;
    }

    /**
     * Reset/clear filter fields does not clear custom Filters see clearCustomFilters()
     * @param {Boolean} [update=true] - if false the grid will not be filtered after updating args
     */
    filterClear(update = true) {
        this._filterArgs.filterBy = false;
        if (update) {
            this.updateFilter()
        }
    }

    /**
     * We have to do it this way so the formatters have access to 'this' since js class objects are just normal functions :/
     */
    initFormatters() {

        this.actionFormatter = (row, cell, value, columnDef, dataContext) => {

            if (typeof this._actions == "undefined") {
                this._actions = [];
            }

            let html = ``;

            this._actions.forEach(item => {
                if (typeof item.formatter === 'function') {
                    html += item.formatter(item, dataContext, this, row, cell, value, columnDef)
                } else {
                    html += item.string.replace('$id', dataContext.id);
                }
            });

            // html += `<button onclick="_globalGrids[${this._id}].deleteByFileId('${dataContext.id}')" class="slick-icon-btn btn btn-danger btn-sm  float-right" title="Delete This Version">
            //                 <i class="fa fa-trash" aria-hidden="true"></i>
            //              </button>`
            //
            // html += `<button onclick="Files.downloadFile('${dataContext.id}')" class="slick-icon-btn btn btn-primary btn-sm  float-right" title="Download This Version">
            //                         <i class="fa fa-download" aria-hidden="true"></i>
            //                      </button>`
            // html += `<button onclick="_globalGrids[${this._id}].viewFile('${dataContext.id}', '${dataContext.file_name}')" class="slick-icon-btn btn btn-success btn-sm float-right" title="View This Version">
            //                         <i class="fa fa-eye" aria-hidden="true"></i>
            //                      </button>`

            return html;
        };
    }


    /**
     * this is responsible for setting up the row highlight system
     * https://stackoverflow.com/a/19985148/4530300
     */
    initHighlight() {

        // this.stylesheet = document.createElement('style')
        // this.stylesheet.innerHtml = `
        //     .slick-highlight-row {
        //         background-color: red !important;
        //     }
        // `

        // addCSSRule(dynamic_sheet, 'slick-highlight-row', 'background-color: red !important;')

        // document.head.appendChild(this.stylesheet);

        this.dataView.getItemMetadata = metadata(this.dataView.getItemMetadata);

        function metadata(old_metadata) {
            return function (row) {
                var item = this.getItem(row);
                var meta = old_metadata(row) || {};

                if (item) {
                    // Make sure the "cssClasses" property exists
                    meta.cssClasses = meta.cssClasses || '';

                    if (item.highlight) { // If the row object has a truthy "canBuy" property
                        let highlightClass = 'slick-highlight-row'
                        if (typeof item.highlight == 'string') {
                            highlightClass += '-' + item.highlight
                        }
                        meta.cssClasses += ' ' + highlightClass;      // add a class of "buy-row" to the row element.
                    } // Note the leading   ^ space.


                    /* Here is just a random example that will add an HTML class to the row element
                       that is the value of your row's "rowClass" property. Be careful with this as
                       you may run into issues if the "rowClass" property isn't a string or isn't a
                       valid class name. */
                    if (item.rowClass) {
                        var myClass = ' ' + item.rowClass;
                        meta.cssClasses += myClass;
                    }
                }

                return meta;
            }
        }

    }

    /**
     * Toggles row highlighting or sets it to a desired state
     * @param id - the slick id
     * @param {Boolean} [state] - if you wish to set it to something specific instead of toggling
     * @param {Int} [timeout] - setTimeout for toggling highlight back again
     * @param {string} [scrollTo='top'] - if falsy wont scrole otherwise options are 'top', 'view',
     */
    highlightRow(id, state, timeout, scrollTo = 'top') {

        let row = this.dataView.getItem(id);

        if (typeof state != 'undefined') {
            row.highlight = state;
        } else {
            row.highlight = !row.highlight;
        }

        this.dataView.updateItem(row.id, row);

        console.log('highlight row ', id, ": ", row.highlight);

        if (row.highlight) {
            if (scrollTo === 'view') {
                this.grid.scrollRowIntoView(id);
            } else if (scrollTo == 'top') {
                this.grid.scrollRowToTop(id);
            }
        }

        if (typeof timeout != 'undefined') {
            setTimeout(() => {
                this.highlightRow(id, !row.highlight)
            }, timeout)
        }
    }


    /**
     * Add a css class to a row
     * @param id - the slick id (data[x].id)
     * @param {String} className - css class names light the would go in class='foo bar'
     */
    addRowClass(id, className) {
        let row = this.dataView.getItem(id);
        row.rowClass += ' ' + className;
        this.dataView.updateItem(row.id, row);
    }


    /**
     * This is to get around scoping and avoid events being wiped out on jspanel creation
     * @param name
     * @param value
     * @private
     */
    _executeGroupAction(name, value) {
        let action = this._groupActions.find(a => a.name == name);
        action.fn(this, action, value);
    }

    /**
     * Groups items by a filed and does some formatting also see Slick.Dataview.setGrouping() for more advanced stuff
     * @param field - the field to group by
     * @param options - optional options
     * @param {Function} [options.formatter] - if set the formatter will be overwriten with this one and you are responsible for setting it up
     * @param {Array<Object>} [options.actions] - An array of actions buttons to add
     * @param {String} options.actions.name - a name attribute for the button
     * @param {String} options.actions.html - the icon html
     * @param {String} [options.actions.title=''] - button title/tooltip
     * @param {String} [options.actions.colorClass=btn-info] - aditional css classes
     * @param {Function} options.actions.fn - the click function(grid, action, value), value = the grouped filed value
     * @param {Boolean} [options.aggregateCollapsed=false] - dataview.groupby option collapses groups
     */
    groupBy(field, options) {

        options = options || {};


        if (!options.formatter) {
            options.formatter = (g) => {
                let row0 = g.rows[0];
                let html = ``;
                html += ` ${row0[field]}  <span style='color:green'>(${g.count} Items)</span>`

                if (Array.isArray(options.actions)) {
                    options.actions.forEach(a => {

                        let color = a.colorClass || 'btn-info';
                        let title = a.title || '';

                        html += `<button class='slick-icon-btn btn ${color} btn-sm float-right' onclick="_globalGrids[${this._id}]._executeGroupAction('${a.name}','${g.value}')" title="${title}">
                                    ${a.html}
                                 </button>`
                    })

                    this._groupActions = options.actions;
                }

                return html;
            }
        }

        this.dataView.setGrouping({
            getter: field,
            formatter: options.formatter,

            /*(g) => {

                let row0 = g.rows[0];
                g.rows.forEach(r => {
                    if (r.version_num > row0.version_num) {
                        row0 = r;
                    }
                });

                let html = ``;
                html += ` ${row0[field]}  <span style='color:green'>(${g.count} Items) ${row0.file_name}</span>`

                //float right is in revers order
                html += `<button class='slick-icon-btn btn btn-danger btn-sm float-right' onclick="_globalGrids[${this._id}].deleteByVersionId('${g.value}')" title="Delete All Versions">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                 </button>`
                html += `<button onclick="_globalGrids[${this._id}].openNewVersionModal('${row0.id}')" class="slick-icon-btn btn btn-primary btn-sm float-right"  title="Upload New Version">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                 </button>`

                html += `<button onclick="Files.downloadFile('${row0.id}')" class="slick-icon-btn btn btn-primary btn-sm float-right" title="Download Latest Version">
                                    <i class="fa fa-download" aria-hidden="true"></i>
                                 </button>`
                html += `<button onclick="_globalGrids[${this._id}].viewFile('${row0.id}', '${row0.file_name}')" class="slick-icon-btn btn btn-success btn-sm float-right" title="View Latest Version">
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                 </button>`


                // html += `<button class='btn btn-warning btn-sm m-0 ml-4 float-right' onclick="deleteByVersionId('${g.value}')">
                //             Delete All Versions
                //          </button>`

                return html;
            }*/
            // aggregators: [
            //     new Slick.Data.Aggregators.Avg("percentComplete"),
            //     new Slick.Data.Aggregators.Sum("cost")
            // ],
            aggregateCollapsed: options.aggregateCollapsed || false,
            lazyTotalsCalculation: true
        });
    }

    /**
     * Group the files by version id
     */
    groupByVersionId() {
        this.dataView.setGrouping({
            getter: "version_id",
            formatter: (g) => {

                let currentFile = g.rows[0];
                g.rows.forEach(r => {
                    if (r.version_num > currentFile.version_num) {
                        currentFile = r;
                    }
                });

                let html = ``;
                html += ` ${currentFile.file_name} <span style='color:green'>(${g.count} Versions)</span>`

                //float right is in revers order

                if (this.options.deleteAction) {
                    html += `<button class='slick-icon-btn btn btn-danger btn-sm float-right' onclick="_globalGrids[${this._id}].deleteByVersionId('${g.value}')" title="Delete All Versions">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                 </button>`
                }
                html += `<button onclick="_globalGrids[${this._id}].openNewVersionModal('${currentFile.id}')" class="slick-icon-btn btn btn-primary btn-sm float-right"  title="Upload New Version">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                 </button>`

                html += `<button onclick="Files.downloadFile('${currentFile.id}')" class="slick-icon-btn btn btn-primary btn-sm float-right" title="Download Latest Version">
                                    <i class="fa fa-download" aria-hidden="true"></i>
                                 </button>`
                html += `<button onclick="_globalGrids[${this._id}].viewFile('${currentFile.id}', '${currentFile.file_name}')" class="slick-icon-btn btn btn-success btn-sm float-right" title="View Latest Version">
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                 </button>`


                // html += `<button class='btn btn-warning btn-sm m-0 ml-4 float-right' onclick="deleteByVersionId('${g.value}')">
                //             Delete All Versions
                //          </button>`

                return html;
            },
            // aggregators: [
            //     new Slick.Data.Aggregators.Avg("percentComplete"),
            //     new Slick.Data.Aggregators.Sum("cost")
            // ],
            aggregateCollapsed: false,
            lazyTotalsCalculation: true
        });
    }

    /**
     * Clear the groupings applied
     */
    groupingClear() {
        this.dataView.setGrouping();
    }

    /**
     * A slick grid validator for required fields
     * @private
     * @param value
     * @returns {{valid: boolean, msg: null}|{valid: boolean, msg: string}}
     */
    requiredFieldValidator(value) {
        if (value == null || value == undefined || !value.length) {
            return {valid: false, msg: "This is a required field"};
        } else {
            return {valid: true, msg: null};
        }
    }

    /**
     * RETURNS THE LATEST VERSION OF A FILE
     * NOTE: Ideally we add the constrain and then if the one in a trillion chance happens all that happens is db error and
     * a file fails to upload if we dont the worst case is someone views a file that doesn't belong to them is version id
     * for alpacs file matches file id for owls file so when they search for owls id it finds the version_id for an alpac
     * file first and shows the most recent version of that file
     *
     * @param id accepts both file id and version_id
     * @return {file}
     */
    getLatestVersion(id) {
        let file = this.data.find(f => f.id === id || f.version_id === id);
        if (!file) {
            console.error("Error: Invalid version id")
            return;

        }
        let versions = this.data.filter(f => f.version_id === file.version_id);
        return versions.reduce((prev, current) => (prev.version_num > current.version_num) ? prev : current)
    }

    /**
     * get the unique values of an collumn
     * @param colName
     */
    getUniqueValues(colName) {

        let values = this.data.map(row => row[colName]);
        let uniqueValues = bs.uniqueArray(values);

        return uniqueValues;


    }

}

//add event listener for jspanel resizes this should solve resizing for all grids
document.addEventListener('jspanelresize', Grid._jspanelResize, true);//a wee bit lagy with large tables i dont thinkg we need it
//todo add close suport here insted oin default so it cant get overriden
document.addEventListener('jspanelloaded', Grid._jspanelLoaded, true);//a wee bit lagy with large tables i dont thinkg we need it
document.addEventListener('jspanelbeforeclose', Grid._jspanelClosed, true);
document.addEventListener('jspanelnormalized', Grid._jspanelNormalized, true);
document.addEventListener('jspanelminimized', Grid._jspanelMinimized, true);
document.addEventListener('jspanelmaximized', Grid._jspanelMaximized, true);

// document.addEventListener('jspanelresizestop', Grid._jspanelResize, false);
// document.addEventListener('jspanelloaded', Grid._jspanelResize, false);
// document.addEventListener('jspanelfronted', Grid._jspanelResize, true);


//test mixin

Grid.prototype.mixinTest = function () {
    console.log(this);

}
