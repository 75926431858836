/**
 * @author Kier Lindsay
 *
 * aug 10 2020
 *
 * Goal:
 * Make an js client for wms
 *
 * requirments:
 * parse the 1.3.0/ 1.1.1 wms spec with focus on wms 1.3.0 specifically geoservers version
 * expose a js api to make wms requests that the wms server suports
 *
 * */
class WMS {

    /**
     * Construce wms client
     * @param url - the wms url
     * @param options
     */
    constructor(url, options = {}) {
        if (typeof url == "undefined") {
            throw "Error Must define WMS URL";
        }

        this.url = url;
        if (this.url[this.url.length - 1] != '?') {
            this.url += '?';
        }

        this.version = typeof options.version != 'undefined' ? options.version : '1.3.0'
        this.service = typeof options.service != 'undefined' ? options.service : 'WMS'
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')
        // this.a = typeof a !='undefined'?console.log(a):console.log('b')

        this.capabilities = {};

    }

    restoreCapabilities(flattened) {
        let restoreLayer = (l) => {
            Object.setPrototypeOf(l, WMSLayer.prototype)
            if (l.layers) l.layers = l.layers.map(restoreLayer)
            return l;
        }
        this.capabilities = flattened
        this.capabilities.layers = this.capabilities.layers.map(restoreLayer)
        return this.capabilities;

    }

    /**
     * Gets the xml doc capabilities from the geoserrver
     * @param options
     * @returns {Promise<Document>}
     */
    async getCapabilities(options) {
        //todo options
        options = options ?? {};
        options.timeout = options.timeout ?? 10000//10s
        let time = Date.now();

        let params = {
            service: this.service,
            version: this.version,
            request: "GetCapabilities"
        }

        let url = this.url + new URLSearchParams(params).toString();
        //
        let response
        // try {
            response = await bs.fetchWithTimeout(url, {
                method: 'GET',
                // mode: "no-cors",
                timeout: options.timeout,
                credentials: 'same-origin', // include, *same-origin, omit
            });
        // } catch (error) {
        //     // Timeouts if the request takes
        //     // longer than 6 seconds
        //     if(error.name === 'AbortError'){
        //         console.log('Aborted geoserver cuz it took to long');
        //
        //         bs.resNotify({success:false,
        //             msg:`Error: Geoserver took to long to respond some layers may no work.
        //             (${this.url} took more then ${options.timeout/1000}s aborting)`});
        //     }
        // }

        if (!response.ok) {
            console.error(`Response Not OK: ${response.statusText}`);
        }
        console.debug(`Done Fetching wms getCapabilities in ${Date.now() - time}ms`);
        time = Date.now();
        let text = await response.text();
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(text, "text/xml");
        console.debug(xmlDoc);

        //todo only re-parse if capabilities change?
        this.capabilities = {};
        this.capabilities.xml = xmlDoc;
        this.capabilities.lastUpdate = time;
        const tempCapabilityXML = this.capabilities.xml.getElementsByTagName('Capability')[0].children
        for (let i = 0; i < tempCapabilityXML.length; i++) {
            let xmlNode = tempCapabilityXML[i]
            switch (xmlNode.tagName.toString()) {
                case 'Request': {
                    let request = {}
                    request.xml = xmlNode;
                    this.capabilities.request = request;
                    break;
                }
                case 'Exception': {
                    let exception = {};
                    exception.xml = xmlNode;

                    this.capabilities.exception = exception;
                    break;
                }
                case 'Layer': {
                    if (typeof this.capabilities.layers == "undefined") this.capabilities.layers = [];
                    this.capabilities.layers.push(new WMSLayer(xmlNode, undefined, this));
                    break;
                }
                default:
                    console.warn(`Warning: Unknown capability sorting might have gon wrong parsing xml or the service ${xmlNode.tagName.toString()}`);

            }

        }
        console.debug(`Done Parsing getCapabilities in ${Date.now() - time}ms`);

        return xmlDoc;

        // let url = this.url + '?' + new URLSearchParams(params).toString();
        //
        // return fetch(url, {
        //     method: 'GET',
        //     credentials: 'same-origin', // include, *same-origin, omit
        // }).then(response => {
        //     if (!response.ok) {
        //         console.error(`Respopnse Not OK: ${response.statusText}`);
        //     }
        //     return response.text().then(function (text) {
        //         // do something with the text response
        //
        //
        //         let parser = new DOMParser();
        //         let xmlDoc = parser.parseFromString(text,"text/xml");
        //         console.debug(xmlDoc);
        //         console.debug(`Done Fetching getCapabilities ${Date.now() - time}ms`);
        //         return xmlDoc;
        //
        //     });
        // });

    }


    //setting version to 1.1.0 based on open layers default from our GeoServer
    //setting EPSG:4326 since our default is 4269 which doesn't work but they are virtualy identical
    //https://spatialreference.org/ref/epsg/4326/

    //Try to use the hocks from this plugin:
    //https://github.com/heigeo/leaflet.wms - can we use the hocks from this plugin?

    //Other References
    //https://gist.github.com/rclark/6908938  DO NOT actually start using better WMS. Been there, done that, just for reference here.
    //http://jsfiddle.net/3tnfp82c/
    //https://gis.stackexchange.com/questions/69124/leaflet-container-coordinates-vs-layer-coordinates

    // https://docs.geoserver.org/stable/en/user/services/wms/reference.html#getfeatureinfo

    //
    static getBBoxParams(map) {
        // WMS.getBBoxString()
        var width = _map.getSize().x;
        var height = _map.getSize().y;
        var bbox = _map.getBounds().toBBoxString();

        return {width, height, bbox}
    }

    /**
     * users global map for defaults todo: test with wms 1.3 and hanfle edge cases
     * @param options https://docs.geoserver.org/stable/en/user/services/wms/reference.html#getfeatureinfo
     * @param options.layers - Layers to display on map. Value is a comma-separated list of layer names.
     * @param [options.styles] - Styles in which layers are to be rendered. Value is a comma-separated list of style names, or empty if default styling is required. Style names may be empty in the list, to use default layer styling.
     * @param [options.srs='EPSG:4326'] - the srs of the getMap request
     * @param [options.bbox=map bbox] - Bounding box for map extent. Value is minx,miny,maxx,maxy in units of the SRS.
     * @param [options.height=_map height] - the height of the getMap image in px
     * @param [options.width=_map width] - the width of the getMap image in px
     * @param [options.info_format='text/html'] - list at https://docs.geoserver.org/stable/en/user/services/wms/reference.html#getfeatureinfo
     * @param [options.query_layers=options.layers] - the layers to querey for info will default to options.layers
     * @param options.x - X ordinate of query point on map, in pixels. 0 is left side. i is the parameter key used in WMS 1.3.0.
     * @param options.y - Y ordinate of query point on map, in pixels. 0 is the top. j is the parameter key used in WMS 1.3.0.
     * @param [options.feature_count=1000] - Maximum number of features to return. Default is 1000 so we get it all.
     * @param [options.buffer=2] - width of search radius around query point (x,y).

     * @param _map A leafelt map object to default the map width height and bbox
     * @return {Promise<Document>}
     */
    async getFeatureInfo(options, _map) {

        let time = Date.now();
        if (!_map) {
            _map = map;
        }
        // WMS.getBBoxString()
        var width = _map.getSize().x;
        var height = _map.getSize().y;
        var bbox = _map.getBounds().toBBoxString();

        options.x = Math.round(options.x);
        options.y = Math.round(options.y);

        options = Object.assign({
            'service': this.service,
            'version': this.version,
            'request': "GetFeatureInfo",
            'format': 'image/png',
            'info_format': 'text/html',
            'srs': 'EPSG:4326',
            'transparent': 'true',
            'tiled': 'false',
            // 'layers': querystring,//required
            'query_layers': options.layers,
            // 'x': Math.round(x), //round up to nearest integer
            // 'y': Math.round(y),
            'width': width,
            'height': height,
            'bbox': bbox,
            'buffer': 2, //buffer radius pixels, important for points, lines and where polygons meet, default is 3
            'feature_count': 1000
            //adding 16 to account for layers with multiple features, eg: Raptors, Piping and Swan Buffers, Col Nesting Birds and Endgrd Plants
            //wont show in pop-up if there's no feature on that point
            //should dynamically count features, but can't find a property related to features in the layer objects
        }, options)


        let url = this.url + new URLSearchParams(options).toString();
        //
        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin', // include, *same-origin, omit
        });

        if (!response.ok) {
            console.error(`Response Not OK: ${response.statusText}`);
        }

        let text = await response.text();
        console.debug(`Done Fetching getFeatureInfo ins ${Date.now() - time}ms`);
        // let parser = new DOMParser();
        // let xmlDoc = parser.parseFromString(text, "text/xml");
        // console.debug(xmlDoc);

        return text;

    }

    /**
     * Gets Layers from the capabilities with options to filter
     * @param options {Object} Options for how you want to get/filter layers
     * @param options.recursive {boolean} [false] - if true getLayers will dive down recursively into layer groups
     * @param options.queryable {boolean} [false] - if true result will be filtered to only queryable layers
     * @param options.requestable {boolean} [false] - if true result will be filtered to only requestable layers
     * @returns {(Array|undefined)} - returns undefined if there are no layers otherwise an array of all layers note if there are layers but they are filtered out you will get an empty array '[]'
     *
     *  @example wms.getLayers({recursive: true, requestable:true}) // returns all layers that you can request with getMap
     */
    getLayers(options = {}) {
        if (typeof this.capabilities.layers == "undefined" || this.capabilities.layers.length == 0) {
            return undefined;
        }
        let layers = this.capabilities.layers;

        if (options.recursive) {
            layers.forEach(l => {
                let tempLayers = l.getLayers({recursive: true});
                if (tempLayers) {
                    layers = layers.concat(tempLayers);
                }
            })
        }

        if (options.queryable) {
            layers = layers.filter(l => l.isQueryable());
        }
        if (options.requestable) {
            layers = layers.filter(l => l.isRequestable());
        }
        return layers;
    }

    /**
     * Alias for get layers {recursive: true} with params options for queryable and requestable
     * @param queryable {boolean} [false] - if true result will be filtered to only queryable layers
     * @param requestable {boolean} [false] - if true result will be filtered to only requestable layers
     */
    getAllLayers(queryable, requestable) {
        return this.getLayers({recursive: true, queryable: queryable, requestable: requestable});
    }

    async getStyles(layer) {

        //todo options
        let time = Date.now();

        let params = {
            service: this.service,
            version: this.version,
            request: "GetStyles",
            layers: layer.name,
        }


        let url = this.url + new URLSearchParams(params).toString();
        //
        let response = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin', // include, *same-origin, omit
        });

        if (!response.ok) {
            console.error(`Response Not OK: ${response.statusText}`);
        }
        console.debug(`Done Fetching styles ins ${Date.now() - time}ms`);
        time = Date.now();
        let text = await response.text();
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(text, "text/xml");
        console.debug(xmlDoc);


        console.debug(`Done Parsing styles in ${Date.now() - time}ms`);

        return xmlDoc;


    }

    /**
     * get legend grapics for a layer https://docs.geoserver.org/master/en/user/services/wms/get_legend_graphic/index.html
     * @param {WMSLayer|String} layer - the layer name as a string or a WMSLayer from capabilities
     * @param options optional options
     * @param [options.return='base64'] - how you want your data (url, blob, base64)
     * @param [options.style=undefined] - Optional Style of layer for which to produce legend graphic. If not present, the default style is selected. The style may be any valid style available for a layer, including non-SLD internally-defined styles.
     * @param [options.featuretype=undefined] - Optional Feature type for which to produce the legend graphic. This is not needed if the layer has only a single feature type.
     * @param [options.rule=undefined] - Optional Rule of style to produce legend graphic for, if applicable. In the case that a style has multiple rules but no specific rule is selected, then the map server is obligated to produce a graphic that is representative of all of the rules of the style.
     * @param [options.scale=undefined] - Optional In the case that a RULE is not specified for a style, this parameter may assist the server in selecting a more appropriate representative graphic by eliminating internal rules that are out-of-scope. This value is a standardized scale denominator, defined in Section 10.2. Specifying the scale will also make the symbolizers using Unit Of Measure resize according to the specified scale.
     * @param [options.sdl=undefined] - todo implement Optional This parameter specifies a reference to an external SLD document. It works in the same way as the SLD= parameter of the WMS GetMap operation.
     * @param [options.sdl_body=undefined] - todo implement Optional This parameter allows an SLD document to be included directly in an HTTP-GET request. It works in the same way as the SLD_BODY= parameter of the WMS GetMap operation.
     * @param [options.format=image/png] - Required This gives the MIME type of the file format in which to return the legend graphic. Allowed values are the same as for the FORMAT= parameter of the WMS GetMap request.
     * @param [options.width=20] - Optional This gives a hint for the width of the returned graphic in pixels. Vector-graphics can use this value as a hint for the level of detail to include.
     * @param [options.height=20] - Optional This gives a hint for the height of the returned graphic in pixels.
     * @param [options.exceptions='application/json'] - Optional This gives the MIME type of the format in which to return exceptions. Allowed values are the same as for the EXCEPTIONS= parameter of the WMS GetMap request.
     * @param [options.language=undefined] - Allows setting labels language for style titles and rules titles; needs a correctly localized SLD to work properly; if labels are not available in the requested language, the default text will be used; look at i18N in SLD for further details.
     * @param [options.legend_options=undefined] - {@link https://docs.geoserver.org/master/en/user/services/wms/get_legend_graphic/index.html#controlling-legend-appearance-with-legend-options}
     * @return {String|Promise<Blob>|Promise<String>} - defulet promis Base64 data string if url is requested synchronise return is fast otherwise promise is returned
     */
    //        $('#legend').append("<img src="+lyrurl+"SERVICE=wms&REQUEST=GetLegendGraphic&FORMAT=image/jpeg&WIDTH=20&HEIGHT=20&LEGEND_OPTIONS=forceLabels:on&LAYER="+lyrname+"&SCALE="+scale+"><br>");
    getLegendGraphic(layer, options, signal) {

        options = options || {};
        let type = options.return || 'base64';
        delete options.return;//so that its on in the params
//forceLabels:off;layout:horizontal;
        let params = {
            service: this.service,
            request: 'GetLegendGraphic',
            version: '1.0.0',
            width: 20,
            height: 20,
            format: 'image/png',
            exceptions: 'application/json',
        }
        Object.assign(params, options);
        if (typeof layer == 'string') {
            params.layer = layer;
        } else {
            params.layer = layer.name;
        }


        let url = this.url + new URLSearchParams(params).toString();

        if (type == 'url') {
            return url;
        }

        return new Promise(async (resolve, reject) => {

            let response = await fetch(url, {
                method: 'GET',
                credentials: 'same-origin', // include, *same-origin, omit
                signal,
            });

            if (!response.ok) {
                console.error(`Response Not OK: ${response.statusText}`);
                reject(`Response Not OK: ${response.statusText}`);
            }
            // const imageUrl = "https://.../image.jpg";


            let blob = await response.blob();
            if (type == 'blob') {
                resolve(blob);
            }


            let base64 = await bs.blobToDataURL(blob);

            resolve(base64);

        })


    }

}

//TODO SLD
//https://github.com/ianturton/ShapefileViewer


/**
 * Class representing Layer/Layer Group based on WMS getCapibilities response this is a tree like structure
 * with each layer having a parent and possibly child layers if you wish to use internal variables they
 * will closely fallow the xml structure you can look at section 7.2.4 of the spec http://portal.opengeospatial.org/files/?artifact_id=14416
 */
class WMSLayer {

    /**
     * Construct a Layer/Layer Group based a <Layer> tag from a WPS 1.3.0 getCapabilities
     * @param xml DomNode of a <Layer> tag after being parsed by DOMParser
     * @param parent Optional Parent Layer(user internal to recursively construct child layers and reference the parent)
     */
    constructor(xml, parent, wms) {
        this.xml = xml;
        this.parent = parent;
        this.wms = wms;

        //default attributes if not defined
        this.queryable = 0;
        this.requestable = 0;
        this.cascaded = 0;
        this.opaque = 0;
        this.noSubsets = 0;
        this.fixedWidth = 0;
        this.fixedHeight = 0;
        //override attributes to layer if specified
        xml.getAttributeNames().forEach(a => {
            let value = xml.getAttribute(a)
            if (value == 'true') {
                this[a] = 1;
            } else if (value != 0 && value != '0') {
                this[a] = parseInt(value);
            }
        });

        //for each child of the layer handle the tag properly according to wms 1.3 spec
        for (let i = 0; i < xml.children.length; i++) {
            let xmlNode = xml.children[i];
            switch (xmlNode.tagName.toString()) {
                case 'Layer': {
                    if (typeof this.layers == "undefined") this.layers = [];
                    this.layers.push(new WMSLayer(xmlNode, this));
                    break;
                }
                case 'Name': {
                    this.name = xmlNode.innerHTML;
                    if (typeof this.name != "undefined") {
                        this.requestable = 1;
                    }
                    break;
                }
                case 'Title': {
                    this.title = xmlNode.innerHTML;
                    break;
                }
                case 'Abstract': {
                    this.abstract = xmlNode.innerHTML;
                    break;
                }
                case 'KeywordList': {
                    this.keywordList = [];
                    for (let j = 0; j < xmlNode.children.length; j++) {
                        this.keywordList.push(xmlNode.children[j].innerHTML);
                    }
                    break;
                }
                case 'Style': {
                    if (typeof this.styles == "undefined") this.styles = [];
                    this.styles.push({
                        name: xmlNode.getElementsByTagName('Name')[0].innerHTML,
                        title: xmlNode.getElementsByTagName('Title')[0].innerHTML,
                        xml: xmlNode
                    });
                    break;
                }
                case 'CRS':
                case 'SRS': {
                    if (typeof this.crss == "undefined") this.crss = [];
                    let crs = xmlNode.innerHTML
                    //todo look into duplacite crs if it matters
                    this.crss.push(crs);
                    break;
                }
                case 'EX_GeographicBoundingBox': {
                    if (typeof this.boundingBoxes == "undefined") this.boundingBoxes = [];
                    let crs = 'CRS:84';
                    let bbox = new WMSBoundingBox(crs,
                        xmlNode.getElementsByTagName('westBoundLongitude')[0].innerHTML, xmlNode.getElementsByTagName('southBoundLatitude')[0].innerHTML,
                        xmlNode.getElementsByTagName('eastBoundLongitude')[0].innerHTML, xmlNode.getElementsByTagName('northBoundLatitude')[0].innerHTML,
                    );
                    this.boundingBoxes.push(bbox);
                    break;
                }
                case 'LatLonBoundingBox': {
                    if (typeof this.boundingBoxes == "undefined") this.boundingBoxes = [];
                    let crs = 'EPSG:4326';
                    let bbox = new WMSBoundingBox(crs,
                        xmlNode.getAttribute('minx'), xmlNode.getAttribute('miny'),
                        xmlNode.getAttribute('maxx'), xmlNode.getAttribute('maxy'),
                    );//todo resx and resy support if nessasary
                    this.boundingBoxes.push(bbox);
                    break;
                }
                case 'BoundingBox': {
                    if (typeof this.boundingBoxes == "undefined") this.boundingBoxes = [];
                    let crs = xmlNode.getAttribute('CRS') || xmlNode.getAttribute('SRS');
                    let bbox = new WMSBoundingBox(crs,
                        xmlNode.getAttribute('minx'), xmlNode.getAttribute('miny'),
                        xmlNode.getAttribute('maxx'), xmlNode.getAttribute('maxy'),
                    );//todo resx and resy support if nessasary
                    this.boundingBoxes.push(bbox);
                    break;
                }
                case 'Dimension': {
                    if (typeof this.dimensions == "undefined") this.dimensions = [];
                    this.dimensions.push(xmlNode);
                    break;
                }
                case 'Attribution': {
                    this.attribution = xmlNode;
                    break;
                }
                case 'AuthorityURL': {
                    if (typeof this.authorityURLs == "undefined") this.authorityURLs = [];
                    this.authorityURLs.push(xmlNode);
                    break;
                }
                case 'Identifier': {
                    if (typeof this.identifiers == "undefined") this.identifiers = [];
                    this.identifiers.push(xmlNode);
                    break;
                }
                case 'MetadataURL': {
                    if (typeof this.metadataURLs == "undefined") this.metadataURLs = [];
                    this.metadataURLs.push(xmlNode);
                    break;
                }
                case 'DataURL': {
                    this.dataURL = xmlNode;
                    break;
                }
                case 'FeatureListURL': {
                    this.featureListURL = xmlNode;
                    break;
                }
                case 'MinScaleDenominator': {/* EXAMPLE  A scale denominator value of “10000000” means a scale of1:10 million. Scientific notation is also allowed, so a more compact value of “10e6” could also be used for the scale denominator. */
                    this.minScaleDenominator = parseFloat(xmlNode.innerHTML);
                    break;
                }
                case 'MaxScaleDenominator': {
                    this.maxScaleDenominator = parseFloat(xmlNode.innerHTML);
                    break;
                }
                case 'ScaleHint': {
                    this.minScaleDenominator = xmlNode.getAttribute('min');
                    this.maxScaleDenominator = xmlNode.getAttribute('max');
                    break;

                }
                default: {
                    console.warn(`Warrning Unknown xml tag in wms capability Layer: ${xmlNode.tagName.toString()}`);
                }
            }
        }

        // this.requestable = (typeof this.name != 'undefined')
    }

    /**
     * Whether or not the layer contains children layers.
     * @returns {boolean|boolean} True if layer has children.
     */
    isGroup() {
        return (typeof this.layers != "undefined" && this.layers.length > 0);
    }

    /**
     * Whether or not the layer can be requested with a GetMap Wms cal.
     * @returns {boolean} True if layer is requestable.
     */
    isRequestable() {
        return this.requestable;
    }

    /**
     * The Boolean attribute queryable indicates whether the server supports the GetFeatureInfo
     * operation on that Layer. A server may support GetFeatureInfo on some of its layers, but need
     * not support it on all layers. A server shall issue a service exception (code="LayerNotQueryable")
     * if GetFeatureInfo is requested on a Layer that is not queryable.
     * @returns {number} 0 if false 1 if true
     */
    isQueryable() {
        return this.queryable
    }

    /**
     * A Layer is said to have been “cascaded” if it was obtained from an originating server and then
     * included in the service metadata of a different server. The second server may simply offer an
     * additional access point for the Layer, or may add value by offering additional output formats or
     * reprojection to other coordinate reference systems.
     * @returns {number}
     *  0:layer has not been retransmitted by a Cascading Map Server.
     *  n: layer has been retransmitted ntimes.
     */
    isCascaded() {
        return this.cascaded
    }

    /**
     * Wether Or not the layer Fills the area A true value for opaque indicates that the Layer
     * represents an area-filling coverage. For example, a map that represents to pography and
     * bathymetry as regions of differing colours will have no transparent areas. The opaquedeclaration
     * should be taken as a hint to the client to place such a Layer at the bottom of a stack of maps.
     * @returns {number}
     *  0, false: map data represents vector features that probably do not completely fill space.
     *  1, true: map data are mostly or completely opaque.
     */
    isOpaque() {
        return this.opaque
    }

    /**
     *
     * @returns {number}
     *  0, false: WMS can map a subset of the full bounding box.
     *  1, true: WMS can only map the entire bounding box.
     */
    isNoSubsets() {
        return this.noSubsets
    }

    /**
     * @returns {number}
     * 0: WMS n produce map of arbitrary width.
     * nonzero: value is fixed map width that cannot be changed by the WMS.
     */
    isFixedWidth() {
        return this.fixedWidth
    }

    /**
     * @returns {number}
     * 0: WMS n produce map of arbitrary height.
     * nonzero: value is fixed map height that cannot be changed by the WMS.
     */
    isFixedHeight() {
        return this.fixedHeight
    }

    /**
     * Get Child layers of a layer group with options to filter
     * @param options {Object} Options for how you want to get/filter layers
     * @param options.recursive {boolean} [false] - if true getLayers will dive down recursively into layer groups
     * @param options.queryable {boolean} [false] - if true result will be filtered to only queryable layers
     * @param options.requestable {boolean} [false] - if true result will be filtered to only requestable layers
     * @returns {Array|undefined} - returns undefined if there are no child layers otherwise an array of all layers note if
     *  there are layers but they are filtered out you will get an empty array '[]'
     */
    getLayers(options = {}) {
        if (typeof this.layers == "undefined" || this.layers.length == 0) {
            return undefined;
        }
        let layers = this.layers;

        if (options.recursive) {
            layers.forEach(l => {

            })
        }

        if (options.queryable) {
            layers = layers.filter(l => l.isQueryable());
        }
        if (options.requestable) {
            layers = layers.filter(l => l.isRequestable());
        }
        return layers;
    }

    /**
     * Returns the L.latLngBounds for this layer if geoserver gave a latlon bounding box!
     * @return {*|LatLngBounds|R} Returns the Leaflet Bounds for a layer. L.latLngBounds
     */
    getLatLonBounds() {
        let bbox = this.boundingBoxes.find(b => b.crs == 'EPSG:4326');
        if (!bbox) {
            console.warn("Unable to finde latlonbounding box try using wms 1.1.1 or make sure that geoserver outputs a bbox in CRS.EPSG4326 (latlon)")
        } else {
            let corner1 = L.latLng(bbox.maxy, bbox.maxx);
            let corner2 = L.latLng(bbox.miny, bbox.minx);
            let bounds = L.latLngBounds(corner1, corner2);
            return bounds

        }
    }

    /**
     * Zooms the supplied Leaflet map to this layer if possible.
     * @param map
     */
    zoomMapTo(map) {
        let bounds = this.getLatLonBounds();
        map.fitBounds(bounds);
    }


}


class WMSBoundingBox {

    constructor(crs, minx, miny, maxx, maxy) {
        this.crs = crs;
        this.minx = minx;
        this.miny = miny;
        this.maxx = maxx;
        this.maxy = maxy;
    }

}
