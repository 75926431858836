class SLD {
    getGeoserverStyles(g) {


    }

}

// https://docs.geoserver.org/stable/en/user/rest/
class GeoserverREST {
    /**
     * Create a geoserver rest object
     * @param url the geoserver rest url ie https://geodev.lorrnel.com:8084/geoserver/rest
     */
    constructor(url) {
        this.url = url;
    }

    async getStyles() {

        let response = await fetch(this.url + '/styles', {
                method: 'GET',
                headers: {
                    // "Content-Type": "application/json",
                    // Accept: "application/json",
                    // mode: 'no-cors'
                    Accept: "application/vnd.ogc.sld+xml",

                    // credential: "same-origin"
                }
            }
        )

        let json = await response.json();
        return json;

    }

    async getStyle(name) {
        let response = await fetch(this.url + '/styles/' + name + '.json', {
                method: 'GET',
                headers: {
                    // "Content-Type": "application/json",
                    // Accept: "application/json",
                    Accept: "application/vnd.ogc.sld+xml",
                    // mode: 'no-cors'
                    // credential: "same-origin"
                }
            }
        )

        let json = await response.json();
        return json;

    }

    async postReload() {

        let response = await fetch(this.url + '/reload', {
                method: 'post',
                headers: {
                    // "Content-Type": "application/json",
                    Accept: "application/json",
                    mode: 'no-cors'
                    // credential: "same-origin"
                }
            }
        )

        let json = await response.json();
        return json;

    }


}


// let geodev = new GeoserverREST('https://geodev.lorrnel.com:8084/geoserver/rest');
