class GeojsonGrid extends Grid {

    /**
     * Takes a layer manager and makes a grid out of it using layers.
     * @param geojson - the geojson data
     * @param layer - layer config see layermanager addLayer for docs
     * @param options - Grid options
     */
    constructor(geojson, layer, options) {

        let defaults = {
            containerType: 'jspanel',
            jspanelOptions: {
                panelSize: {
                    width: 500,
                    height: 400,
                },
                position: {
                    my: 'center',
                    at: 'bottom-center',
                    offsetX: 5,
                    offsetY: 5
                },

                headerTitle: 'Geojson Grid: '+layer.niceName,

            },

            gridOptions: {
                // enableAddRow: true,
                showHeaderRow: false,
                autoHeight: false,
                height: 250,

            }

        }

        options = bs.mergeDeep(defaults, options);


        //this initializes the object with grid defaults our defaults should take priority but doesn't initialize the grid yet
        super([], [], options, false);
        //now 'this' can be used

        this.geojson = geojson;
        this.layer = layer;

        this.mapLayer = L.geoJson(geojson, {
            // http://leafletjs.com/reference.html#geojson-style
            //this is applied to points
            pointToLayer: function (feature, latlng) {
                //Initial style: radius: size of circle, weight: thickness of outline, color: color of outline (black so easy to see), fillColor: color inside
                return L.circleMarker(latlng, {
                    radius: 12,
                    color: "#b3b300",
                    weight: 5,
                    opacity: 1,
                    fillColor: "#ffff99",
                    fillOpacity: 0.4,
                    interactive: false,
                    clickable: true //just guessing here

                });
            },
            //this is applied to polylines and polygons

            style: function (feature) {
                return {
                    color: "#b3b300",
                    weight: 5
                };
            }
        });

        // this.mapLayer.addTo(map);

        this.data = geojson.features.map(f=>{
            let ret = f.properties
            ret.id = f.id;
            ret.feature = f;
            return ret
        })
        this.columns = [];

        Object.keys(this.data[0]).forEach(key=>{
            if(key == 'feature') return;

            let column = {id: key, name: bs.niceName(key), field: key, width: 75,  resizable: true,
                selectable: false};
            this.columns.push(column);

        })

        // this.layerManager = layermanager;

        // this.options.jspanelOptions.callback = (panel) => {
        //     // handler for the icons
        //
        //     // let addRow = panel.headertoolbar.querySelector('span[name=addRow]');
        //     // let configSelect = panel.headertoolbar.querySelector('select[name=configSelect]');
        //     console.log("jspanal callback", panel)
        //     // addRow.addEventListener('click', (e) => {
        //     //     console.log('Add Row: ', e.target.value);
        //     //     this.addRow();
        //     // });
        //
        // }


        //no this.data and this.comumns should be set so we can init;
        this.sortcol = "id";
        this.sortdir = 1;
        //Initalize the grid
        this.init();

        this.addGenericTools();
        this.addInfoAction();

    }


    /**
     * We have to do it this way so the formatters have access to 'this' since js class objects are just normal functions :/
     */
    initFormatters() {


        // this.actionFormatter = (row, cell, value, columnDef, dataContext) => {
        //
        //     let html = ``;
        //     html += `<button onclick="_globalGrids[${this._id}]._info('${dataContext.id}')" class="slick-icon-btn btn btn-warning btn-sm p-0 m-0" title="Delete Row">
        //                     <i class="fa fa-cog" aria-hidden="true"></i>
        //                  </button>`
        //     html += `<button onclick="_globalGrids[${this._id}]._table('${dataContext.id}')" class="slick-icon-btn btn btn-info btn-sm p-0 m-0  " title="Attribute Table">
        //                     <i class="fa fa-th-list" aria-hidden="true"></i>
        //                  </button>`
        //     html += `<button onclick="_globalGrids[${this._id}]._zoom('${dataContext.id}')" class="slick-icon-btn btn btn-primary btn-sm p-0 m-0  " title="Zoom To">
        //                     <i class="fa fa-search-plus" aria-hidden="true"></i>
        //                  </button>`
        //
        //     return html;
        // };

        this.layerFormatter = (row, cell, value, columnDef, dataContext) => {


            let html = ``;
            // html += `<button onclick="_globalGrids[${this._id}]._zoom('${dataContext.id}')" class="slick-icon-btn btn btn-danger btn-sm p-1 m-1  " title="Delete Row">
            //                 <i class="fa fa-zoom" aria-hidden="true"></i>
            //              </button>`
            // html += `<button onclick="_globalGrids[${this._id}]._zoom('${dataContext.id}')" class="slick-icon-btn btn btn-info btn-sm p-1 m-1  " title="Delete Row">
            //                 <i class="fa fa-zoom" aria-hidden="true"></i>
            //              </button>`
            // html += `<button onclick="_globalGrids[${this._id}]._zoom('${dataContext.id}')" class="slick-icon-btn btn btn-success btn-sm p-1 m-1  " title="Delete Row">
            //                 <i class="fa fa-zoom" aria-hidden="true"></i>
            //              </button>`

            html += `<span style="font-size: small">${dataContext.niceName}</span>`


            // html += `<button onclick="_globalGrids[${this._id}].rowEdit('${dataContext.id}')" class="slick-icon-btn btn btn-success btn-sm p-1 m-1 float-right" title="Edit Row Prompt">
            //                         <i class="fa fa-edit" aria-hidden="true"></i>
            //                      </button>`
            // html += `<button onclick="_globalGrids[${this._id}].jsonEdit('${dataContext.id}')" class="slick-icon-btn btn btn-success btn-sm p-1 m-1 float-right" title="Edit Row JSON">
            //                         <i class="fa fa-outdent" aria-hidden="true"></i>
            //                      </button>`

            return html;
        };
    }

    initEvents() {

    }

    groupByLayer() {

        this.dataView.setGrouping({
            getter: 'group',
            formatter: (g) => {

                let currentFile = g.rows[0];
                console.log(g);
                let html = ``;
                html += `<span class="slick-group-select-checkbox unchecked"></span>`
                html += ` ${currentFile['group']}  <span style='color:green'>(${g.count} Items)</span>`


                // html += `<button onclick="_globalGrids[${this._id}].viewFile('${currentFile.id}', '${currentFile.file_name}')" class="slick-icon-btn btn btn-success btn-sm float-right" title="View Latest Version">
                //                     <i class="fa fa-eye" aria-hidden="true"></i>
                //                  </button>`


                // html += `<button class='btn btn-warning btn-sm m-0 ml-4 float-right' onclick="deleteByVersionId('${g.value}')">
                //             Delete All Versions
                //          </button>`

                return html;
            },
            // aggregators: [
            //     new Slick.Data.Aggregators.Avg("percentComplete"),
            //     new Slick.Data.Aggregators.Sum("cost")
            // ],
            aggregateCollapsed: false,
            lazyTotalsCalculation: true
        });


    }

    _onRowSelect(event, data) {

        let rows = data.rows;
        let prev = data.previousSelectedRows;
        let grid = data.grid;
        console.log('event', event)
        console.log('data', data)
        // console.log('grid.getData', grid.getData())
        let on = rows.filter(r => !prev.includes(r));
        let off = prev.filter(r => !rows.includes(r));
        console.log(on);
        console.log(off);

        let dataView = data.grid.getData();
        on.forEach(id => {
            let layer = dataView.getItem(id)
            console.log('layer', layer, id);
            layer.mapLayer.addTo(map)
        })

        off.forEach(id => {
            let layer = dataView.getItem(id)
            console.log('layer', layer, id);
            layer.mapLayer.removeFrom(map)
        })


    }


    rowEdit(id) {
        let row = this.data.find((r) => r.id == id);
        let name = row.nice_name || row.name || row.label || row.id;
        bs.prompt('Edit:' + name, this.template, row).then(data => {
            // console.log("id roe data",id,row,data);
            bs.sendJsonAndNotify('post', this.url + '/update', {row: data, key: 'id', value: id}).then(res => {
                console.log(res);
                if (res.success) this.dataView.updateItem(id, res.data[0]);//todo; maybe update all returned rows from and update request
            })
        })

    }


//
// options:
//  id: the id to use for the checkbox input
//  inputClass: optional additional classes for the input tag ie 'my-input'
//  labelClass: optional additional classes for the label tag ie:'my-label mb3'
//  checked: bool, wether to cheack the box by deafult
    /**
     * Creates a checkbox and appends it to container
     * @param labelText - the label text
     * @param container - the container to append it to
     * @param options optional options
     * @param options.id: the id to use for the checkbox input
     * @param options.inputClass: optional additional classes for the input tag ie 'my-input'
     * @param options.labelClass - optional additional classes for the label tag ie:'my-label mb3'
     * @param options.checked - bool, wether to cheack the box by deafult
     * @return {{input: any, label: any, id: (*|string)}}
     */
    _createCheckbox(labelText, container, options) {

        if (!options) {
            options = {}
        }
        let id = (typeof options.id != "undefined") ? options.id : 'bsutilcheck_' + this.id + '_' + (this.nextcheckid++).toString();
        let inputClass = (typeof options.inputClass != "undefined") ? options.inputClass : '';
        let labelClass = (typeof options.labelClass != "undefined") ? options.labelClass : '';
        let checked = (typeof options.checked != "undefined") ? options.checked : false;


        let label = L.DomUtil.create('label', labelClass, container);
        label.setAttribute('for', id);

        let input = L.DomUtil.create('input', inputClass, label);
        input.id = id;
        input.type = 'checkbox';
        input.checked = checked;

        let span = L.DomUtil.create('span', '', label);
        span.innerText = labelText;

        return {label: label, input: input, id: id};

    }

    /**
     * A function to zoom to a layer
     * @param id - dataview id
     * @private
     */
    _zoom(id) {
        let layer = this.dataView.getItemById(id);
        console.log('zoom,', id);

        let wmslayer = this.layerManager.sourceManager.getWMSLayer(layer.source, layer.workspace, layer.layer)
        console.log(wmslayer);
        wmslayer.zoomMapTo(this.layerManager.map);
    }

    /**
     * A function to initialize the attribute table
     * @param id - dataview id
     * @private
     */
    _table(id) {
        let layer = this.dataView.getItemById(id);
        console.log('table,', id);
    }

    /**
     * A function to initialize the info popup for a layer
     * @param id - dataview id
     * @private
     */
    _info(id) {
        let layer = this.dataView.getItemById(id);
        let wmslayer = this.layerManager.sourceManager.getWMSLayer(layer.source, layer.workspace, layer.layer)

        let html = ''
        html += 'abstract:' + wmslayer.abstract;
        html += '<br>tooltip:' + layer.tooltip;

        html += '<br>real:' + ( layer.tooltip || wmslayer.abstract )



        console.log('info,', id);
        let options = {
            headerTitle: 'Layer Info: ' + layer.niceName,
            theme:  bs.getCssVar("--panel-theme", true),
            borderRadius: '0.5rem',
            panelSize: {
                width: 300,
                height: 400,
            },
            content: html,
            // callback: function () {
            //     // this.content.style.padding = '10px';
            //     console.log("callback");
            // },
            // onclosed: () => {
            //     console.log("Closed Help");
            //     help.panal = undefined;
            // },
            dragit: {
                containment: 0,
                snap: {
                    sensitivity: 70,
                    trigger: 'panel',
                    active: 'both',
                },
            },

        }
        let panel = jsPanel.create(options)

    }
}