var icons =
    {

        autosyncOn: `
            <span class="fa-stack " style='font-size:64%;'">
              <i class="fas fa-sync-alt fa-stack-2x fa-spin"></i>
              <i class="fas fa-check"></i>
            </span>
        `,
        autosyncOff: `
            <span class="fa-stack " style='font-size:64%;'>
              <i class="fas fa-sync-alt fa-stack-2x"></i>
              <i class="fas fa-slash fa-stack-2x"></i>
            </span>
        `,

        syncMap: `
            <span class="fa-stack " style='font-size:64%;'>
              <i class="fas fa-sync-alt fa-stack-2x"></i>
              <i class="fas fa-globe-americas fa-stack-1x"></i>
            </span>
        `,

        syncAll: `
            <span class="fa-stack " style = 'font-size:64%;'>
              <i class="fas fa-sync-alt fa-stack-2x"></i>
              <i class="fas fa-database fa-stack-1x"></i>
            </span>
        `,

        refresh: `
             <i class="fas fa-sync-alt"></i>
        `,
        save: `
            <i class="fas fa-save"></i>
        `,
        load: `
            <i class="fas fa-load"></i>
        `,
            filter: `
            <i class="fas fa-filter"></i>
        `,
             filterClear: `
                   <span class="fa-stack " style='font-size:64%;'>
              <i class="fas fa-filter fa-stack-2x"></i>
              <i class="fas fa-slash fa-stack-2x"></i>
            </span>
        `,
        notification: `
            <i class="fas fa-bell"></i>
        `,
            notificationOff: `
            <i class="fas fa-bell-slash"></i>
        `,
        view: `
            <i class="fas fa-eye"></i>
        `,
        delete: `
            <i class="fa fa-trash"></i>
        `,
        edit: `
            <i class="fa fa-edit"></i>
        `,
        pm_state: `
            <i class="fas fa-save"></i>  
        `,
        reSave: `
            <span class="fa-stack " style='font-size:64%;'>
              <i class="fas fa-sync-alt fa-stack-2x"></i>
              <i class="fas fa-save fa-stack-1x"></i>
            </span>
        `,
        help: `
            <i class="fas fa-info"></i>  
        `
    }
