class LayerGrid extends Grid {

    /**
     * Takes a layer manager and makes a grid out of it using layers.
     * @param layermanager
     * @param options - Grid options
     */
    constructor(layermanager, options) {

        let defaults = {
            containerType: 'jspanel',
            jspanelOptions: {
                panelSize: {
                    width: bs.getGoodWidth(350, 50),
                    height: 500,
                },
                position: {
                    my: 'right-bottom',
                    at: 'right-bottom',
                    offsetX: 5,
                    offsetY: 5
                },

                headerTitle: 'Layer Panel',

            },
            initPanel: false,
            gridOptions: {
                // enableAddRow: true,
                showHeaderRow: false,
                autosizeColsMode: Slick.GridAutosizeColsMode.LegacyForceFit,
                // autosizeColsMode: Slick.GridAutosizeColsMode.FitViewportToCols,

                multiColumnSort: true,
                numberedMultiColumnSort: true,
            }

        }

        options = bs.mergeDeep(defaults, options);


        //this initializes the object with grid defaults our defaults should take priority but doesn't initialize the grid yet
        super([], [], options, false);
        //now 'this' can be used

        var checkboxSelector = new Slick.CheckboxSelectColumn({
            // cssClass: "slick-cell-checkboxsel",
            // selectableOverride: this._selectableOverride,
            hideInFilterHeaderRow: false,
        });
        this.checkboxSelector = checkboxSelector;
        let check_col = checkboxSelector.getColumnDefinition()
        check_col.maxWidth = 18;
        check_col.width = 18;
        this.columns = [
            check_col,
            {
                id: 'actions',
                name: 'Actions',
                field: 'id',
                formatter: this.actionFormatter,
                minWidth: 70,
                width:70,
                maxWidth: 70,
                selectable: false,
            },

            // {id: "sel", name: "#", field: "zindex", cssClass: "cell-selection", width: 40, resizable: false, selectable: false, focusable: false },
            {
                id: 'layers',
                name: 'Layers',
                field: 'id',
                formatter: this.layerFormatter,
                minWidth: 150,
                selectable: false,
            },
        ]
        this.layerManager = layermanager;
        this.data = layermanager.getAllLayers();



        delete this.groupItemMetadataProvider
        this.groupItemMetadataProvider = new Slick.Data.GroupItemMetadataProvider({
            checkboxSelect: true,
            checkboxSelectPlugin: checkboxSelector
        });

        //no this.data and this.comumns should be set so we can init;
        this.sortcol = "id";
        this.sortdir = 1;
        //Initalize the grid
        this.init();

        this.addGenericTools();

        this.grid.registerPlugin(checkboxSelector);
        this.grid.onSelectedRowsChanged.subscribe(this._onRowSelect)

        // this.initPanel();
        this.groupByLayer();
        // this.grid.invalidateAllRows();

        // this.dataView.refresh();
        // this.initResizer();
        // this.resize()
        // this.grid.setSelectionModel(new Slick.RowSelectionModel());
        // this.grid.init();

        // this.initPanel();

        // this.bsutil = new BSUtil('dbgrid' + this.id);
    }


    /**
     * We have to do it this way so the formatters have access to 'this' since js class objects are just normal functions :/
     */
    initFormatters() {

        this.actionFormatter = (row, cell, value, columnDef, dataContext) => {

            let html = ``;
            html += `<button onclick="_globalGrids[${this._id}]._info('${dataContext.id}')" class="slick-icon-btn btn btn-warning btn-sm p-0 m-0" title="Delete Row">
                            <i class="fa fa-cog" aria-hidden="true"></i>
                         </button>`
            html += `<button onclick="_globalGrids[${this._id}]._table('${dataContext.id}')" class="slick-icon-btn btn btn-info btn-sm p-0 m-0  " title="Attribute Table">
                            <i class="fa fa-th-list" aria-hidden="true"></i>
                         </button>`
            html += `<button onclick="_globalGrids[${this._id}]._zoom('${dataContext.id}')" class="slick-icon-btn btn btn-primary btn-sm p-0 m-0  " title="Zoom To">
                            <i class="fa fa-search-plus" aria-hidden="true"></i>
                         </button>`

            return html;
        };

        this.layerFormatter = (row, cell, value, columnDef, dataContext) => {
            let html = ``;
            // html += `<button onclick="_globalGrids[${this._id}]._zoom('${dataContext.id}')" class="slick-icon-btn btn btn-danger btn-sm p-1 m-1  " title="Delete Row">
            //                 <i class="fa fa-zoom" aria-hidden="true"></i>
            //              </button>`
            // html += `<button onclick="_globalGrids[${this._id}]._zoom('${dataContext.id}')" class="slick-icon-btn btn btn-info btn-sm p-1 m-1  " title="Delete Row">
            //                 <i class="fa fa-zoom" aria-hidden="true"></i>
            //              </button>`
            // html += `<button onclick="_globalGrids[${this._id}]._zoom('${dataContext.id}')" class="slick-icon-btn btn btn-success btn-sm p-1 m-1  " title="Delete Row">
            //                 <i class="fa fa-zoom" aria-hidden="true"></i>
            //              </button>`


            let source = this.layerManager.sourceManager.getSource(dataContext.source)
            let url = source.wms.getLegendGraphic(dataContext.workspace + ':' + dataContext.layer, {
                return: 'url',
                // legend_options: 'forceTitles:off;forceLabels:off;hideEmptyRules:true;'
                legend_options: 'forceTitles:off;forceLabels:off;layout:horizontal;groupLayout:horizontal;hideEmptyRules:true;'

            })
            //data-toggle="tooltip" data-html="true" title="<img style='width: 100%; height: 100%;object-fit: contain;' src='${url}'>
            let id = 'lg_item_'+bs.id++;
            html = `
                <div class="layer_grid_row" id="${id}">
         
                     <span>${dataContext.niceName} </span>
                     <img style="opacity:0.12" width: 100%; height: 100%;object-fit: contain;" src="${url}">
                </div>
            `;

            $('#'+id).on('hover')
            //for input
            //  <input type="range" id="cowbell" name="cowbell" min="0" max="100" value="90" step="10" style="
                //     position: absolute;
                // ">
            //

            // html += `<span style="font-size: smaller">${dataContext.niceName}</span>`


            // html += `<button onclick="_globalGrids[${this._id}].rowEdit('${dataContext.id}')" class="slick-icon-btn btn btn-success btn-sm p-1 m-1 float-right" title="Edit Row Prompt">
            //                         <i class="fa fa-edit" aria-hidden="true"></i>
            //                      </button>`
            // html += `<button onclick="_globalGrids[${this._id}].jsonEdit('${dataContext.id}')" class="slick-icon-btn btn btn-success btn-sm p-1 m-1 float-right" title="Edit Row JSON">
            //                         <i class="fa fa-outdent" aria-hidden="true"></i>
            //                      </button>`

            return html;

        };
    }

    initEvents() {

    }

    groupByLayer() {

        this.dataView.setGrouping({
            getter: 'group',
            formatter: (g) => {

                let currentFile = g.rows[0];
                console.log(g);
                let html = ``;
                html += `<span class="slick-group-select-checkbox unchecked"></span>`
                html += ` ${currentFile['group']}  <span style='color:green'>(${g.count} Items)</span>`


                // html += `<button onclick="_globalGrids[${this._id}].viewFile('${currentFile.id}', '${currentFile.file_name}')" class="slick-icon-btn btn btn-success btn-sm float-right" title="View Latest Version">
                //                     <i class="fa fa-eye" aria-hidden="true"></i>
                //                  </button>`


                // html += `<button class='btn btn-warning btn-sm m-0 ml-4 float-right' onclick="deleteByVersionId('${g.value}')">
                //             Delete All Versions
                //          </button>`

                return html;
            },
            // aggregators: [
            //     new Slick.Data.Aggregators.Avg("percentComplete"),
            //     new Slick.Data.Aggregators.Sum("cost")
            // ],
            aggregateCollapsed: false,
            lazyTotalsCalculation: true
        });


    }

    _onRowSelect(event, data) {

        let rows = data.rows;
        let prev = data.previousSelectedRows;
        let grid = data.grid;
        console.log('event', event)
        console.log('data', data)
        // console.log('grid.getData', grid.getData())
        let on = rows.filter(r => !prev.includes(r));
        let off = prev.filter(r => !rows.includes(r));
        console.log(on);
        console.log(off);

        let dataView = data.grid.getData();
        on.forEach(id => {
            let layer = dataView.getItem(id)
            console.log('layer', layer, id);
            layer.mapLayer.addTo(map)
        })

        off.forEach(id => {
            let layer = dataView.getItem(id)
            console.log('layer', layer, id);
            layer.mapLayer.removeFrom(map)
        })


    }


    rowEdit(id) {
        let row = this.data.find((r) => r.id == id);
        let name = row.nice_name || row.name || row.label || row.id;
        bs.prompt('Edit:' + name, this.template, row).then(data => {
            // console.log("id roe data",id,row,data);
            bs.sendJsonAndNotify('post', this.url + '/update', {row: data, key: 'id', value: id}).then(res => {
                console.log(res);
                if (res.success) this.dataView.updateItem(id, res.data[0]);//todo; maybe update all returned rows from and update request
            })
        })

    }


//
// options:
//  id: the id to use for the checkbox input
//  inputClass: optional additional classes for the input tag ie 'my-input'
//  labelClass: optional additional classes for the label tag ie:'my-label mb3'
//  checked: bool, wether to cheack the box by deafult
    /**
     * Creates a checkbox and appends it to container
     * @param labelText - the label text
     * @param container - the container to append it to
     * @param options optional options
     * @param options.id: the id to use for the checkbox input
     * @param options.inputClass: optional additional classes for the input tag ie 'my-input'
     * @param options.labelClass - optional additional classes for the label tag ie:'my-label mb3'
     * @param options.checked - bool, wether to cheack the box by deafult
     * @return {{input: any, label: any, id: (*|string)}}
     */
    _createCheckbox(labelText, container, options) {

        if (!options) {
            options = {}
        }
        let id = (typeof options.id != "undefined") ? options.id : 'bsutilcheck_' + this.id + '_' + (this.nextcheckid++).toString();
        let inputClass = (typeof options.inputClass != "undefined") ? options.inputClass : '';
        let labelClass = (typeof options.labelClass != "undefined") ? options.labelClass : '';
        let checked = (typeof options.checked != "undefined") ? options.checked : false;


        let label = L.DomUtil.create('label', labelClass, container);
        label.setAttribute('for', id);

        let input = L.DomUtil.create('input', inputClass, label);
        input.id = id;
        input.type = 'checkbox';
        input.checked = checked;

        let span = L.DomUtil.create('span', '', label);
        span.innerText = labelText;

        return {label: label, input: input, id: id};

    }

    /**
     * A function to zoom to a layer
     * @param id - dataview id
     * @private
     */
    _zoom(id) {
        let layer = this.data.find(i => i.id == id)
        console.log('zoom,', id);

        let wmslayer = this.layerManager.sourceManager.getWMSLayer(layer.source, layer.workspace, layer.layer)
        console.log(wmslayer);
        wmslayer.zoomMapTo(this.layerManager.map);
    }

    /**
     * A function to initialize the attribute table
     * @param id - dataview id
     * @private
     */
    _table(id) {
        let layer = this.data.find(i => i.id == id)
        console.log('table,', id);
        topbar.show();
        let geojson = this.layerManager.sourceManager.getSource(layer.source)
            .wfs.getGeoJson(layer.workspace + ":" + layer.layer).then(geojson => {


                let color = bs.getRandomColor()
                /*                let layer = L.geoJson(geojson, {
                                    // http://leafletjs.com/reference.html#geojson-style
                                    //this is applied to points
                                    pointToLayer: function (feature, latlng) {
                                        //Initial style: radius: size of circle, weight: thickness of outline, color: color of outline (black so easy to see), fillColor: color inside
                                        return L.circleMarker(latlng, {
                                            radius: 12,
                                            color: "#b3b300",
                                            weight: 5,
                                            opacity: 1,
                                            fillColor: "#ffff99",
                                            fillOpacity: 0.4,
                                            interactive: false,
                                            clickable: true //just guessing here

                                        });
                                    },
                                    //this is applied to polylines and polygons
                                    style: function (feature) {
                                        return {
                                            color: "#b3b300",
                                            weight: 5
                                        };
                                    }
                                });*/


                console.log(geojson);
                topbar.hide();

                let gg = new GeojsonGrid(geojson, layer, {})
                gg.initPanel();

                return geojson;


            });
    }

    /**
     * A function to initialize the info popup for a layer
     * @param id - dataview id
     * @private
     */
    _info(id) {

        let layer = this.data.find(i => i.id == id)
        let wmslayer = this.layerManager.sourceManager.getWMSLayer(layer.source, layer.workspace, layer.layer)


        let html = ''
        html += 'abstract:' + wmslayer.abstract;
        html += '<br>tooltip:' + layer.tooltip;

        html += '<br>real:' + (layer.tooltip || wmslayer.abstract)


        console.log('info,', id);
        let options = {
            headerTitle: 'Layer Info: ' + layer.niceName,
            theme:  bs.getCssVar("--panel-theme", true),
            borderRadius: '0.5rem',
            panelSize: {
                width: 300,
                height: 400,
            },
            content: html,
            // callback: function () {
            //     // this.content.style.padding = '10px';
            //     console.log("callback");
            // },
            // onclosed: () => {
            //     console.log("Closed Help");
            //     help.panal = undefined;
            // },
            dragit: {
                containment: 0,
                snap: {
                    sensitivity: 70,
                    trigger: 'panel',
                    active: 'both',
                },
            },

        }
        // let panel = jsPanel.create(options)

    }
}
