console.log("DEMO CLIENT CODE");


/**
 * ToDo: move this function somewhere more general as it does not need to be altered to work with different tables.
 * We do NOT actually have to change anything in this function but we have to include this file in the bundle.
 * We ensure that the SOURCE table has a field called xxx_ids which is an array of the ID's from myDestinationTable.
 * The value for myDestinationTable equals the let noun value of the destination table from the table config file.
 *    eg: let noun = 'sites'
 *    eg: formatter: getButtonFilterTableFormatter('myDestinationTable',false,"my custom label")
 * Then  we add the example code to the table config:
 * @example
 *             { // column def to turn ids into filter of foreign table
                name: 'xxx_ids',
                visible: true,
                edit: false,
                columnOptions: {
                    formatter: getButtonFilterTableFormatter('myDestinationTable')
                }
            },
 * @param tableVarName - the global name for the table this filed references
 * @param lazy - if true button will call global function lazy_tableVarName(filter parmas)
 * @param label - override button label text
 * @return {function} - Returns a formatter function for slick grids
 */
function getButtonFilterTableFormatter(tableVarName, lazy, label) {

    return function (row, cell, value, columnDef, dataContext) {
        if (!Array.isArray(value)) {
            try {
                value = JSON.parse(value);
            } catch (e) {
                console.warn("Failed parse JSON to get array from value bad data", e);
            }
        }
        if (!Array.isArray(value)) {

            if (typeof value == 'string' || typeof value == 'number') {
                console.warn("Failed to get array but got a string so using that");
                value = [value];
            } else {
                console.error("Failed to get array from value bad data");
                value = []
                // return;
            }


        }


        let arr = "['" + value.join("', '") + "']"
        arr = bs.sanitizeHtmlString(arr);

        label = label || 'Show in ' + bs.niceName(tableVarName);

        let bg = 'info';
        if(window[tableVarName] && !window[tableVarName].data.some(x=>value.some(y=>y==x.id))) {
            bg = 'danger'
        }

        if (lazy) {
            return `<button class="btn btn-${bg} p-0 m-0 " onclick="window['lazy_${tableVarName}']({id:${arr}});">${label}</button>`
        } else return `<button class="btn btn-${bg} p-0 m-0 " onclick="window['${tableVarName}'].show();window['${tableVarName}'].filterBy({id:${arr}})">${label}</button>`
        // projects.show();
        // projects.filterByID(value);


    }
}

