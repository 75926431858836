//Here we are extending a feature group
L.Photo = L.FeatureGroup.extend({
    options: { // leafelats way of setting defaults
        icon: {
            iconSize: [40, 40]
        }
    },

    initialize: function (photos, options) {
        L.setOptions(this, options);
        L.FeatureGroup.prototype.initialize.call(this, photos);//call parents initialize
    },

    addLayers: function (photos) {
        if (photos) {
            for (var i = 0, len = photos.length; i < len; i++) {
                this.addLayer(photos[i]);
            }
        }
        return this;
    },

    addLayer: function (photo) {
        L.FeatureGroup.prototype.addLayer.call(this, this.createMarker(photo));
    },

    createMarker: function (photo) {
        var marker = L.marker(photo, {
            icon: L.divIcon(L.extend({
                html: '<div style="background-image: url(' + photo.thumbnail + ');"></div>​',
                className: 'leaflet-marker-photo'
            }, photo, this.options.icon)),
            title: photo.caption || ''
        });
        marker.photo = photo;
        return marker;
    }
});

L.photo = function (photos, options) {
    return new L.Photo(photos, options);
};


if (L.MarkerClusterGroup) {//if MarkerClusterGroup is loaded

    L.Photo.Cluster = L.MarkerClusterGroup.extend({
        options: {
            featureGroup: L.photo,
            maxClusterRadius: 100,
            showCoverageOnHover: false,
            iconCreateFunction: function (cluster) {
                return new L.DivIcon(L.extend({
                    className: 'leaflet-marker-photo',
                    html: '<div style="background-image: url(' + cluster.getAllChildMarkers()[0].photo.thumbnail + ');"></div>​<b>' + cluster.getChildCount() + '</b>'
                }, this.icon));
            },
            icon: {
                iconSize: [40, 40]
            }
        },

        initialize: function (options) {
            options = L.Util.setOptions(this, options);
            L.MarkerClusterGroup.prototype.initialize.call(this);
            this._photos = options.featureGroup(null, options);
        },

        add: function (photos) {
            this.addLayer(this._photos.addLayers(photos));
            return this;
        },

        clear: function () {
            this._photos.clearLayers();
            this.clearLayers();
        }

    });

    L.photo.cluster = function (options) {
        return new L.Photo.Cluster(options);
    };

}

//TODO DOCUMENT
//TODO Gather dependencies into lib bundle
class GeoImg {


    constructor(geojson, map, layerManager) {
        console.log("New GeoImg, ", geojson);

        this.geojson = geojson;
        this.map = map;
        this.layerManager = layerManager;

        // this.layer = L.featureGroup();

        let photos = geojson.features.map(feature => {
            // console.log(feature);


            let split = feature.properties.photo.split('/'); // /path/to/filename.jpg => [path, to, filename]
            let filename = split.pop(); // filename.jpg
            let path = split.join('/'); // /path/to


            let dotSplit = filename.split('.');//split foo.bar.jpg => [foo, bar, jpg]
            let extension = dotSplit.pop(); // jpg,
            let name = dotSplit.join('.');// foo.bar

            let orig = bs.routeToURL('/api/geo_img/download' + feature.properties.photo);

            // split[split.length - 1] = 'thumb-' + split[split.length - 1]
            let thumbnail = path + '/thumb/' + filename;

            let med = path + '/med/' + filename;
            let video = false;

            thumbnail = bs.routeToURL('/api/geo_img/thumbnail/' + thumbnail);
            let download = bs.routeToURL('/api/geo_img/download/' + med);
            //TODO: suport multiple video formats
            if (extension.toLowerCase() == 'mp4' || extension.toLowerCase() == 'webm') {
                console.log("Got mp4 Video");
                // thumbnail = path + '/vid_thumb/' + name + '.jpg';
                // med = path + '/vid_poster/' + name + '.jpg';
                thumbnail = '/img/vgeo_video_default_sm.jpg'
                download = '/img/vgeo_video_default.jpg'
                video = bs.routeToURL('/api/geo_img/video/' + path + '/' + filename); // the original link
            }

            if(feature.properties.p360) {
                thumbnail = '/img/icons/p360_icon.png'
            }

            return {//photo goes into template
                // lat: parseFloat(feature.properties.latitude),
                // lon: parseFloat(feature.properties.longitude),
                lat: feature.geometry.coordinates[1],
                lon: feature.geometry.coordinates[0],
                // thumbnail: bs.routeToURL('/api/geo_img/thumbnail/' + thumbnail),
                // download: bs.routeToURL('/api/geo_img/download/' + med),
                thumbnail: thumbnail, // path to the thumbnail
                med: med, //path to medium file
                download: download,//todo clean up this
                orig: orig, //download to the original file
                video: video, //boolean true if is a video
                p360: feature.properties.p360,//boolean true if is 360 photo
                nice_name: feature.properties.nice_name,
                // video: feature.properties.video,
                alt: parseFloat(feature.properties.altitude).toFixed(5),
                lat5: feature.geometry.coordinates[1].toFixed(5),
                lon5: feature.geometry.coordinates[0].toFixed(5),
                filename: feature.properties.filename,
            }
        })

        // this.photo = new L.Photo(photos, {});
        this.photo = L.photo.cluster().on('click', function (evt) {//every time a photo is clicked
            let photo = evt.layer.photo;
            console.log('PHOTO:', photo);
            let template


            if (photo.video && (!!document.createElement('video').canPlayType('video/mp4; codecs=avc1.42E01E,mp4a.40.2'))) {
                //VIDEO
                // template = '<video autoplay ontrols poster="{download}" width="300" height="300"><source src="{video}" type="video/mp4"/></video>';
                template = '';
                if (photo.nice_name) {
                    template += `<h6 class="text-center">{nice_name}</h6>`
                }
                template += `
                    <video autoplay controls controlsList="nodownload"  poster="{download}" width="300">
                        <source src="{video}" type="video/webm"/>
                    </video>`;

                template += `
                <p>
                    lat: {lat5}, lon: {lon5} <br>
                </p>`;
            } else if (photo.p360) {
                //360 Photo Sphere

                p360.show(photo.orig);

            } else {//PHOTO
                template = '';
                if (photo.nice_name) {
                    template += `<h6 class="text-center">{nice_name}</h6>`
                }
                template += `<img src="{download}" width="300" /></a>
                <p>
                    lat: {lat5}, lon: {lon5}, alt: {alt} <br>
                    <a href="{download}" class="btn btm-sm asmt_button" style="width: 100%">Download {filename}</a>
                </p>`;

            }

            if (!photo.p360) {
                evt.layer.bindPopup(L.Util.template(template, photo), {
                    className: 'leaflet-popup-photo',
                    minWidth: 300,
                }).openPopup();
            }


        });
        this.photo.add(photos)//.addTo(this.map);

        console.log("Created Photo: ", this.photo);

        GeoImg._instances.push(this);
    }

}

GeoImg._instances = [];
