/**
 * @author Kier Lindsay
 * @description FileGrid Extends grid and defines the colomns of files it also adds some file specific filter and grouping
 */
class FileGrid extends Grid {


    /**
     * Formaters are used to define the visualse of a cell/ column
     * @param value
     * @param row
     * @param index
     * @returns {string}
     */
    static bsTableFormater(value, row, index) {


        if (row['file_id'] && row['file_id'][0] != null) {

            return [
                // '<a class="btn btn-sm p-0 m-0 weed-view-btn" href="javascript:void(0)" title="View File">',
                // '<i class="fa fa-eye"></i>',
                // '</a>  ',
                '<a class="btn btn-sm p-0 m-0 fg-files-btn" href="javascript:void(0)" title="Open File List">',
                '<i class="fa fa-file"></i>',
                '</a>'
            ].join('')

        } else {

            return [
                // '<a class="btn btn-sm p-0 m-0 weed-view-btn" href="javascript:void(0)" title="Upload File">',
                // '<i class="fa fa-upload"></i>',
                // '</a>  ',
                '<a class="btn btn-sm p-0 m-0 fg-files-btn" href="javascript:void(0)" title="Upload File' +
                '">',
                '<i class="fa fa-upload"></i>',
                '</a>'
            ].join('')

        }
    }

    /**
     * A helper for getting the function which will check a file exists then open the modal
     * note: used in gdash so carefull
     */
    static checkFileThenExecute(file_field, ref_field, ref_table, next) {
        return async (e, value, row, index) => {
            // alert('You click like action, row: ' + JSON.stringify(row))
            console.log("File ID: ", row[file_field])
            let id = row[ref_field];//this is the field todo; make config
            let version_ids = row[file_field];

            let res;

            //if we diddnt get a array make it an array
            if (!Array.isArray(version_ids)) {
                version_ids = [version_ids];
            }

            // console.log(version_ids, Array.isArray(version_ids));

            if (version_ids == null || (Array.isArray(version_ids) && version_ids[0] == null)) {
                //we have an array and its null
                // DID.setupNewFile(row, index);

                res = {success: false, msg: "No File References"}
                next(e, value, row, index, res);

            } else {//array is not [null]

                let files = [];
                let bad_references = []
                version_ids.forEach(vid => {
                    let file = fileGrid.getLatestVersion(vid);
                    if (!file) {
                        console.log("Bad Reference: ", vid);
                        bad_references.push(vid);
                    } else {
                        files.push(file);
                    }
                });


                let file = files[0];
                console.log('Files: ', files);
                if (!file) {

                    console.log("All Refrences are bad", bad_references);
                    res = {
                        success: false,
                        msg: "Bad File References",
                        data: bad_references,
                        bad_references: bad_references
                    }

                    /*     if( typeof ref_table != "undefined") {

                         }*/
                    /*    console.log('delete refrence');
                        bs.resNotify({success: false, msg: "No File Corresponding To that Reference (Deleting Reference)"});

                        let res = await Files.deleteFileReference('didInspection', id, version_ids[0]);

                        let layer = didDashboard.layer.getLayer(row.leaflet_stamp);
                        row['version_ids'] = version_ids.filter(v => v !== version_ids[0]);
                        layer.feature.properties['version_ids'] = row['version_ids']

                        $(didDashboard.table).bootstrapTable('updateRow', {
                            index: index,
                            row: row
                        });

                        if (res.route == "/api/auth/dbLogin") {
                            console.log("Confirming password");
                            confirmDBPassword(res)
                        }

    */
                    // console.warn('Didn\'t actually delete reference ');
                } else {
                    res = {success: true, msg: "Files Founds", data: files, bad_references: bad_references}
                }

                next(e, value, row, index, res);
            }
        }
    }

    /**
     * See Grid
     * @param data
     * @param options - see defaults
     */
    constructor(data, options) {


        //
        //     gridOptions: {
        //         // enableAddRow: true,
        //         editable: true,
        //             frozenColumn: 0,
        //             frozenBottom: true,
        //             autosizeColsMode: Slick.GridAutosizeColsMode.LegacyForceFit,
        //             // autosizeColsMode: Slick.GridAutosizeColsMode.FitViewportToCols,
        //
        //             multiColumnSort: true,
        //             numberedMultiColumnSort: true,
        //             tristateMultiColumnSort: true,
        //             sortColNumberInSeparateSpan: false,
        //     }
        // }

        let defaults = {
            // dialogOptions: {
            //     title: 'File Grid',
            // }

            containerType: 'jspanel',
            jspanelOptions: {
                panelSize: bs.getGoodPanelSize(16/9, 800, 50),
                aspectRatio: false,

                position: {
                    my: 'center',
                    at: 'center',
                    offsetX: 0,
                    offsetY: 0
                },

                headerTitle: 'File Browser',


            },
            gridOptions: {
                autosizeColsMode: Slick.GridAutosizeColsMode.LegacyForceFit,
                // autosizeColsMode: Slick.GridAutosizeColsMode.FitViewportToCols,

                multiColumnSort: true,
                numberedMultiColumnSort: true,
                tristateMultiColumnSort: true,
                sortColNumberInSeparateSpan: false,
            },
            deleteAction: true,
        }
        options = bs.mergeDeep(defaults, options);


        super(data, [], options, false);


        this._options = options

        // this.resizeOfsets = {
        //     clientWidth: -35,
        //     clintHeight: -15,
        //     uiWidth: -45,
        //     uiHeight: -60
        // }

        this.hijackFileFunctions();

        this.columns =
            [

                // checkboxSelector.getColumnDefinition(),
                // {id: "id", name: "uuid", field: "id", width: 250,},
                // {id: "version_id", name: "version_id", field: "version_id", width: 250,},
                {id: "version", name: "#", title: 'Version Number', field: "version_num", maxWidth: 24, sortable: true},
                {id: "pos", name: "#", title: 'Pos Number', field: "pos", maxWidth: 24, sortable: true},

                {
                    id: "filename",
                    name: "File Name",
                    field: "file_name",
                    minWidth: 150,
                    maxWidth: 300,
                    // formatter: FileNameFormatter,
                    editor: Slick.Editors.Text,
                    validator: this.requiredFieldValidator,
                    sortable: true,
                },
                {id: "type", name: "File Type", field: "mime_type", minWidth: 80},
                {
                    id: "remarks",
                    name: "File Remarks",
                    field: "remarks",
                    minWidth: 100,
                    editor: Slick.Editors.Text,
                    validator: Grid.requiredFieldValidator,
                },
                {id: "actions", name: "Actions", minWidth: 100, maxWidth: 100, formatter: this.actionFormatter},
            ];


        this.sortcol = "pos";
        this.sortdir = 1;

        this.init();

        this.setupToolBar();
        this.setupActions();
    }

    //file specific help

    addHelp() {

        this.addToolbarButton(
            "Help",
            `File Grid Help Guide (3-5 min)`,
            `<i class="fa fa-question" aria-hidden="true" style="font-size: 115%; padding: 3px;"></i>`,
            (grid, item, event) => {
                grid.buildHelp();
            });
    }

    buildHelp() {


        console.log("Building FileGrid Intro")
        let scope = this.panel;
        let steps = []
        steps.push({
            title: 'Welcome To The File Browser Guide',
            intro:
                'This guide provides a self guided tour of the file Browser tool. ' +
                'For quick reference, note there are also tooltips accessible by hovering over most things.' +
                '<br/><br/> Clicking anywhere outside this guide will close it.',

        })

        steps.push({
            title: 'The File Browser Panel',
            intro: 'The entire tool is contained within a movable resizable panel.',
            element: scope
        })
        steps.push({
            title: 'The File Browser Panel Controls',
            intro: 'Panels can be collapsed, minimized, maximized and closed.',
            element: scope.querySelector('.jsPanel-controlbar')
        })


        steps.push({
            title: 'The Toolbar',
            intro: 'Use this toolbar to search for files or upload new file types.',
            element: scope.querySelector('.jsPanel-hdr-toolbar')
        })
        steps.push({
            title: 'Uploading Files',
            intro: 'Uploading a new file from the button on this toolbar will create a new entry in the file browser.<br/>' +
                ' This is different then uploading a new ' +
                'version of an existing file from the actions.  Use this button ONLY when adding a NEW file type.',
            element: scope.querySelector('.jsPanel-hdr-toolbar')
        })
        let tmp;

        tmp = scope.querySelector('div.slick-viewport-left');
        if (tmp) {
            tmp = scope.querySelector('div.slick-pane-header')
            if (tmp) {

                steps.push({
                    title: 'File Browser Column Headings',
                    intro: 'Headings for the main file groups. If there are many rows you can use the scroll bars to navigate',
                    element: tmp
                });
            }

            tmp = scope.querySelector('div.slick-viewport-left')
            if (tmp) {
                steps.push({
                    title: 'File Browser Data',
                    intro: 'This area displays the files. Files are grouped by file type. The number of versions is visible at the group level.',
                    element: tmp
                });
                steps.push({
                    title: 'Files',
                    intro: 'Each Grouping Represents a single file. The number of versions is visible at the group level. Use the + at the beginning of the row to expand the group and see individual file versions.',
                    element: tmp
                });
                steps.push({
                    title: 'File - Action Part 1',
                    intro: 'There are actions available at this level' +
                        '<br/> Eye Icon: view the file. At the group lavel the latest version is shown. At the version level the specific version is shown.' +
                        '<br/> Download Icon: At the group level this downloads the last version. At the version level this downloads the selected file.',


                    element: tmp
                });
                steps.push({
                    title: 'File - Action Part 2',
                    intro: 'Upload Icon: Use this action to upload new versions of an existing file. Only available at the group level.' +
                        '<br/> Trash Can Icon: Delete. At the group level this deletes ALL versions. At the version level this deletes the selected file.',

                    element: tmp
                });


            }

            steps.push({
                title: 'That\'s All',
                intro: 'We hope this guide helped you familiarize yourself with Table Grids.  If you have feedback or run into ' +
                    'issues send us a message from the Module help in the main toolbar.',
                // element: scope
            })
        }

        // tmp = scope.querySelector('div.slick-viewport-right')

        introJs().setOptions({
            steps: steps
        }).start();


    }

    //top toolbar

    setupToolBar() {
        // this.addToolbarItem('button[name=addNewFile]',
        //     `<button name="addNewFile" class="btn btn-primary" style="width: 100%"> Add new File</button>`,
        //     (grid, item) => {
        //         console.log("NEW FILE CLICKED", this.didRow);
        //
        //         // let row = this.didRow;
        //
        //         this.didSetupCallback(this.didRow)
        //     },
        //     (grid, item, el) => {
        //         console.log("NEW FILE BUTTON CREATED callback", el);
        //         this.btnNew = el;
        //     })

        let helpModal
        this.addToolbarButton(
            'fileGridHelp',
            'Some help information on the file browser.',
            icons.help,
            () => {

                if (!helpModal) {
                    helpModal = bs.createModal(
                        'tdl_pit_help',
                        'File Browser Help',
                        `    <div class="mx-auto clearfix" style="padding:5px">
                            <h1 id="file-manager-help">File Manager Help</h1>
                            <p>The file management dialogue allows the user to:</p>
                            <ul>
                            <li>upload new documents by type</li>
                            <li>upload new versions of existing documents</li>
                            <li>view documents. View the latest version or any other uploaded version</li>
                            <li>delete versions</li>
                            <li>delete documents</li>
                            </ul>
                            <h2 id="uploading-a-new-document-">Uploading a new document.</h2>
                            <p>A record with no attached documents will take the user directly to the &quot;Upload New Documents&quot; dialogue.  </p>
                            <p>If files exist the dialogue opens at the file manager and a new document can be added by selecting the &quot;Upload New Document&quot; button from the top right.  </p>
                            <p><strong>IMPORTANT</strong>
                            The application does not restrict the user from uploading a specific document type multiple times. This is a feature. <strong>When adding new documents consider if you are adding a new document type or a new version of an existing document.</strong></p>
                            </div>
                    `,
                        false,
                        false,
                        {size: 'almost_fullscreen'}
                    );
                }

                helpModal.modal('show');

            },
        );

        this.addHelp();

        this.addRowSearch(true, true);
        // this.addGenericTools()


        // if (!this.btnNew) {
        //     this.btnNew = $(`<button name="addNewFile" class="btn btn-primary" style="width: 100%"> Add new File</button>`)[0]
        //     // this.gridElement.insertAdjacentElement('beforebegin', this.btnNew);
        //
        //     this.addToolbarItem('button[name=addNewFile]',
        //         this.btnNew, (grid, item) => {
        //             console.log(this.didRow);
        //
        //             // let row = this.didRow;
        //
        //             this.didSetupCallback(this.didRow)
        //         })
        //
        //     // this.btnNew.addEventListener('click', (e) => {
        //     //
        //     //     console.log(this.didRow);
        //     //
        //     //     // let row = this.didRow;
        //     //
        //     //     this.didSetupCallback(this.didRow)
        //     //
        //     // });
        // }
    }

    setupActions() {

        if(this._options.deleteAction) {
            this.addActionButton(
                'delete',
                'Delete This File/Version',
                `<i class="fa fa-trash" aria-hidden="true"></i>`, (row_id, grid) => {
                    // let row = grid.dataView.getItemById(row_id)
                    grid.deleteByFileId(row_id);
                }, {
                    colorClass: 'btn-danger'
                }
            )
        }

        this.addActionButton(
            'download',
            'Download This File/Version',
            `<i class="fa fa-download" aria-hidden="true"></i>`,
            (row_id, grid) => {
                // let row = grid.dataView.getItemById(row_id)
                // grid.deleteByFileId(row_id);
                Files.downloadFile(row_id)
            },
            {
                colorClass: 'btn-primary'
            }
        )

        this.addActionButton(
            'view',
            'View This File/Version',
            `<i class="fa fa-eye" aria-hidden="true"></i>`,
            (row_id, grid) => {
                // let row = grid.dataView.getItemById(row_id)
                grid.viewFile(row_id, row_id);
            },
            {
                colorClass: 'btn-success'
            }
        )
    }

    /**
     * OPen a file in a modal
     * @param id
     * @param name
     * @returns {Promise<void>}
     */
    async viewFile(id, name) {
        console.debug("View File id:", id, name);
        $('#fileViewModalTitle').html(`View File: ${name}`);

        let fileViewModalBody = $('#fileViewModalBody');

        //show spinner
        // fileViewModalBody.html(`<div class="spinner-border text-center text-primary"></div>`);
        fileViewModalBody.html(`<canvas id="the-canvas"></canvas>`);

        let fileViewModal = $('#fileViewModal');
        fileViewModal.modal('show');

        //download file

        let url = bs.routeToURL('/api/file/' + id)
        let viewerurl = bs.routeToURL(`/libs/pdfjs/web/viewer.html`);
        viewerurl += `?file=${encodeURIComponent(url)}`;


        // show file
        // fileViewModalBody.html(`
        //     <iframe class="file-iframe " src="${url}"></${tag}>
        // `);
        //
        try {


            let tag = 'iframe';
            fileViewModalBody.html(`
                    <${tag} class="file-iframe" src="${viewerurl}"></${tag}>
                `);


            // document
            //     .querySelector("#file-viewer")
            //     .addEventListener("onload", (e) => console.log("e", e));

        } catch (e) {
            if (fileViewModal.hasClass('show')) {
                console.error(e);
            } else {
                console.warn('Ignoring error because modal closed');
            }
        }
    }

    openNewVersionModal(id) {

        let file = this.dataView.getItems().find(i => i.id == id);
        document.getElementById('fileNewVersionModalTitle').innerHTML = `New Version Of: ${file.file_name}`;
        document.getElementById('newVersionClientID').value = file.client_id;
        document.getElementById('newVersionGroupID').value = file.group_id;
        document.getElementById('newVersionVersionId').value = file.version_id;
        document.getElementById('newVersionMeta').value = JSON.stringify(file.meta);
        $('#fileNewVersionModal').modal('show');

    }

    /**
     * This is similar to init formaters and is needed so that we have a refrence to this when its called
     */
    hijackFileFunctions() {
        this.deleteByFileId = async (id) => {
            await swal({
                title: "Are you sure you want to delete this file?",
                text: "Once deleted, you will not be able to recover this file!",
                icon: "warning",
                buttons: ["No Cancel", "Yes Delete File"],
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        let ret = await Files.deleteByFileId(id);
                        if (ret.success == true) {
                            this.dataView.deleteItem(id)
                        }
                    } else {
                        let color = 'warning';
                        let icon = 'nc-icon nc-simple-remove'

                        $.notify({
                            icon: icon,
                            message: "Canceled File Delete."
                        }, {
                            type: color,
                            timer: 3000,
                            placement: {
                                from: 'top',
                                align: 'right'
                            }
                        })
                    }
                });

            // let ret = await Files.deleteByFileId(id);
            // if (ret.success == true) {
            //     this.dataView.deleteItem(id)
            // }
        }

        this.deleteByVersionId = async (vid) => {

            await swal({
                title: "Are you sure you want to delete ALL versions?",
                text: "Once deleted, you will not be able to recover these files!",
                icon: "warning",
                buttons: ["No Cancel", "Yes Delete All Versions"],
                dangerMode: true,
            })
                .then(async (willDelete) => {
                    if (willDelete) {
                        let ret = await Files.deleteByVersionId(vid);
                        if (ret.success == true) {
                            let files = this.data.filter(f => f.version_id == vid);
                            files.forEach(f => {
                                this.dataView.deleteItem(f.id);
                            })
                        }
                    } else {
                        let color = 'warning';
                        let icon = 'nc-icon nc-simple-remove'

                        $.notify({
                            icon: icon,
                            message: "Canceled File Delete."
                        }, {
                            type: color,
                            timer: 3000,
                            placement: {
                                from: 'top',
                                align: 'right'
                            }
                        })
                    }
                });


        }
    }

    
    addFile(file) {
        file = Files.magicFile(file, newFile.types);
        this.dataView.addItem(file);
        this.dataView.refresh();
    }

    setupNewFile(config, show=true) {
       let  c = Object.assign({
            title: "Add New File To File Grid",
            type: false,
            group_id: site.group_id,
            client_id: client.id,
            ref_id: false,
            ref_table: false,
            meta: undefined,
            callback: ()=>{

            }
        }, config)

        newFile.setup(c.title, c.types, c.group_id, c.client_id, c.ref_table, c.ref_id, c.meta, c.callback);

       if(show) newFile.show();
    }


    siteTypeGrouping(types) {
        if(!types) types = tool_config.fileBrowser.siteFiles.types;


        this.dataView.setGrouping({
            getter: "meta.type",
            comparer: (a, b) => {
                return a.pos - b.pos;
            },
            formatter: (g) => {


                let currentFile = g.rows[0];
                g.rows.forEach(r => {
                    if (r.version_num > currentFile.version_num) {
                        currentFile = r;
                    }
                });
                let t = types.find(t=>t.value = currentFile.meta.type);

                let html = ``;
                html += `  ${currentFile.meta.type} :: ${currentFile.type_name} <span style='color:green'>(${g.count} Files)</span>`

                //float right is in revers order
                // html += `<button class='slick-icon-btn btn btn-danger btn-sm float-right' onclick="_globalGrids[${this._id}].deleteByVersionId('${g.value}')" title="Delete All Versions">
                //                     <i class="fa fa-trash" aria-hidden="true"></i>
                //                  </button>`
                // html += `<button onclick="_globalGrids[${this._id}].openNewVersionModal('${currentFile.id}')" class="slick-icon-btn btn btn-primary btn-sm float-right"  title="Upload New Version">
                //                     <i class="fa fa-upload" aria-hidden="true"></i>
                //                  </button>`

                // html += `<button onclick="Files.downloadFile('${currentFile.id}')" class="slick-icon-btn btn btn-primary btn-sm float-right" title="Download Latest Version">
                //                     <i class="fa fa-download" aria-hidden="true"></i>
                //                  </button>`
                // html += `<button onclick="_globalGrids[${this._id}].viewFile('${currentFile.id}', '${currentFile.file_name}')" class="slick-icon-btn btn btn-success btn-sm float-right" title="View Latest Version">
                //                     <i class="fa fa-eye" aria-hidden="true"></i>
                //                  </button>`


                // html += `<button class='btn btn-warning btn-sm m-0 ml-4 float-right' onclick="deleteByVersionId('${g.value}')">
                //             Delete All Versions
                //          </button>`

                return html;
            },
            // aggregators: [
            //     new Slick.Data.Aggregators.Avg("percentComplete"),
            //     new Slick.Data.Aggregators.Sum("cost")
            // ],
            aggregateCollapsed: false,
            lazyTotalsCalculation: true,

        });

        // this.dataView.collapseAllGroups()




    }

    /**
     * Group the files by version id for dids
     *
     */
    didGrouping(row, setup, nameField, label) {
        // this.btnNew.addEventListener('click', (e)=> {
        this.didRow = row;
        this.didSetupCallback = setup;

        nameField = nameField || 'disp_num'
        label = label || row[nameField]; // bacwards compatibility
        let str = `Upload New Document For: ` + label
        this.delToolbarButton('addDidFile');
        this.addToolbarButton('addDidFile', str, str, (grid, item) => {
            //when this button is clicked it sets up the fileUploadModal
            grid.didSetupCallback(row, row[nameField]);
        })
        // this.btnNew.innerHTML = ;
        this.dataView.sort(this.comparer);


        this.dataView.setGrouping(this._getVersionGrouping());

        this.dataView.collapseAllGroups()
    }

    _getVersionGrouping() {
        return {
            getter: "version_id",
            comparer: (a, b) => {
                return a.pos - b.pos;
            },
            formatter: (g) => {

                let currentFile = g.rows[0];
                g.rows.forEach(r => {
                    if (r.version_num > currentFile.version_num) {
                        currentFile = r;
                    }
                });

                let html = ``;
                html += `  ${currentFile.meta.type} :: ${currentFile.type_name} <span style='color:green'>(${g.count} Versions)</span>`

                //float right is in revers order
                html += `<button class='slick-icon-btn btn btn-danger btn-sm float-right' onclick="_globalGrids[${this._id}].deleteByVersionId('${g.value}')" title="Delete All Versions">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                 </button>`
                html += `<button onclick="_globalGrids[${this._id}].openNewVersionModal('${currentFile.id}')" class="slick-icon-btn btn btn-primary btn-sm float-right"  title="Upload New Version">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                 </button>`

                html += `<button onclick="Files.downloadFile('${currentFile.id}')" class="slick-icon-btn btn btn-primary btn-sm float-right" title="Download Latest Version">
                                    <i class="fa fa-download" aria-hidden="true"></i>
                                 </button>`
                html += `<button onclick="_globalGrids[${this._id}].viewFile('${currentFile.id}', '${currentFile.file_name}')" class="slick-icon-btn btn btn-success btn-sm float-right" title="View Latest Version">
                                    <i class="fa fa-eye" aria-hidden="true"></i>
                                 </button>`


                // html += `<button class='btn btn-warning btn-sm m-0 ml-4 float-right' onclick="deleteByVersionId('${g.value}')">
                //             Delete All Versions
                //          </button>`

                return html;
            },
            // aggregators: [
            //     new Slick.Data.Aggregators.Avg("percentComplete"),
            //     new Slick.Data.Aggregators.Sum("cost")
            // ],
            aggregateCollapsed: false,
            lazyTotalsCalculation: true,

        }
    }


    /**
     * This is a magic function that groups by many standarf fields for files
     *
     * note it generaly accepts only tru or false
     *
     * if you want to filter apply that with the standard grid filterBy
     * @param client
     * @param group
     * @param ref
     * @param path
     * @param type
     * @param version
     * @example
     * let groupingOptions = groupByFields(false, false, true, true, false, true )
     * ##----> for No client or group,  ref
     */
    groupByFields(client, group, ref, path, type, version) {
        const groupingOptions = [];

        if (client) {
            groupingOptions.push({
                getter: "client_id",
                formatter: function (g) {
                    return "Client ID: " + g.value + "  <span style='color:green'>(" + g.count + " items)</span>";
                },
                // aggregators: [
                //     new Slick.Data.Aggregators.Sum("duration"),
                //     new Slick.Data.Aggregators.Sum("cost")
                // ],
                aggregateCollapsed: true,
                lazyTotalsCalculation: true
            });
        }

        if (group) {
            groupingOptions.push({
                getter: "group_id",
                formatter: function (g) {
                    return "Group ID: " + g.value + "  <span style='color:green'>(" + g.count + " items)</span>";
                },
                // aggregators: [
                //     new Slick.Data.Aggregators.Sum("duration"),
                //     new Slick.Data.Aggregators.Sum("cost")
                // ],
                aggregateCollapsed: true,
                lazyTotalsCalculation: true
            });
        }

        if (ref) {
            groupingOptions.push({
                getter: function (item) {
                    // Use a getter function to access the nested property
                    if(typeof item.meta !== "object") return undefined;
                    console.log("Fromating spam");
                    return item.meta.ref_id;
                },
                formatter: function (g) {
                    return "Reference ID: " + g.value + "  <span style='color:green'>(" + g.count + " items)</span>";
                },
                // aggregators: [
                //     new Slick.Data.Aggregators.Sum("duration"),
                //     new Slick.Data.Aggregators.Sum("cost")
                // ],
                aggregateCollapsed: true,
                lazyTotalsCalculation: true
            });
        }

        if (path) {
            groupingOptions.push({
                getter: function (item) {
                    // Use a getter function to access the nested property
                    if(typeof item.meta !== "object") return undefined;
                    return item.meta.path;
                },
                formatter: function (g) {
                    return "Path: " + g.value + "  <span style='color:green'>(" + g.count + " items)</span>";
                },
                // aggregators: [
                //     new Slick.Data.Aggregators.Sum("duration"),
                //     new Slick.Data.Aggregators.Sum("cost")
                // ],
                aggregateCollapsed: true,
                lazyTotalsCalculation: true
            });
        }

        if (type) {
            groupingOptions.push({
                getter: function (item) {
                    // Use a getter function to access the nested property
                    if(typeof item.meta !== "object") return undefined;
                    return item.meta.type;
                },
                formatter: function (g, dataContext) {
                    return "Type: " + g.value + JSON.stringify(g.row)+"  <span style='color:green'>(" + g.count + " items)</span>";
                },
                // aggregators: [
                //     new Slick.Data.Aggregators.Sum("duration"),
                //     new Slick.Data.Aggregators.Sum("cost")
                // ],
                aggregateCollapsed: true,
                lazyTotalsCalculation: true
            });
        }

        if (version) {
            groupingOptions.push({
                getter: "version_id",
                formatter: function (g) {
                    return "Version ID: " + g.value + "  <span style='color:green'>(" + g.count + " items)</span>";
                },
                // aggregators: [
                //     new Slick.Data.Aggregators.Sum("duration"),
                //     new Slick.Data.Aggregators.Sum("cost")
                // ],
                aggregateCollapsed: true,
                lazyTotalsCalculation: true
            });
        }


        this.dataView.setGrouping(groupingOptions);
        return groupingOptions
    }

    popGroupBy(ref_id, path, type) {



    }

    siteGroupBy(path, type) {



    }

}