/**
 * Date picker config for the date selector (users by bs.prompt date type as well)
 */
$.datepicker.setDefaults({
    dateFormat: 'yy-mm-dd',
    showButtonPanel: true,
});
//https://stackoverflow.com/questions/33293111/jquery-datepicker-default-format-not-working
// $.extend($.fn.datepicker.defaults, {
//     dateFormat: 'yy-mm-dd',
//     // autoclose: true,
//     // todayHighlight: true,
//     // showOnFocus: false
// });
//slick grid editor for jquery date
function DateEditor(args) {
    var $input;
    var defaultValue;
    var scope = this;
    var calendarOpen = false;
    this.args = args;

    this.init = function () {
        $input = $("<input />");
        $input.appendTo(args.container);
        // $input.focus().select();
        alert('stop');
        console.log("Date Picker Init")


        $input.datepicker();

        //   $input.datepicker( {
        //    showOn: "button",
        //    buttonImageOnly: true,
        //   beforeShow: function () {
        //     calendarOpen = true;//test
        //   },
        //   onClose: function () {
        //     calendarOpen = false;
        //
        //     // trigger onCompositeEditorChange event when input changes and it's a Composite Editor
        //     if (args.compositeEditorOptions) {
        //       var activeCell = args.grid.getActiveCell();
        //
        //       // when valid, we'll also apply the new value to the dataContext item object
        //       if (scope.validate().valid) {
        //         scope.applyValue(scope.args.item, scope.serializeValue());
        //       }
        //       scope.applyValue(scope.args.compositeEditorOptions.formValues, scope.serializeValue());
        //       args.grid.onCompositeEditorChange.notify({ row: activeCell.row, cell: activeCell.cell, item: scope.args.item, column: scope.args.column, formValues: scope.args.compositeEditorOptions.formValues });
        //     }
        //   }
        // });

        $input.width($input.width() - (!args.compositeEditorOptions ? 18 : 28));
    };

    this.destroy = function () {
        $.datepicker.dpDiv.stop(true, true);
        $input.datepicker("hide");
        $input.datepicker("destroy");
        $input.remove();
    };

    this.show = function () {
        if (calendarOpen) {
            $.datepicker.dpDiv.stop(true, true).show();
        }
    };

    this.hide = function () {
        if (calendarOpen) {
            $.datepicker.dpDiv.stop(true, true).hide();
        }
    };

    this.position = function (position) {
        if (!calendarOpen) {
            return;
        }
        $.datepicker.dpDiv
            .css("top", position.top + 30)
            .css("left", position.left);
    };

    this.focus = function () {
        $input.focus();
    };

    this.loadValue = function (item) {
        defaultValue = item[args.column.field];
        // defaultValue = moment(item[args.column.field]).format('MM/DD/YYYY');
        $input.val(defaultValue);
        $input[0].defaultValue = defaultValue;
        $input.select();
    };

    this.serializeValue = function () {
        return $input.val();
    };

    this.applyValue = function (item, state) {
        item[args.column.field] = state;
    };

    this.isValueChanged = function () {
        return (!($input.val() === "" && defaultValue == null)) && ($input.val() != defaultValue);
    };

    this.validate = function () {
        if (args.column.validator) {
            var validationResults = args.column.validator($input.val(), args);
            if (!validationResults.valid) {
                return validationResults;
            }
        }

        return {
            valid: true,
            msg: null
        };
    };

    this.init();
}


function DateFormatter(row, cell, value, columnDef, dataContext) {

    html = '';
    if(!value) return 'Invalid Date'

    let date = new Date(value.replace(/-/g, '\/').replace(/T.+/, ''));
// => Sat Sep 24 2011 00:00:00 GMT-0700 (MST) - CORRECT DATE.
//https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off

    html += moment(date).format('YYYY-MM-DD');

    return html;
}


//slick grid editor for array todo convert

/*
 * An example of a "detached" editor.
 * The UI is added onto document BODY and .position(), .show() and .hide() are implemented.
 * KeyDown events are also handled to provide handling for Tab, Shift-Tab, Esc and Ctrl-Enter.
 *
 * Uses Array input class from bullchat works on jaon array fields
 * todo: VALIDATION
 */
function ArrayInputEditor(args) {
    var $input, $wrapper;
    var defaultValue;
    var scope = this;
    this.args = args;
    var arrayInput;
    this.init = function () {
        var compositeEditorOptions = args.compositeEditorOptions;
        var navOnLR = args.grid.getOptions().editorCellNavOnLRKeys;
        var $container = compositeEditorOptions ? args.container : $('body');

        $wrapper = $("<DIV class='slick-large-editor-text' style='z-index:10000;background:white;padding:5px;border:3px solid gray; border-radius:10px;'/>")
            .appendTo($container);
        if (compositeEditorOptions) {
            $wrapper.css({position: 'relative', padding: 0, border: 0});
        } else {
            $wrapper.css({position: 'absolute'});
        }

        arrayInput = new ArrayInput()
        $input = $(arrayInput.div)
            .appendTo($wrapper);

        // trigger onCompositeEditorChange event when input changes and it's a Composite Editor
        if (compositeEditorOptions) {
            $input.on("array-change", function () {
                var activeCell = args.grid.getActiveCell();

                // when valid, we'll also apply the new value to the dataContext item object
                if (scope.validate().valid) {
                    scope.applyValue(scope.args.item, scope.serializeValue());
                }
                scope.applyValue(scope.args.compositeEditorOptions.formValues, scope.serializeValue());
                args.grid.onCompositeEditorChange.notify({
                    row: activeCell.row,
                    cell: activeCell.cell,
                    item: scope.args.item,
                    column: scope.args.column,
                    formValues: scope.args.compositeEditorOptions.formValues
                });
            });
        } else {
            $("<DIV style='text-align:right'><BUTTON name='save'>Save</BUTTON><BUTTON name='cancel'>Cancel</BUTTON></DIV>")
                .appendTo($wrapper);

            $wrapper.find("button[name='save']").on("click", this.save);
            $wrapper.find("button[name='cancel']").on("click", this.cancel);
            $input.on("keydown", this.handleKeyDown);
            scope.position(args.position);
        }

        $input.focus().select();
    };

    this.handleKeyDown = function (e) {
        if (e.which == $.ui.keyCode.ENTER && e.ctrlKey) {
            scope.save();
        } else if (e.which == $.ui.keyCode.ESCAPE) {
            e.preventDefault();
            scope.cancel();
        } else if (e.which == $.ui.keyCode.TAB && e.shiftKey) {
            e.preventDefault();
            args.grid.navigatePrev();
        } else if (e.which == $.ui.keyCode.TAB) {
            e.preventDefault();
            args.grid.navigateNext();
        } else if (e.which == $.ui.keyCode.LEFT || e.which == $.ui.keyCode.RIGHT) {
            if (args.grid.getOptions().editorCellNavOnLRKeys) {
                var cursorPosition = this.selectionStart;
                var textLength = this.value.length;
                if (e.keyCode === $.ui.keyCode.LEFT && cursorPosition === 0) {
                    args.grid.navigatePrev();
                }
                if (e.keyCode === $.ui.keyCode.RIGHT && cursorPosition >= textLength - 1) {
                    args.grid.navigateNext();
                }
            }
        }
    };

    this.save = function () {
        args.commitChanges();
    };

    this.cancel = function () {
        $input.val(defaultValue);
        args.cancelChanges();
    };

    this.hide = function () {
        $wrapper.hide();
    };

    this.show = function () {
        $wrapper.show();
    };

    this.position = function (position) {
        $wrapper
            .css("top", position.top - 5)
            .css("left", position.left - 5);
    };

    this.destroy = function () {
        $wrapper.remove();
    };

    this.focus = function () {
        $input.focus();
    };

    this.loadValue = function (item) {
        let a = item[args.column.field];
        if (typeof a == "string") a = JSON.parse(a);
        if (!Array.isArray(a)) {
            console.warn("Data not an array : ", a, "  (Resetting to [])");
            a = [];
        }
        arrayInput.setArray(a)
        defaultValue = this.serializeValue();
        // $input.val(defaultValue = item[args.column.field]);
        // $input.select();

    };

    this.serializeValue = function () {
        return JSON.stringify(arrayInput.getArray());
    };

    this.applyValue = function (item, state) {
        item[args.column.field] = state;
    };

    this.isValueChanged = function () {

        let val = this.serializeValue()
        return (!(defaultValue === "[]" && defaultValue == null)) && (val != defaultValue);
    };

    this.validate = function () {
        // if (args.column.validator) {
        //     var validationResults = args.column.validator($input.val(), args);
        //     if (!validationResults.valid) {
        //         return validationResults;
        //     }
        // }

        return {
            valid: true,
            msg: null
        };
    };

    this.init();
}

//todo formater too maybe


//Select2 Dropdown
function PopulateSelect(select, dataSource, addBlank) {
    var index, len, newOption;
    if (addBlank) {
        select.appendChild(new Option('', ''));
    }
    $.each(dataSource, function (value, text) {
        newOption = new Option(text, value);
        select.appendChild(newOption);
    });
}

function Select2Editor(args) {
    var $input;
    var defaultValue;
    var scope = this;
    var calendarOpen = false;
    this.keyCaptureList = [Slick.keyCode.UP, Slick.keyCode.DOWN, Slick.keyCode.ENTER];
    this.init = function () {
        $input = $('<select></select>');
        $input.width(args.container.clientWidth + 3);
        PopulateSelect($input[0], args.column.dataSource, true);
        $input.appendTo(args.container);
        $input.focus().select();

        let select2Options = {
            // placeholder: '-',
            allowClear: true,
            tags: true
        }

        select2Options = Object.assign(select2Options, args.column.select2Options);


        $input.select2(select2Options);
    };
    this.destroy = function () {
        $input.select2('close');
        $input.select2('destroy');
        $input.remove();
    };
    this.show = function () {
    };
    this.hide = function () {
    };
    this.position = function (position) {
    };
    this.focus = function () {
        $input.select2('input_focus');
    };
    this.loadValue = function (item) {
        defaultValue = item[args.column.field];
        $input.val(defaultValue);
        $input[0].defaultValue = defaultValue;
        $input.trigger("change.select2");
    };
    this.serializeValue = function () {
        return $input.val();
    };
    this.applyValue = function (item, state) {
        item[args.column.field] = state;
    };
    this.isValueChanged = function () {
        return (!($input.val() == "" && defaultValue == null)) && ($input.val() != defaultValue);
    };
    this.validate = function () {
        return {
            valid: true,
            msg: null
        };
    };
    this.init();
}

function Select2Formatter(row, cell, value, columnDef, dataContext) {
    console.log('formatter: ', {row, cell, value, columnDef, dataContext});
    let color;
    let html = '';
    if (typeof columnDef.editOptions.validator == 'function') {
        color = columnDef.editOptions.validator(row, cell, value, columnDef, dataContext) ? 'var(--success)' : 'var(--danger)';
    }
    // if (columnDef.dataSource) {
    //
    //     // color = (columnDef.dataSource[value] || dataContext.status == 'Current') ? 'var(--success)' : 'var(--danger)';
    // }

    html = `<span style="background-color: ${color}">${value}</span>`;


    return html;
}

