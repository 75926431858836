/**
 * This is meant con control a loading screen/mask design your loading mask and give the div id='loading-mask' then call
 * loadingMask.waitOnMe() at the beginning of your code and loadingMask.done() at the end the number of task is internally
 * kept track of and the mask will be removed when all tasks are done.
 *
 *
 * @author Kier Lindsay
 */

let loadingMask = {};

loadingMask.waitingCount = 0;

// loadingMask.$div = $('#loading-mask');
loadingMask.div = document.getElementById('loading-mask');
loadingMask.msg = document.getElementById('loading-mask-msg');
loadingMask.display = 'unset';
/**
 * display = 'unset'
 */
loadingMask.show = (msg) => {
    // loadingMask.$div.show();
    loadingMask.div.style.display = loadingMask.display;
    if (msg) loadingMask.setMsg(msg);
    else loadingMask.setMsg('');

}

loadingMask.setMsg = (msg) => {
    loadingMask.msg.innerHTML = msg;
}

/**
 * display = 'none'
 */
loadingMask.hide = () => {
    // loadingMask.$div.hide();
    loadingMask.div.style.display = 'none';


}

loadingMask.errorBackup = console.error;
loadingMask.errorMessages = [];

window.onerror = (message, source, lineno, colno, error) => {
    loadingMask.errorMessages.push(`message${message} source${source} lineno${lineno} colno${colno} error${JSON.stringify(error, null, 2)} `)

}
// console.error = function() {
//     loadingMask.errorMessages.push.apply(loadingMask.errorMessages, arguments);
//     loadingMask.errorBackup.apply(console, arguments);
// };

/**
 * This causes the loading mask to wait for this thing to finish
 */
loadingMask.waitOnMe = (name) => {
    loadingMask.waitingCount++;
    if (loadingMask.waitingCount == 1) {

        let delay = 1000
        let warn = 10 * 1000; //seconds
        let fail = 20 * 1000; // seconds

        let start = Date.now();
        loadingMask.firstStartTime = start;
        let monitorFailure = () => {

            let now = Date.now()
            let delta = now - start;
            if (loadingMask.lastDoneTime && loadingMask.lastDoneTime > start) {
                delta = now - loadingMask.lastDoneTime;
            }

            if (delta > warn) {
                loadingMask.setMsg(`No Progress Made in ${(delta / 1000).toFixed(0)}s ... Waiting a moment longer.`)
            }
            if (delta > fail) {
                loadingMask.setMsg(`Something is taking longer then expected...`)
            }
            let msgTime = 4000

            if (delta > fail + msgTime * 1) {
                loadingMask.setMsg(`Sending feedback in case of crash. Data may just be loading extremely slow.`)
                if (!loadingMask.emailSent) {
                    bs.sendJsonAndNotify('post', '/api/help/feedback', { // sendEmail work
                        email: user.email,
                        msg: `A page failed to load for a user!`,
                        meta: {
                            // site_config: site_config,
                            error: loadingMask.errorMessages,
                            test: 'This is send from loadingMask.js',
                            site: window.location.href,
                            userAgent: navigator.userAgent,
                        }
                    })
                    loadingMask.emailSent = true;//only send once
                }
            }
            if (delta > fail + msgTime * 2) {
                loadingMask.setMsg(`Feedback Sent. If your internet is slow you may continue to wait the site may still load. Or <a onClick="document.location.reload(true)" href="#">refresh</a>`)
            }
            if (delta > fail + msgTime * 3) {
                // loadingMask.setMsg(`No Progress Made in ${(delta/1000).toFixed(0)}s ... If your internet is slow you may continue to wait the site may still load. Or <a onClick="document.location.reload(true)" href="#">refresh</a> <br. or <stromg>continue anyway some functionaly may be broken!</stromg> <br> <a onClick="lodingmask.hide()" href="#">refresh</a>`)
                loadingMask.setMsg(`No Progress Made in ${(delta / 1000).toFixed(0)}s ... <br><div>
 <span class="small alert-info rounded p-2 m-2"> If your internet is slow, you may continue to wait, the site may still load.  
 <button class="btn btn-sm btn-info ml-2 rounded-pill" onclick="document.location.reload(true)" href="#">Reload module (recomended)</button></span><br> 
 <span class="small alert-warning rounded p-2 m-2">Some componenets have not finish loading. Therfore some fuctionlity may be broken! 
 <button class="btn btn-sm btn-warning ml-2 rounded-pill" onclick="loadingMask.hide()" href="#">Proceed to module anyway.</button></span></div>`)
            }


            if (loadingMask.waitingCount) {// continue while still waiting
                setTimeout(monitorFailure, delay)
            }
        }
        setTimeout(monitorFailure, delay)

    }
    loadingMask.lastDoneTime = Date.now();

    console.debug("Loading Mask Waiting Count:  ", loadingMask.waitingCount, 'Waiting on', name);
    if (loadingMask.waitingCount > 0) {
        loadingMask.show();
    }
    loadingMask.setMsg(`Waiting on ${loadingMask.waitingCount} Tasks`)

}

/**
 * Signals that your task is finished
 */
loadingMask.done = () => {
    loadingMask.waitingCount--;
    loadingMask.lastDoneTime = Date.now();
    loadingMask.setMsg(`Waiting on ${loadingMask.waitingCount} Tasks`)
    console.debug("Loading Mask Waiting Count:  ", loadingMask.waitingCount)

    if (loadingMask.waitingCount <= 0) {
        loadingMask.hide()
    }
}
